<div class="container">
    <div class="row">
        <div class="card p-3 border-0 bg-grey col-8">
            <h1 class="font-condensed m-0">Billing Information</h1>
            <p class="mat-typography">Please complete your billing information to finalize this purchase.</p>

            <form>
                <h3 class="m-0 mat-typography">Pay with a new card</h3>
                <small>For this purchase only</small>

                <div class="row">
                    <div class="col-6">
                        <mat-form-field appearance="outline">
                            <mat-label>First Name</mat-label>
                            <input class="" [(ngModel)]="billingRequest.firstName" name="firstname" matInput
                                placeholder="First Name">
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Last Name</mat-label>
                            <input class="" [(ngModel)]="billingRequest.lastName" name="lastName" matInput
                                placeholder="Last Name">
                        </mat-form-field>
                    </div>
                </div>


                <div class="row">
                    <div class="col-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Email</mat-label>
                            <input class="" [(ngModel)]="billingRequest.email" name="email" matInput
                                placeholder="Email">
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Street Address</mat-label>
                            <input class="" [(ngModel)]="billingRequest.address" name="address" matInput
                                placeholder="Street Address">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-6">
                        <mat-form-field appearance="outline">
                            <mat-label>City</mat-label>
                            <input class="" [(ngModel)]="billingRequest.city" name="city" matInput placeholder="City">
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <div class="row">
                            <div class="col-6">
                                <mat-form-field appearance="outline">
                                    <mat-label>State</mat-label>
                                    <input class="" [(ngModel)]="billingRequest.state" name="state" matInput
                                        placeholder="State">
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field appearance="outline">
                                    <mat-label>Zip Code</mat-label>
                                    <input class="" [(ngModel)]="billingRequest.zip" name="zip" matInput
                                        placeholder="Zip Code">
                                </mat-form-field>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="w-50">
                    <p class="cc-info mat-typography "><span>Credit Card Information</span></p>
                </div>
                <div class="col-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Credit Card Number</mat-label>
                        <input class="" [(ngModel)]="billingRequest.cardNumber" maxlength="16" name="cardNumber"
                            matInput placeholder="Credit Card Number">
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Expiration Month</mat-label>
                        <mat-select class="" [(ngModel)]="expmonth" name="expmonth" matInput
                            placeholder="Expiration Month">
                            <mat-option value=''>
                            </mat-option>
                            <mat-option value='01'>January</mat-option>
                            <mat-option value='02'>February</mat-option>
                            <mat-option value='03'>March</mat-option>
                            <mat-option value='04'>April</mat-option>
                            <mat-option value='05'>May</mat-option>
                            <mat-option value='06'>June</mat-option>
                            <mat-option value='07'>July</mat-option>
                            <mat-option value='08'>August</mat-option>
                            <mat-option value='09'>September</mat-option>
                            <mat-option value='10'>October</mat-option>
                            <mat-option value='11'>November</mat-option>
                            <mat-option value='12'>December</mat-option>

                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Expiration Year</mat-label>
                        <mat-select class="" [(ngModel)]="selectedYear" name="expyear" matInput placeholder="Expiration Year">
                            <mat-option *ngFor="let year of expyear" [value]="year" >{{year}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field appearance="outline">
                        <mat-label>CVV Code</mat-label>
                        <input class="" minlength="3" [(ngModel)]="billingRequest.cvv" name="cvv" matInput
                            placeholder="CVV Code">
                    </mat-form-field>
                </div>
                <a routerLink=".." class="col-3 my-3 me-2 text-white text-uppercase" mat-raised-button color="primary">
                    <span>Back</span>
                </a>
                <button (click)="submitPayment(expmonth,selectedYear)" class="col-3 my-3 text-uppercase" mat-raised-button color="primary">
                    <span>Confirm Purchase</span>

                </button>

            </form>

        </div>
        <div class="col-4" *ngIf="packageRequest">
            <table class="mycart">

                <thead class="cart-summary">
                    <tr>
                        <th class="ps-2">
                            <h2 class="condensed mb-0">Products</h2>
                        </th>
                        <th class="ps-2">
                            <h2 class="condensed mb-0">Price</h2>
                        </th>
                    </tr>
                </thead>

                <tbody class="mat-typography">
                    <ng-container *ngFor="let packages of packageRequest.packages">
                        <tr class="border-bottom">
                            <td class="py-3 ps-2 mycart-name">
                                <span>
                                    {{packageTypes[packages.type.replaceAll('-', '_')]}}
                                </span>
                            </td>
                            <td class="ps-2">
                                <span class="d-flex justify-content-between">
                                    {{packagePrices[packages.type.replaceAll('-','_')] | currency : 'USD':'symbol'}}
                                </span>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
                <tfoot>
                    <tr>
                        <th class="ps-2 pt-2"><span class="text-uppercase total-text">Total</span></th>
                        <th class="ps-2 pt-2"> {{packageRequest.grandTotalCheck | currency : 'USD':'symbol'}}</th>
                    </tr>
                </tfoot>
            </table>
        </div>
        <!-- Can use the below code once we have added the logic for $0.0 value -->
        <!-- <div *ngIf="emptyCartValue">
            <a routerLink=".." class="col-3 my-3 me-2 text-white text-uppercase" mat-raised-button color="primary">
                <span>Back</span>                
            </a>
            <button (click)="submitPayment()" class="col-3 my-3 text-uppercase" mat-raised-button color="primary">
                <span>Confirm Purchase</span>

            </button>
        </div> -->
    </div>
</div>