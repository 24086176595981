<a (click)="editJob(params.data.id)" style="padding-left:12px;">
    {{params.data.title}}
    <div class="mat-typography mt-2" style="padding-left:12px;">
        {{params.data.city}} {{params.data.state}} {{createdDate}}
        <div class="d-flex">
            <div *ngFor="let category of categories">
                <div class="categories m-1">
                    {{category}}
                </div>
            </div>
        </div>
    </div>
</a>
