<h1 class="jd-add mat-typography">Job Description</h1>
<p class="mat-typography text-muted">Enter your Company information as you would like it to be
    displayed on Job Postings, Profile pages, and Search.
</p>
<form [formGroup]="stepTwoForm">
    <div class="col-md-12">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Job Title</mat-label>
            <input class="w-100" matInput placeholder="Job Title" name="title" formControlName="title" required
                minlength="1">
                <mat-error *ngIf="stepTwoForm.get('title').hasError('required')">
                    Please Enter Job Title
                </mat-error>
        </mat-form-field>
    </div>
    <div class="col-md-12">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>By Line</mat-label>
            <input class="w-100" name="byline" formControlName="byline" matInput placeholder="By Line">
        </mat-form-field>
    </div>
    <div class="col-md-12">
        <p class="ms-3 mat-typography">Job Description</p>
        <angular-editor name="description" formControlName="description" [config]="editorConfig">
        </angular-editor>
    </div>

    <div class="col-md-12 mt-3">
        <p class="ms-3 mat-typography">Benefits</p>
        <angular-editor name="benefits" formControlName="benefits" [config]="editorConfig">
        </angular-editor>
    </div>

    <div class="col-md-12 mt-3">
        <p class="ms-3 mat-typography">Requirements</p>
        <angular-editor name="requirements" formControlName="requirements" [config]="editorConfig">
        </angular-editor>
    </div>
</form>