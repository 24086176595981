import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';
import { MyJobsComponent } from 'src/app/components/ats/ats-layout/jobs/my-jobs/my-jobs.component';
import { SharedService } from 'src/app/services/shared.service';
import { environment } from 'src/environments/environment.local';
import { GlobalConstants } from '../../constants';

@Component({
  selector: 'app-my-jobs-settings',
  templateUrl: 'my-jobs-settings.component.html',
  styleUrls: ['./my-jobs-settings.component.scss']
})
export class MyJobsSettingsComponent implements OnInit, ICellRendererAngularComp {
  
  jobViewURL: string;
  activate: any;

  constructor(private router: Router, private sharedService: SharedService, private infoSnackBar: MatSnackBar) { }

  refresh(params: ICellRendererParams): boolean {
    throw new Error('Method not implemented.');
  }
  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    throw new Error('Method not implemented.');
  }

  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  editJob() {

    this.params.clicked(this.params.data.id);
    this.router.navigate(['/job/edit/', this.params.data.id]);
  }



  updateJobAction(value) {
    
    this.sharedService.updateJobActions(this.params.data.id, value).subscribe(
      (response: any)=>{
        if (response.message === 'success') {
          const action = (
            value === 'activate' ? 'Activated' :
              value === 'deactivate' ? 'Deactivated' :
                value === 'archive' ? 'Archived' :
                  value === 'unarchive' ? 'Unarchived' :
                    value === 'copy' ? 'Copied' :
                      value === 'delete' ? 'Deleted' : ""
          );
          this.showSnackBarWithText("Job " + action + " Successfully");
          this.refreshJobs();
        }
      }
    )
  }

  refreshJobs(){
    this.params.context.getJobs();
  }

  showSnackBarWithText(text: string): void {
    this.infoSnackBar.open(text, undefined, {
      duration: 3000
    });
  }
  ngOnInit() {
    if (window.location.href.includes('staging') || window.location.href.includes('staging')){
      this.jobViewURL = environment.JIUS_OLD;
    }
    else 
      this.jobViewURL = GlobalConstants.JIUS_OLD;
  }

}
