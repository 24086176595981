import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SharedService } from 'src/app/services/shared.service';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { ViewApplicant } from 'src/app/model/ViewApplicant';

export interface UserData {
  id: string;
  name: string;
  progress: string;
  color: string;
}

@Component({
  selector: 'app-single-job-applicants',
  templateUrl: './single-job-applicants.component.html',
  styleUrls: ['./single-job-applicants.component.scss']
})
export class SingleJobApplicantsComponent implements OnInit {
  jobdetailsdata: any = [];

  rows: any = [];
  ViewApplicant = ViewApplicant;

  jobId: string;
  jobData: any;
  showSpinner: boolean;
  tableData: any;
  rowStyle: { background: string; };
  rowHeight: number;
  getRowStyle: (params: any) => { background: string; 'padding-bottom': string; };
  defaultColDef: any;
  columnDefs: any;
  gridApi: any;
  gridColumnApi: any;
  rowData: any;
  innerWidth: number;
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.innerWidth = window.innerWidth;
  }
  constructor(
    public sharedService: SharedService,
    public router: Router,
    public route: ActivatedRoute) {
    this.onResize();
    this.columnDefs = [
      {
        field: 'candidate',
       
       
        filter: 'agMultiColumnFilter',
        
        cellStyle: { fontSize: '1.375rem', fontFamily: 'Myriad Pro' },
        
        cellRenderer: function (params) {
          let name = params.data.firstName + " " + params.data.lastName;
          let city = params.data.city ? params.data.city + ', ' : ''
          let state = params.data.state ? params.data.state : '';
          if (params.data)
            return '<a style="padding-left:12px;" href="/viewapplicant/' + params.data.jobApplicantId + '" >' + name + '<p class="mat-typography " style="padding-left:12px;">' + city + state + '</p></a>';
        },
        valueGetter: function(params){
          let firstName = params.data.firstName ? params.data.firstName + " " : '';
          let lastName = params.data.lastName ? params.data.lastName + " ": '';
          let location = (params.data.city ? params.data.city + ", " : '') + (params.data.state ? params.data.state : '');

          return firstName + lastName + location;
        },
        suppressSizeToFit: true,
      },
      {
        field: 'appStatus',
        filter: 'agMultiColumnFilter',
        cellStyle: {
          textAlign: 'center',
        },
        
        floatingFilter: false,
      },
      {
        field: 'appStage',
        filter: 'agSetColumnFilter',
        valueGetter: function appStage(params) {

          return ViewApplicant.STAGES[params.data.appStage.replaceAll('-', '_')];

        },
        floatingFilter: false,
        cellStyle: {
          textAlign: 'center',
        }
      },
      {
        field: 'applyDate',
        filter: 'agMultiColumnFilter',
        valueGetter: function nameGetter(params) {
          return new Date(params.data.createdAt).toLocaleDateString();
        },
        floatingFilter: false,
        cellStyle: {
          textAlign: 'center',
        }
      },
      {
        field: 'updated',
        filter: 'agMultiColumnFilter',
        valueGetter: function nameGetter(params) {
          return new Date(params.data.updatedAt).toLocaleDateString();
        },
        floatingFilter: false,
        cellStyle: {
          textAlign: 'center',
        }
      
      },
      {
        field: 'source',
        filter: 'agMultiColumnFilter',
        floatingFilter: false,
        cellStyle: {
          textAlign: 'center',
        }
      }
    ];

    this.rowStyle = { background: 'black' };
    this.rowHeight = 90;
    this.getRowStyle = params => {

      let style = {
        background: '#faf9f6',
        'padding-bottom': '0.5rem'
      };

      (params.node.rowIndex % 2 === 0) ? style = { background: '#faf9f6', 'padding-bottom': '0.5rem' }
        : style = { background: '#f2f2f2', 'padding-bottom': '0.5rem' };

      return style;

    };
    this.defaultColDef = {
      
      sortable: true,
      filter: true,
      floatingFilter: true,
    };
  }
  onGridReady(params) {

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.innerWidth <= 768 ? this.gridColumnApi.autoSizeAllColumns() : this.gridApi.sizeColumnsToFit();

    Array.from(document.getElementsByClassName('ag-text-field-input')).forEach((obj) => {
      if (obj.attributes['disabled']) { // skip columns with disabled filter
        return;
      }
      obj.setAttribute('placeholder', 'Search by Name/Location...');
    });
  }

  resetGrid() {
    
    //clear filters
    this.gridApi.setFilterModel(null);
    //notify grid to implement the changes
    this.gridApi.onFilterChanged();
  }

  onFilterChanged(params) {
    sessionStorage.setItem('ag-grid-singlejob-filter', JSON.stringify(params.api.getFilterModel()));
  }

  onFirstDataRendered(params): void {
    const filterModel = sessionStorage.getItem('ag-grid-singlejob-filter');
    params.api.setFilterModel(JSON.parse(filterModel));
  }

  updateFilter(status){

    const instance = this.gridApi.getFilterInstance('appStage');
    instance.setModel({
      values: [status]
    });
    instance.applyModel();
    this.gridApi.onFilterChanged();
    this.gridApi.resetRowHeights();
    //To-do Raghu -  Set Timeout needs to be implemented as the above method is behaving weird
    setTimeout(() => {

    }, 2000);

  }
  
  // TO_DO - NULL CHECKS
  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.jobId = params.get('id');
    });
    this.getSingleJob(this.jobId);
    this.getApplicants();
  }
  getSingleJob(jobId) {
    this.sharedService.getSingleJob(this.jobId).subscribe(
      (response: any) => {
       
        this.jobdetailsdata = response.data;
      }
    )
  }

  getApplicants() {

    this.showSpinner = true;
    this.sharedService.getSingleJobApplicants(this.jobId).subscribe(
      (response: any) => {

        this.rowData = response.data;
        
        this.rows = response.data;
        this.showSpinner = false;
      },
      (err: HttpErrorResponse) => {
        this.showSpinner = false;
        window.location.href = environment.ATS_ENV;
      }
    );
  }


}
