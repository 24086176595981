export class Packages {
    jobId: number;
    coupon: string;
    grandTotalCheck: number;
    packages: PackageTypes[];
    billing: Billing;
}
export class PackageTypes {
    type: string;
    count: number;
    term?: string | null;
    isCredit: boolean;
    autorenew?: boolean | null;
}
export class Billing {
    cvv: string;
    firstName: string;
    lastName: string;
    address: string;
    email: string;
    city: string;
    state: string;
    zip: string;
    cardNumber: string;
    expirationDate: string;
}


export class PackageDetails {
    // Keyed by 'numOfSlots': { numOfMonths: price } }'
    public static SLOT_PRICES = {
        1: {
            1: 199,
            3: 449,
            12: 1429
        },
        5: {
            1: 399,
            3: 899,
            12: 2899
        },
        10: {
            1: 599,
            3: 1349,
            12: 4299
        }
        // 25: {
        //     1: 1059,
        //     3: 2399,
        //     12: 7599
        // },
        // 50: {
        //     1: 1699,
        //     3: 3829,
        //     12: 12199
        // },
        // 100: {
        //     1: 2659,
        //     3: 5999,
        //     12: 19199
        // },
        // 'unlimited': 600
    }

    public static SLOT_OBJECTS = {
        1.1: 199,
        1.3: 449,
        1.12: 1429,
        5.1: 399,
        5.3: 899,
        5.12: 2899,
        10.1: 599,
        10.3: 1349,
        10.12: 4299
    };
    public static SLOT_OBJECTS_NAMES = {
        1.1: 'Single Job Slot 1 Month',
        1.3: 'Single Job Slot 3 Months',
        1.12: 'Single Job Slot 12 Months',
        5.1: '5 Job Slots 1 Month',
        5.3: '5 Job Slots 3 Months',
        5.12: '5 Job Slots 12 Months',
        10.1: '10 Job Slots 1 Month',
        10.3: '10 Job Slots 3 Months',
        10.12: '10 Job Slots 12 Months'
    };


    public static PACKAGE_NAMES = {
        job_priority: 'Priority Job',
        job_featured: 'Featured Job',
        company_featured: 'Featured Company',
        job_highlight: 'Highlight Job',
        job_boldface: 'Boldface Job',
        job_highlight_boldface: 'Highlight and Boldface Job',
        job_juice: 'Juice a Job!'
    };

    public static PACKAGE_NAMES_WITH_SLOTS = {
        job_priority: 'Priority Job',
        job_featured: 'Featured Job',
        company_featured: 'Featured Company',
        job_slots_resume_views: 'Job Slots',
        job_highlight: 'Highlight Job',
        job_boldface: 'Boldface Job',
        job_highlight_boldface: 'Highlight and Boldface Job',
        job_juice: 'Juice a Job!'
    };

    public static PACKAGE_PRICES = {
        job_priority: 50,
        job_featured: 75,
        company_featured: 150,
        job_highlight: 25,
        job_boldface: 15,
        job_highlight_boldface: 35,
        job_juice: 149,
    }

    public static PACKAGE_DESCRIPTION = {
        job_priority: 'Your job will be displayed at the top of the search results page.',
        job_featured: 'Feature your job posting on the front page of the site. (runs in rotation)',
        company_featured: 'Your company logo will be featured on the front page of the site, as well as on the job seeker dashboard. (runs in rotation)',
        job_highlight: 'Your job will be displayed and highlighted within the search results.',
        job_boldface: 'Your job will be displayed in boldface in the search results.',
        job_highlight_boldface: 'Your job will be displayed in the search results highlighted and in boldface for increased visibility.',
        job_juice: 'Get your job seen by more job seekers! Juice-a-Job distributes your job to a pool of 50+ job sites.'
    }
}