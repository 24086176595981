import { Component, OnInit } from '@angular/core';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  selector: 'app-candidate-renderer',
  templateUrl: './candidate-renderer.component.html',
  styleUrls: ['./candidate-renderer.component.scss']
})
export class CandidateRendererComponent implements OnInit {
  location: any;

  constructor() { }
  
  refresh(params: ICellRendererParams): boolean {
    throw new Error('Method not implemented.');
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    throw new Error('Method not implemented.');
  }

  params: any;

  agInit(params: any): void {

    this.params = params;
    this.location = params.data.city ? params.data.city + ", ": '' + params.data.state ? params.data.state : '';
  }
 
  ngOnInit(): void {
  }

}
