<div class="row">
  <div class="col-md-12  candidate-header">
    <div class="heading ">
      <h2 class="mat-h1 bg-white text-uppercase">{{jobdetailsdata?.title}}</h2>
    </div>
  </div>
  <div class="col-md-12">
    <ul class="steps steps-5 mb-3">
      <li><a title="" class="text-center"><span class="steps-title">0</span><span>New
            <span class="d-block">Leads</span></span></a></li>
      <li><a (click)="updateFilter('New')" title="" class="text-center"><span
            class="steps-title">{{jobdetailsdata.newApplicants}}</span><span>New
            <span class="d-block">Applicants</span></span></a></li>
      <li class="current"><a (click)="updateFilter('Review')" title=""
          class="text-center text-white"><span class="steps-title">{{jobdetailsdata.inReview}}</span><span>Applicants
            <span class="d-block">in
              Review</span></span></a></li>
      <li><a (click)="updateFilter('Interview')" title="" class="text-center text-white"><span
            class="steps-title">{{jobdetailsdata.inInterview}}</span><span>Interviews <span
              class="d-block">Scheduled</span></span></a></li>
      <li><a (click)="updateFilter('Offer')" title="" class="text-center text-white"><span
            class="steps-title">{{jobdetailsdata.offered}}</span><span>Offers <span class="d-block">
              Made</span></span></a></li>
    </ul>
    <p>Job Location: <b>{{jobdetailsdata.city}} {{jobdetailsdata.city?',':''}} {{jobdetailsdata.state}}</b> |
      Created: <b>{{jobdetailsdata.createdAt | date: 'MM/dd/yyyy'}} </b>| Status: <b>{{jobdetailsdata.status? 'Active'
        :'Inactive'}}</b> | Views: <b>{{jobdetailsdata.views}}</b> </p>
  </div>
    <div *ngIf="!showSpinner" class="mb-2 d-flex justify-content-end">
      <button (click)="resetGrid()" color="accent" mat-stroked-button>Reset All Filters
        <mat-icon class="mb-2">filter_alt_off</mat-icon>
      </button>
    </div>
  <div class="col-md-12 ">
    <div *ngIf="showSpinner" class="d-flex m-3 justify-content-center">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <ag-grid-angular *ngIf="!showSpinner" style="height: 1000px;" class="ag-theme-alpine" [rowData]="rowData"
      [suppressMenuHide]="true" [columnDefs]="columnDefs" [getRowStyle]="getRowStyle" [defaultColDef]="defaultColDef"
      (firstDataRendered)="onFirstDataRendered($event)" (filterChanged)="onFilterChanged($event)" (gridReady)="onGridReady($event)" [rowHeight]="rowHeight" [pagination]="true">
    </ag-grid-angular>
  </div>
</div>