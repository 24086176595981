import { SharedService } from 'src/app/services/shared.service';
import { AfterViewChecked, AfterViewInit, Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ViewApplicant } from 'src/app/model/ViewApplicant';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import 'ag-grid-enterprise';
import { CandidateRendererComponent } from 'src/app/components/common/ag-grid/candidate-renderer/candidate-renderer.component';
import { MatDialog } from '@angular/material/dialog';
import { InviteJobDialogComponent } from 'src/app/components/common/invite-job-dialog/invite-job-dialog.component';
import * as moment from 'moment';
@Component({
  selector: 'app-candidate-pipeline',
  templateUrl: './candidate-pipeline.component.html',
  styleUrls: ['./candidate-pipeline.component.scss']
})
export class CandidatePipelineComponent implements OnInit {

  @ViewChild('inviteJobDialog') inviteJobDialog: TemplateRef<any>;
  ViewApplicant = ViewApplicant;
  showSpinner = false;
  tags: any;
  id: any;
  userData: any;
  columnDefs;
  defaultColDef;
  private gridApi;
  private gridColumnApi;
  rowData = [];
  rowStyle: { background: string; };
  getRowStyle: (params: any) => { background: string; };
  rowHeight: number;
  fromDashboard: any;
  fromSingleJob: string;
  jobTitle: string;
  showActive = false;
  innerWidth: number;
  paginationPageSize: number;
  paginationNumberFormatter: (params: any) => string;
  includesActive: any;
  frameworkComponents: { candidateRenderer: any; };
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.innerWidth = window.innerWidth;
  }

  constructor(public dialog: MatDialog, private sharedService: SharedService, private http: HttpClient, private router: ActivatedRoute) {
    this.onResize();
    this.router.paramMap.subscribe(params => {
      this.includesActive = params.get('job');
      if (params.get('filter')?.includes('dashboard')) {
        this.fromDashboard = params.get('filter');
      }
      else {
        this.fromSingleJob = params.get('filter');
        this.jobTitle = params.get('job');
      }

    });

    function dateComparator(date1, date2) {
      var date1Number = monthToComparableNumber(date1);
      var date2Number = monthToComparableNumber(date2);
      if (date1Number === null && date2Number === null) {
        return 0;
      }
      if (date1Number === null) {
        return -1;
      }
      if (date2Number === null) {
        return 1;
      }
      return date1Number - date2Number;
    }
    // MM/DD/YYYY - 10/28/2021
    function monthToComparableNumber(date) {
      if (date === undefined || date === '-' || date === null || date.length !== 10) {
        return null;
      }
      var yearNumber = date.substring(6, 10);
      var monthNumber = date.substring(0, 2);
      var dayNumber = date.substring(3, 5);
      var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
      return result;
    }

    this.columnDefs = [
      {
        field: 'candidate',
        filter: 'agMultiColumnFilter',
        cellStyle: { fontSize: '1.375rem', fontFamily: 'Myriad Pro' },
        cellRenderer: "candidateRenderer",
        cellRendererParams: {
          context: this,
          clicked: function (field: any) {
            return field;
          }
        },
        valueGetter: function (params) {
          let location = (params.data.city ? params.data.city + ", " : '') + (params.data.state ? params.data.state : '');
          let firstName = params.data.firstName ? params.data.firstName + " " : '';
          let lastName = params.data.lastName ? params.data.lastName + ", " : '';
          return firstName + lastName + location;
        },
        getQuickFilterText: function (params) {
          return params.data.firstName + " " + params.data.lastName;
        },
        suppressSizeToFit: true
      },
      {
        field: 'jobTitle',
        filter: 'agMultiColumnFilter',
        suppressSizeToFit: true

      },
      {
        field: 'jobStatus',
        filter: 'agSetColumnFilter',
        valueGetter: function (params) {
          let status = params.data.jobStatus ? 'Active' : params.data.appStatus === 'Credit' ? '-': 'Inactive'
          return status;
        },
        cellStyle: {
          fontSize: '13px',
          textAlign: 'center',
        },
        floatingFilter: false,
      },
      {
        field: 'appStatus',
        filter: 'agSetColumnFilter',
        floatingFilter: false,
        cellStyle: {
          fontSize: '13px',
          textAlign: 'center',
        }
      },
      {
        field: 'appStage',
        filter: 'agSetColumnFilter',
        valueGetter: function appStage(params) {
          return !params.data.isRead ? params.data.appStatus === 'Credit' ? '-': params.data.appStage === 'lead' ? 'Lead': 'New' : ViewApplicant.STAGES[params.data.appStage.replaceAll('-', '_')];
        },
        floatingFilter: false,
        cellStyle: {
          fontSize: '13px',
          textAlign: 'center',
        }
      },
      {
        field: 'applyDate',
        filter: 'agDateColumnFilter',
        comparator: dateComparator,
        valueGetter: function nameGetter(params) {
          return params.data.createdAt ? moment(new Date(params.data.createdAt)).format('MM/DD/YYYY') : '-';
        },
        floatingFilter: false,
        cellStyle: {
          fontSize: '13px',
          textAlign: 'center',
        }
      },
      {
        field: 'updated',
        filter: 'agDateColumnFilter',
        comparator: dateComparator,
        valueGetter: function nameGetter(params) {
          return params.data.updatedAt ? moment(new Date(params.data.updatedAt)).format('MM/DD/YYYY') : '-';
        },
        floatingFilter: false,
        cellStyle: {
          fontSize: '13px',
          textAlign: 'center',
        }
      },
      {
        field: 'tags',
        filter: 'agMultiColumnFilter',
        floatingFilter: false,
        cellRenderer: function (params) {
          if(params.data.tags){
            return ('<div class="d-flex">' +
              params.data.tags.map((val) => {        
              return '<div class="tags-cp m-1">' + val + '</div>';
            }).join('')  + '</div>');
          }
          else{
            return '';
          }          
        },
        cellStyle: {
          textAlign: 'center',
        }
      },
      {
        field: 'source',
        filter: 'agMultiColumnFilter',
        floatingFilter: false,
        cellStyle: {
          fontSize: '13px',
          textAlign: 'center',
        }
      }
    ];

    this.rowStyle = { background: 'black' };
    this.rowHeight = 80;

    this.getRowStyle = params => {

      let style = {
        background: '#faf9f6',
        'padding-bottom': '0.5rem'
      };

      (params.node.rowIndex % 2 === 0) ? style = { background: '#faf9f6', 'padding-bottom': '0.5rem' }
        : style = { background: '#f2f2f2', 'padding-bottom': '0.5rem' };

      return style;

    };

    this.defaultColDef = {

      sortable: true,
      filter: true,
      floatingFilter: true,
      filterParams: { newRowsAction: 'keep' }
    };
    
    this.paginationPageSize = 10;
    this.paginationNumberFormatter = function (params) {
      return '[' + params.value.toLocaleString() + ']';
    };
    this.frameworkComponents = {
      candidateRenderer: CandidateRendererComponent,
      
    };
  }

  onFilterChanged(params) {
    sessionStorage.setItem('ag-grid-persistane-filtering', JSON.stringify(params.api.getFilterModel()));
  }

  resetGrid() {
    //clear filters
    this.gridApi.setFilterModel(null);
    //notify grid to implement the changes
    this.gridApi.onFilterChanged();

  }

  onPageSizeChanged(newPageSize) {

    var inputValue = (<HTMLInputElement>document.getElementById('page-size')).value;
    this.gridApi.paginationSetPageSize(Number(inputValue));
  }

  selectFilter() {

    const filterModel = sessionStorage.getItem('ag-grid-persistane-filtering');
    this.gridApi.setFilterModel(JSON.parse(filterModel));
    if (this.fromDashboard) {
      this.showSpinner = true;
      const filter = this.fromDashboard;
      const instance = this.gridApi.getFilterInstance('appStage');
      instance.setModel({
        values: filter === 'dashboard-new' ? ['New'] :
          (filter === 'dashboard-review' ? ['Review'] :
            (filter === 'dashboard-interview' ? ['Interview'] :
              (filter === 'dashboard-offer' ? ['Offer'] :
                (filter === 'dashboard-leads' ? ['Lead'] : '')
              )
            )
          ),
      });
      instance.applyModel();
      this.gridApi.onFilterChanged();
      this.showSpinner = false;
    }

    else if (this.fromSingleJob) {

      const filter = this.fromSingleJob;
      const jobInstance = this.gridApi.getFilterInstance('jobTitle');
      const stageInstance = this.gridApi.getFilterInstance('appStage');
      const title = decodeURIComponent(this.jobTitle);

      jobInstance.setModel({
        values: [title],
      });
      jobInstance.applyModel();

      stageInstance.setModel({
        values: filter === 'new' ? ['New'] : filter === 'review' ? ['Review'] : filter === 'interview' ? ['Interview'] : filter === 'offer' ? ['Offer'] : '',
      });
      stageInstance.applyModel();

      this.gridApi.onFilterChanged();
      //To-do Raghu -  Set Timeout needs to be implemented as the above method is behaving weird
      setTimeout(() => {

      }, 2000);
    }
    else {
      return;
    }

  }

  onGridReady(params) {

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const sortModel = [
      { colId: 'applyDate', sort: 'desc' }
    ];
    this.gridApi.setSortModel(sortModel);
    this.innerWidth <= 768 ? this.gridColumnApi.autoSizeAllColumns() : this.gridApi.sizeColumnsToFit();
    Array.from(document.getElementsByClassName('ag-text-field-input')).forEach((obj) => {
      if (obj.attributes['disabled']) { // skip columns with disabled filter
        return;
      }
      obj.setAttribute('placeholder', 'Search by Name/Location...');
    });

  }

  getCandidate() {
    this.sharedService.getCandidatePipelineData().subscribe(

      (response: any) => {
        const candidateData = response.data;
        if (this.fromDashboard && this.includesActive?.includes('active')) {
          candidateData.map((candidate) => {
            if (candidate.jobStatus) {
              this.showActive = true;
              this.rowData?.push(candidate);
            }
          })
        }
        else {
          this.rowData = response.data;
        }

        this.showSpinner = false;

      }
    )
  }
  
  getUserDetails() {
    this.sharedService.loginAts().subscribe((res) => {

      if (res.data.response == "LOGIN_VALID") {
        this.userData = res.data.userDetails;
      } else {
        localStorage.removeItem('token');
        window.location.href = environment.ATS_ENV;
      }
    })
  }

  getTags(candidateId) {
    this.sharedService.getCandidateTags(candidateId, this.userData.userId).subscribe(

      (response: any) => {
        this.tags = response.data.tags;
        return this.tags;
      }
    )
  }


  openInviteJobDialog(data) {
    this.openDialog(this.inviteJobDialog, 600, data);
  }
  openDialog(templateRef, width, data) {
    let dialogRef = this.dialog.open(InviteJobDialogComponent, {
      width: width + 'px',
      disableClose: true,
      data: {
        jobData: data,
        userId: this.userData.userId
      }
    });
  }

  closeDialog() {
    let dialogRef = this.dialog.closeAll();

  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.showSpinner = true;
    this.getCandidate();
    this.getUserDetails();
  }

}
