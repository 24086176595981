import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JobApplication } from 'src/app/model/JobApplication';
import { SharedService } from 'src/app/services/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-invite-job-dialog',
  templateUrl: './invite-job-dialog.component.html',
  styleUrls: ['./invite-job-dialog.component.scss']
})
export class InviteJobDialogComponent implements OnInit {

  jobList = [];
  showEmail = false;
  inviteEmailTemplate: any;
  inviteForm: any;
  jobId: any;
  user: any;
  jobApplicationId: string;
  inviteEmailContent: string;
  inviteJobTitle: string;
  candidateId: any;
  tenantId: any;
  jobApplication: JobApplication;
  receiverEmail: any;

  constructor(@Inject(MAT_DIALOG_DATA) private jobData: any,
    @Inject(MAT_DIALOG_DATA) private employerId: any,
    private sharedService: SharedService,
    private infoSnackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<InviteJobDialogComponent>) { }

  closeDialog(): void {
    this.dialogRef.close(true);
  }

  showEmailField(event: MatSelectChange) {
    this.showEmail = true;
    this.jobId = event.value;
    this.inviteJobTitle = event.source.triggerValue;
    this.inviteEmailTemplate = "Dear Applicant, \nYou're invited to apply to the job '" + this.inviteJobTitle + "'. Please proceed with the application by applying it in your dashboard. Or Click on the below link to directly apply\n" +
      environment.JIUS_OLD + "/" + this.jobId;
  }
  sendInvite() {

    this.jobApplication = new JobApplication();
    this.jobApplication.jobId = this.jobId;
    this.jobApplication.candidateId = this.candidateId;
    const data = this.jobApplication;
    this.sharedService.inviteToJob(data).subscribe(
      (response: any) => {
        if (response.message === 'success') {
          this.sendEmail();
        }
        this.jobApplicationId = response.data.id;
      }
    );

  }

  sendEmail() {

    const formData = new FormData();
    formData.append('sender', this.tenantId);
    formData.append('receiver', this.receiverEmail);
    formData.append('senderEmployerId', this.employerId);
    formData.append('jobApplicationId', "");
    formData.append('messageText', this.inviteEmailTemplate);
    formData.append('subject', "Invitation For " + this.inviteJobTitle);
    this.sharedService.sendEmailToApplicant(formData).subscribe(
      {
        next: (response) => {
          this.showSnackBarWithText('Invitation Sent Successfully!');
        },
        error: (error) => {
          this.showSnackBarWithText('There is a problem, please try again later!');
        }
      });
  }

  showSnackBarWithText(text: string): void {
    this.infoSnackBar.open(text, undefined, {
      duration: 3000
    });
  }

  ngOnInit(): void {

    this.employerId = this.jobData.userId;
    this.candidateId = this.jobData.jobData.candidateId;
    this.tenantId = this.jobData.jobData.tenantId;
    this.receiverEmail = this.jobData.jobData.email;
    this.sharedService.getAllJobs().subscribe(
      (response: any) => {
        this.jobList = response.data;
      });
  }

}
