import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-step-four',
  templateUrl: './step-four.component.html',
  styleUrls: ['./step-four.component.scss'],
})
export class StepFourComponent implements OnInit, OnChanges {
  isJius: boolean;
  isExternal: boolean;
  showEmail: boolean;
  @Input() jobData: any;
  applicationSettings: any;
  stepFourForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {

    this.stepFourForm = formBuilder.group({
      applicationSettings: formBuilder.group({
        email: formBuilder.group({
          address: [],
          notify: [],
          subject: [],
        }),
        hasResume: true,
      }),
    });
  }

  showJius(value) {
    if (value === 'jius') {
      this.isJius = true;
      this.isExternal = false;
    }
  }

  showExternal(val) {
    if (val === 'external') {
      this.isJius = false;
      this.isExternal = true;
    }
  }

  hideField() {
    this.showEmail = false;
  }

  showEmailField() {
    this.showEmail = true;
  }

  ngOnInit(): void {

  }

  ngOnChanges(): void {
    if (this.jobData.applicationSettings) {

      if (this.jobData.applicationSettings.email.notify !== 'never') {
        this.showEmailField();
      }

      const notificationFrequency = this.jobData.applicationSettings.email;
      if (notificationFrequency.notify === 'never') {

        this.hideField();
        this.stepFourForm
          .get('applicationSettings')
          .get('email')
          .get('notify')
          .setValue('never');

      } else {

        this.showEmailField();
        this.stepFourForm.patchValue({
          applicationSettings: {
            email: {
              address: this.jobData.applicationSettings.email.address,
              notify: this.jobData.applicationSettings.email.notify,
              subject: this.jobData.applicationSettings.email.subject,
            },
            hasResume: this.jobData.applicationSettings.hasResume,
          },
        });

      }
    }
  }
}

