import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Billing, PackageDetails } from 'src/app/model/packages.model';
import { AtsService } from 'src/app/services/ats.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  packageRequest: any;
  billingRequest = new Billing();
  expyear: any;
  expmonth: any;
  selectedYear: any;
  emptyCartValue = true;
  packageTypes = PackageDetails.PACKAGE_NAMES_WITH_SLOTS;
  packagePrices = PackageDetails.PACKAGE_PRICES;

  constructor(private router: Router, private infoSnackBar: MatSnackBar, private atsService: AtsService, private sharedService: SharedService) { }

  showSnackBarWithText(text: string): void {
    this.infoSnackBar.open(text, undefined, {
      duration: 3000
    });
  }



  submitPayment(expmonth?, selectedYear?) {
    this.billingRequest.expirationDate = expmonth + selectedYear.toString().substr(2, 3);
    this.packageRequest.billing = this.billingRequest;
    this.sharedService.submitPayment(this.packageRequest).subscribe(
      (response: any) => {
        if (response.message === 'success') {
          this.showSnackBarWithText(response.data.status);
          this.router.navigate(['my-jobs']);
        }
        else {
          this.showSnackBarWithText('There was an issue in purchasing the packages. Please try again!');
        }
      }
    )
  }

  getYear() {
    const thisYear = new Date().getFullYear();
    const yearsAhead = 20;
    let year = thisYear;
    const endYear = year + yearsAhead;
    const options = [];
    for (year; year <= endYear; ++year) {
      options.push(year);
    }
    return options;
  }


  ngOnInit(): void {
    this.expyear = this.getYear();
    this.atsService.checkoutSubject.subscribe((data) => {
      this.packageRequest = data;
      if (this.packageRequest?.grandTotalCheck === 0) {
        this.emptyCartValue = true;
      } else {
        this.emptyCartValue = false;
      }
    });

  }

}
