import { EmailRequest } from './../../../../../model/email-request';
import { ApplicantConversation, Conversation } from './../../../../../model/conversations';
import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EmailTemplateRequest } from 'src/app/model/EmailTemplateRequest';
import { ViewApplicantConstants } from 'src/app/components/common/constants/ViewApplicantConstants';

@Component({
  selector: 'app-applicant-message',
  templateUrl: './applicant-message.component.html',
  styleUrls: ['./applicant-message.component.scss']
})
export class ApplicantMessageComponent implements OnInit, OnChanges {
  showEditor: boolean = true;
  userTypeMessages = [];
  @Input() applicationdetails;
  @Input() applicantDetails: any;
  @Input() jobTitle: any;
  @Input() user: any;
  @Input() jobApplicationId = '';
  @Input() tenantId: any;
  @Input() companyId: any;
  @Input() applicantFirstName: any;
  messages: any;
  text: string;
  formdata: FormGroup;
  conversation: ApplicantConversation;
  employerInitials: any;
  seekerInitials: string;
  fileName = [];
  file: File[] = [];
  @ViewChild('fileUpload') fileUpload: ElementRef;
  timeStamp: string;

  constructor(public sharedService: SharedService, private formBuilder: FormBuilder, private infoSnackBar: MatSnackBar) { }


  showEmailTemplate() {
    this.showEditor = !this.showEditor;
  }

  loadEmailTemplate(ev) {
    if (ev) {

      let reqData : EmailTemplateRequest = {
        companyId : this.companyId,
        jobTitle : this.jobTitle,
        applicantName : (this.applicantDetails.firstName + ' ' + this.applicantDetails.lastName),
        email : this.user.email,
        phoneNumber :  this.user.phone,
        employerName : this.user.firstName + ' ' + this.user.lastName,
        linkToJob : ''
      };
      this.sharedService.loadJobTemplate(ViewApplicantConstants.EMAIL_TEMPLATES[ev], reqData).subscribe((response: any) => {
        this.text = response.data?.template;
      });
    } 
    else {
      this.text = '';
    }
  }

  showSnackBarWithText(text: string): void {
    this.infoSnackBar.open(text, undefined, {
      duration: 3000
    });
  }

  sendMail(text) {
    if (text) {
      const formData = new FormData();
      formData.append('sender', this.tenantId);
      formData.append('receiver', this.applicantDetails.email);
      formData.append('senderEmployerId', this.user.userId);
      formData.append('jobApplicationId', this.jobApplicationId);
      formData.append('messageText', this.text)
      formData.append('subject', "Regarding Your Application for " + this.jobTitle);
      formData.append('carbonCopy', this.user.email);
      for (let files in this.file) {

        formData.append('attachments', this.file[files]);
      }

      this.sharedService.sendEmailToApplicant(formData).subscribe(
        {
          next: (response) => {
            this.showSnackBarWithText('Email Sent Successfully!');
            this.fileUpload.nativeElement.value = '';
            this.fileName = [];
            this.file = [];
            setTimeout(() => {
              this.getConversations();
            }, 3000);

            this.text = '';
          },
          error: (error) => console.log(error)
        });


    }
    else {
      this.formdata.get('text').markAsTouched();
    }
  }

  
  onFileSelected(event) {

    let fileList = event.target.files as FileList

    for (let i = 0; i < fileList.length; i++) {
      this.file.push(fileList.item(i));
      this.fileName.push(fileList.item(i).name);
    }
  }
  getConversations(): void {

    const jobApplicationIdForMessages: string = this.jobApplicationId;
    this.sharedService.getConversationForApplicantWithJobAppId(jobApplicationIdForMessages)
      .subscribe({
        next: (response: ApplicantConversation) => {
          this.conversation = response;

          this.conversation.conversation.map((conv) => {
            conv.timestamp = conv.timestamp.replace('T', ' ').concat('Z').toString();
            conv.conversationText = conv.conversationText.replace(/(?:\r\n|\r|\n)/g, '<br />');
          })
        },
        error: (error) => console.log(error)
      });
  }
  

  ngOnChanges() {
    this.getConversations();
  }

  ngOnInit() {
    const employerName = this.user.firstName + " " + this.user.lastName;
    const seekerName = this.applicantDetails.firstName + " " + this.applicantDetails.lastName;
    this.seekerInitials = seekerName.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()
    this.employerInitials = employerName.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()
    this.formdata = this.formBuilder.group({
      text: new FormControl('', Validators.required),

    });
  }

  getConversationTypeText(conversation: Conversation): string {
    const conversationType = conversation.conversationType;
    return conversationType === 'email' ? 'Email' : 'Text Message';
  }

}
