<div class="chat-content review-content w-100">
    <div class="chat-footer container py-3">
        <h2 class="mb-0">Create internal notes</h2>
        <div class="row py-3 justify-content-center align-items-start">
            <div class="col-md-7 col-lg-8 col-xl-9">
                <mat-form-field appearance="outline" class="postinput w-100">
                    <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1.3" cdkAutosizeMaxRows="5"
                        #fname></textarea>
                </mat-form-field>
            </div>
            <div class="col-md-5 col-lg-4 col-xl-3">
                <button (click)="sendPost(fname, fname.value)" mat-raised-button class="w-100 text-uppercase mb-3"
                    color="primary">
                    Post Note</button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <figure class="pt-2 border-0" *ngFor="let notes of notes.slice().reverse();">
                    <span class="col-md-2"> {{notes.postedOn + 'Z' | date:'longDate'}} {{notes.postedOn + 'Z' | date: 'shortTime'}} By {{user?.firstName}}  {{user?.lastName}}</span>
                    <blockquote class="blockquote mb-0">
                        <blockquote class="blockquote mb-0">
                            <p class="mb-0 fst-italic">" {{notes.message}} "</p>
                        </blockquote>
                    </blockquote>
                </figure>
            </div>
        </div>

    </div>
</div>