import { CandidatePipelineComponent } from './components/ats/ats-layout/candidate-pipeline/candidate-pipeline.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AtsLayoutComponent } from './components/ats/ats-layout/ats-layout.component';
import { DashboardComponent } from './components/ats/dashboard/dashboard.component';
import { RedirectComponent } from './components/redirect/redirect.component';
import { LoginComponent } from './components/common/login/login.component';
import { AddJobComponent } from './components/ats/ats-layout/jobs/add-job/add-job.component';
import { MyJobsComponent } from './components/ats/ats-layout/jobs/my-jobs/my-jobs.component';
import { ApplicantViewComponent } from './components/ats/ats-layout/applicant-view/applicant-view/applicant-view.component';
import { SingleJobApplicantsComponent } from './components/ats/ats-layout/jobs/single-job-applicants/single-job-applicants.component';
import { ApplicationsByJobComponent } from './components/ats/ats-layout/jobs/applications-by-job/applications-by-job.component';
import { CheckoutComponent } from './components/ats/ats-layout/jobs/checkout/checkout.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'jius-ats',
    pathMatch: 'full'
  },
  {
    path: 'jius-ats',
    pathMatch: 'full',
    component: LoginComponent
  },
  {
    path: 'redirect',
    pathMatch: 'full',
    component: RedirectComponent
  },
  {
    path:'', component: AtsLayoutComponent,  data:{breadcrumb:'dashboard'},
    children: [
      {
        path: '', redirectTo: 'dashboard', pathMatch: 'full', data:{breadcrumb:'Dashboard'},
      },
      {
        path: 'jobs', component: ApplicationsByJobComponent, data:{breadcrumb:'Applications By Job'},
      },
      {
        path: 'singlejob/:id', component: SingleJobApplicantsComponent, data:{breadcrumb:'Applicant List For Job'},
      },
      {
        path: 'viewapplicant/:id', component: ApplicantViewComponent, data:{breadcrumb:'View Applicant'},
      },
      {
        path: 'job', component: AddJobComponent,  data: {routeName: 'job'}
      },
      {
        path: 'job/edit/:id', component: AddJobComponent, 
        children: [
          {
            path: 'checkout', component: CheckoutComponent, pathMatch: 'full',
          }],
        data: { routeName: 'job' }
      },
      {
        path: 'dashboard', component: DashboardComponent, data:{breadcrumb:''},
      },
      {
        path: 'my-jobs', component: MyJobsComponent, data:{breadcrumb:'My Jobs'},
      },
      {
        path: 'candidates/:filter', component: CandidatePipelineComponent, data:{breadcrumb:'Candidate Pipeline'},
      },
      {
        path: 'candidates', component: CandidatePipelineComponent, data: { breadcrumb: 'Candidate Pipeline' },
      },
      {
        path: 'candidates/:filter/:job', component: CandidatePipelineComponent, data: { breadcrumb: 'Candidate Pipeline' },
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
