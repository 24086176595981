<div class="example-header my-4 bg-white">
    <section class="container-fluid  px-md-4">
        <div class="logo row align-items-center">
            <div class="col">
                <div class="logo-img mr-auto">
                    <img src="{{logoUrl}}" alt="JIUS" />
                </div>
            </div>
            <div class="col">
                <div class="d-flex justify-content-end align-items-center">
                    
                    <a routerLink="/job" mat-raised-button color="accent" class="text-white header-add-btn text-uppercase">
                        <span class="material-icons">
                            add_circle
                        </span> Add Job
                    </a>
                    <span class="material-icons ms-3 user-icon text-muted" [matMenuTriggerFor]="menu">
                        account_circle
                    </span>
                    <mat-menu #menu="matMenu">
                        <!-- TO-Do: Remove disabled when functionality is introduced -->
                        <button mat-menu-item>{{userData?.firstName}} {{userData?.lastName}}</button>
                        <button disabled mat-menu-item>Edit Profile</button>
                        <button disabled mat-menu-item>Billing Info</button>
                        <button disabled mat-menu-item>Purchase History</button>
                        <button disabled mat-menu-item>Manage Users</button>
                        <button mat-menu-item (click)="logout()">Log out</button>
                    </mat-menu>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 pl-md-0">
                <app-breadcrumb></app-breadcrumb>
            </div>
        </div>
    </section>
</div>