<div class="row">
    <div class="col-12">
        <p class="mat-typography"> {{jobData.title}}
        </p>
    </div>
</div>
<div *ngIf="route.children.length === 0">
    <div class="row">
        <div class="col-lg-7 col-md-6 col-sm-7" *ngIf="packages">
            <ng-container *ngIf="showSlotPurchasePanel">
                <mar-accordion class="marketplace-accordion">
                    <mat-expansion-panel expanded>
                        <mat-expansion-panel-header class="bg-secondary condensed">
                            <mat-panel-title>
                                Your Existing JobsInTheUS Package
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="pb-3 row mat-typography">
                            <div class="col-1 margin-end">
                                <span class="badge rounded-pill bg-dark-blue">
                                    {{existingPackages.slots.available}}
                                </span>
                            </div>
                            <div class="col-11 ps-0">
                                <p>Existing Job Slots Available | <a class="text-dark-blue"><b>View Purchase History</b></a></p>
                            </div>

                        </div>
                        <mat-checkbox (change)="showOrHideSlots($event)" class="mat-typography">Use an Existing Job Slot When Activating This Job</mat-checkbox>
                    </mat-expansion-panel>
                </mar-accordion>
            </ng-container>
            <mat-accordion class="marketplace-accordion" multi>
                <ng-container *ngFor="let jobs of joboptions; let i = index">
                    <mat-expansion-panel *ngIf="showPanel(jobs.id)" [expanded]="expandColumn(jobs.id) && !showSlots(jobs.id)">
                        <mat-expansion-panel-header class="condensed">
                            <mat-panel-title>
                                {{jobs.header}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div *ngIf="jobs.id==2 && disableEnhancements" class="enhancement-message text-white">
                            Please note that job enhancements require a new or existing job slot package. Please select
                            a package above
                        </div>
                        <table class="mycart">
                            <thead>
                                <tr>
                                    <td class="pb-3">Product</td>
                                    <td class="pb-3 text-right">Price</td>
                                    <td class="pb-3 text-center">{{jobs.id==1 ? '' : 'Available Credits'}}</td>
                                    <td class="pb-3 text-center">Add to Cart</td>
                                </tr>
                            </thead>
                            <ng-container *ngIf="jobs.id==1">
                                <tbody
                                    *ngFor="let slotObjects of slotObjectConstant | keyvalue : originalOrder; let i=index">
                                    <tr>
                                        <td class="pb-3">
                                            {{slotObjectNames[slotObjects.key]}}
                                        </td>
                                        <td class="pb-1 text-right">
                                            <p>{{slotObjects.value | currency : 'USD':'symbol'}}</p>
                                        </td>
                                        <td class="pb-3 text-center">

                                        </td>
                                        <td class="pb-3 text-center">
                                            <input type="checkbox" id="{{'slots- ' + i}}"
                                                [checked]="isChecked('slots- ' + i)" class="enhancement-cart" (change)="addPackages($event, getCount(slotObjects.key), getMonths(slotObjects.key), slotObjects.value , 
                                                    'slots', 'job-slots-resume-views', 'slots- '+i)" />
                                        </td>
                                    </tr>
                                </tbody>
                            </ng-container>
                            <ng-container *ngIf="jobs.id==2">
                                <tbody *ngFor="let types of packageTypes | keyvalue: originalOrder; let i=index">
                                    <tr>
                                        <td class="pb-3 marketplace-enhancement-title" (mouseenter)="showSlotDescription(i)" (mouseleave)="hideSlotDescription(i)">
                                            {{types.value}}
                                        </td>
                                        <td class="pb-1 text-right">
                                            <p>{{packagePrices[types.key] | currency : 'USD':'symbol'}}</p>
                                        </td>
                                        <td class="pb-3 text-center">
                                            <span class="badge rounded-pill bg-primary">
                                                {{getAvailableCredits(types.key)}}
                                            </span>
                                        </td>

                                        <td class="pb-3 text-center">
                                            <input type="checkbox" id="{{'enhancement- ' + i}}" class="enhancement-cart"
                                                [disabled]="disableEnhancements"
                                                [checked]="isChecked('enhancement- ' + i)"
                                                (change)="addPackages($event, 1, null, packagePrices[types.key], 'enhancement', types.key, 'enhancement- '+i)" />
                                        </td>
                                    </tr>
                                    <tr *ngIf="slotDescription[i]">
                                        <span colspan="2">
                                            {{packageInfo[types.key]}}
                                        </span>
                                    </tr>
                                </tbody>
                            </ng-container>
                        </table>
                    </mat-expansion-panel>
                </ng-container>
            </mat-accordion>
        </div>
        <div class="col-lg-5 col-md-6 col-sm-5">
            <mat-accordion class="marketplace-accordion mycart-accordion" multi>
                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header class="condensed">
                        <mat-panel-title>
                            My Cart
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="mat-typography my-3" *ngIf="cartObject.length===0">
                        Your cart is empty. Please add a few packages! Or Click on Skip.
                    </div>
                    <table class="mycart">
                        <ng-container *ngFor="let cartItem of cartObject; let i = index">
                            <tr>
                                <td class="pb-3 mycart-name">
                                    <span>
                                        JobsInTheUS - {{cartItem.displayName}}
                                    </span>
                                </td>
                                <td class="pb-3">
                                    <span class="d-flex justify-content-between">{{cartItem.price | currency :
                                        'USD':'symbol'}}
                                        <span (click)="deleteCartItem(i, cartItem.packName, cartItem.selectedId)"
                                            class="material-icons ml-2">
                                            close
                                        </span>
                                    </span>
                                </td>
                            </tr>
                            <tr *ngIf="cartItem.isCredit">
                                <td class="pb-3 mycart-name">

                                    <span>
                                        {{cartItem.displayName}} Credit Applied
                                    </span>
                                </td>
                                <td class="pb-3">
                                    <span class="d-flex justify-content-between">
                                        - {{cartItem.price | currency : 'USD':'symbol'}}
                                    </span>
                                </td>
                            </tr>
                        </ng-container>
                        <tfoot>
                            <tr>
                                <th class="py-2"><span class="text-uppercase total-text">Total</span></th>
                                <th class="py-2"> {{getCartTotal() | currency : 'USD':'symbol'}}</th>
                            </tr>
                        </tfoot>
                    </table>
                    <div class="d-flex mt-2" [ngClass]="cartObject.length===0 ? 'justify-content-between' : 'justify-content-end'">
                        <button *ngIf="cartObject.length===0" (click)="skipMarketplace()" mat-raised-button
                            color="primary" class="apply-btn text-uppercase mb-4">Skip</button>
                        <button (click)="proceedToPayment()" mat-raised-button color="primary"
                            [disabled]="cartObject.length===0" class="apply-btn text-uppercase mb-4">Check Out</button>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</div>

<router-outlet></router-outlet>