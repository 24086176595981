<div class="chat-content w-100 review-content">
	<div *ngIf="showHiredContent">
		<div class="container">
			<div class="row">
				<h2 class="mt-2">Acceptance Date</h2>
				<div class="col-md-4 d-flex align-items-center">
					<mat-icon class="me-2 material-icons-outlined icons-primary">
						edit_calendar
					</mat-icon>
					<p *ngIf="acceptedDate" class="mb-0 mat-typography">{{acceptedDate | date:'short'}}</p>
					<p *ngIf="offerDate" class="mb-0 mat-typography">{{ offerDate | date: 'short'}}</p>
				</div>
				<div class="col-md-4">
					<!-- <button mat-raised-button color="primary" (click)="openDialog(hiredDialog, 600)" class="text-uppercase">
					Deactivate this Job Posting
				</button> -->
				</div>
			</div>
			<hr />

			<div *ngIf="disabled" class="row d-flex align-items-center">
				<div class="col-md-6">
					<p class="mat-typography">Expected Start Date:
						<b>{{ expected }}</b>
					</p>
					<p class="mat-typography">Actual Start Date:
						<b>{{ actual }} </b>
					</p>
				</div>
			</div>
			<form *ngIf="!disabled" [formGroup]="hiringform" (ngSubmit)="updateHiring(hiringform.value)">
				<div class="row">
					<h2 class="mt-2">Employee Start Dates</h2>

					<div class="col-md-3">
						<label class="d-block mb-0">Expected Start Date </label>
						<mat-form-field class="w-100" appearance="outline">
							<input [disabled]="disabled" matInput formControlName="expected_start_date"
								placeholder="mm/dd/yyyy" [matDatepicker]="picker" />
							<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
							<mat-datepicker [startAt]="expected_start_date" #picker></mat-datepicker>
						</mat-form-field>
					</div>
					<div class="col-md-3">
						<label class="d-block mb-0">Actual Start Date</label>
						<mat-form-field class="w-100" appearance="outline">
							<input [disabled]="disabled" matInput formControlName="actual_start_date"
								placeholder="mm/dd/yyyy" [matDatepicker]="picker1" />
							<mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
							<mat-datepicker [startAt]="actual_start_date" #picker1></mat-datepicker>
						</mat-form-field>

					</div>
					<div class="col-md-3 schedule-button">
						<button [disabled]="hiringform.invalid || disabled" mat-raised-button color="primary"
							type="submit" class="text-uppercase">
							Save
						</button>
					</div>

				</div>
			</form>
		</div>
	</div>
	<div *ngIf="!showHiredContent">
		<h2 class="text-center mt-4 mat-typography">No Offer has been accepted by the Applicant yet!</h2>
	</div>
</div>
<ng-template #hiredDialog>
	<button class="close" mat-button (click)="closeDialog()">X</button>

	<mat-dialog-content>
		<div class="row d-flex align-items-center">

			<h2 mat-dialog-title> Deactivate this job and notify all applicants that the position has
				been filled ?</h2>


			<div class="row my-2">
				<mat-checkbox color="primary" class="mat-typography">
					Deactivate this Job Posting</mat-checkbox>
			</div>
			<div class="row my-2">
				<mat-checkbox color="primary" class="mat-typography">
					Mark Applicants Not Hired as "Rejected - More Qualified Candidate Selected"
				</mat-checkbox>
			</div>
			<div class="row my-2">
				<mat-checkbox color="primary" (change)="showEmail($event)" class="mat-typography">
					Send
					Notification to Applicants</mat-checkbox>
			</div>
		</div>
		<div class="m-3" *ngIf="showEmailTemplate">
			<angular-editor [config]="editorConfig"></angular-editor>
		</div>
	</mat-dialog-content>
	<div mat-dialog-actions class="m-2" align="end">
		<button mat-stroked-button color="accent" class="text-uppercase" (click)="closeDialog()"
			mat-dialog-close>Cancel</button>

		<button mat-raised-button color="primary" class="mx-4 text-uppercase" mat-dialog-close>{{
			showEmailTemplate ? 'Update and Send' : 'Update'}}</button>
	</div>
</ng-template>