import { ApplicantHiringComponent } from './components/ats/ats-layout/applicant-view/applicant-hiring/applicant-hiring.component';
import { CandidatePipelineComponent } from './components/ats/ats-layout/candidate-pipeline/candidate-pipeline.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './components/common/login/login.component';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { AtsLayoutComponent } from './components/ats/ats-layout/ats-layout.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { HeaderComponent } from './components/common/header/header.component';
import { SidebarComponent } from './components/common/sidebar/sidebar.component';
import { DashboardComponent } from './components/ats/dashboard/dashboard.component';
import { MatSortModule } from '@angular/material/sort';
import { ApplicantViewComponent } from './components/ats/ats-layout/applicant-view/applicant-view/applicant-view.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ApplicantMessageComponent } from './components/ats/ats-layout/applicant-view/applicant-message/applicant-message.component';
import { ApplicantReviewsComponent } from './components/ats/ats-layout/applicant-view/applicant-reviews/applicant-reviews.component';
import { ApplicantPostComponent } from './components/ats/ats-layout/applicant-view/applicant-post/applicant-post.component';
import { ApplicantOffersComponent } from './components/ats/ats-layout/applicant-view/applicant-offers/applicant-offers.component';
import { RedirectComponent } from './components/redirect/redirect.component';
import { BreadcrumbComponent } from './components/common/breadcrumb/breadcrumb.component';
import { AddJobComponent } from './components/ats/ats-layout/jobs/add-job/add-job.component';
import { AtsPreviewComponent } from './components/ats/ats-layout/jobs/ats-preview/ats-preview.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {MatStepperModule} from '@angular/material/stepper';
import { MyJobsComponent } from './components/ats/ats-layout/jobs/my-jobs/my-jobs.component';
import { ApplicationsByJobComponent } from './components/ats/ats-layout/jobs/applications-by-job/applications-by-job.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { SingleJobApplicantsComponent } from './components/ats/ats-layout/jobs/single-job-applicants/single-job-applicants.component';
import { OnCreateDirective } from './directives/on-create.directive';
import { ApplicantInterviewComponent } from './components/ats/ats-layout/applicant-view/applicant-interview/applicant-interview.component';
import { MatChipsModule } from '@angular/material/chips';
import { ApplicantTextComponent } from './components/ats/ats-layout/applicant-view/applicant-text/applicant-text.component';
import { MatSnackBarModule} from '@angular/material/snack-bar';
import { BarRatingModule } from 'ngx-bar-rating';
import { AgGridModule } from 'ag-grid-angular';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MyJobsSettingsComponent } from './components/common/ag-grid/my-jobs-settings/my-jobs-settings.component';
import { StepOneComponent } from './components/ats/ats-layout/jobs/steps/step-one/step-one.component';
import { StepTwoComponent } from './components/ats/ats-layout/jobs/steps/step-two/step-two.component';
import { StepThreeComponent } from './components/ats/ats-layout/jobs/steps/step-three/step-three.component';
import { StepFourComponent } from './components/ats/ats-layout/jobs/steps/step-four/step-four.component';
import { JobsrouterComponent } from './components/common/ag-grid/jobsrouter/jobsrouter.component';
import { CandidateRendererComponent } from './components/common/ag-grid/candidate-renderer/candidate-renderer.component';
import { InviteJobDialogComponent } from './components/common/invite-job-dialog/invite-job-dialog.component';
import { JobMarketplaceComponent } from './components/ats/ats-layout/jobs/job-marketplace/job-marketplace.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatExpansionModule, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { CheckoutComponent } from './components/ats/ats-layout/jobs/checkout/checkout.component';
import { HttpInterceptorService } from './services/http-interceptor.service';
@NgModule({
  declarations: [
    AppComponent,
    SafeUrlPipe,
    LoginComponent,
    AtsLayoutComponent,
    SingleJobApplicantsComponent,
    HeaderComponent,
    SidebarComponent,
    BreadcrumbComponent,
    DashboardComponent,
    ApplicantViewComponent,
    ApplicantMessageComponent,
    ApplicantReviewsComponent,
    ApplicantPostComponent,
    ApplicantOffersComponent,
    ApplicantInterviewComponent,
    RedirectComponent,
    AddJobComponent,
    AtsPreviewComponent,
    MyJobsComponent,
    ApplicationsByJobComponent,
    SafeUrlPipe,
    OnCreateDirective,
    CandidatePipelineComponent,
    ApplicantHiringComponent,
    ApplicantTextComponent,
    MyJobsSettingsComponent,
    StepOneComponent,
    StepTwoComponent,
    StepThreeComponent,
    StepFourComponent,
    JobsrouterComponent,
    CandidateRendererComponent,
    InviteJobDialogComponent,
    JobMarketplaceComponent,
    CheckoutComponent
    
  ],
  imports: [
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AngularEditorModule,
    MatCardModule,
    MatStepperModule,
    MatNativeDateModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    CommonModule,
    FormsModule,
    MatTabsModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatRadioModule,
    MatIconModule,
    MatDatepickerModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatSliderModule,
    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
    MatToolbarModule,
    MatButtonModule,
    MatPaginatorModule,
    HttpClientModule,
    MatTableModule,
    MatSortModule,
    MatSnackBarModule,
    BarRatingModule,
    MatAutocompleteModule,
    CdkAccordionModule,
    MatExpansionModule,
    MatDialogModule,
    AgGridModule.withComponents([])
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: HttpInterceptorService,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
