<div class="chat-panel">
	<div class="chat-footer container py-3">
		<h2 class="mt-2">{{ offered ? 'Offers Sent to Candidate' : ''}}</h2>
		<div class="row" *ngFor="let offer of offerData">

			<div class="d-flex">
				<div class="col-md-4 d-flex align-items-center">
					<mat-icon class="me-2 material-icons-outlined icons-primary">
						edit_calendar
					</mat-icon>
					<p class="mb-0 mat-typography">{{offer.offer_sent_date | date: 'short'}}</p>
				</div>
				<div class="col-md-4">
					<mat-checkbox (change)="openDialog(confirmOffer, 400, offer.id)" color="primary"
						[(ngModel)] = "checked" [checked]="offer.offer_accepted" class="mat-typography"
						[disabled]="disableAccept">
						Offer Accepted
					</mat-checkbox>
				</div>
				<div class="col-md-3">
					<button mat-raised-button (click)="openDialog(viewOffer, 600, offer.id)" color="primary">View
						Offer</button>
				</div>
			</div>
			<hr class=" my-4 mx-2">
			<ng-template #confirmOffer>
				<button class="close" mat-button (click)="closeDialog()">X</button>

				<mat-dialog-content>
					
					<div class="row d-flex align-items-center">

						<h2 class="mat-typography font-condensed" mat-dialog-title> Are you sure the candidate accepted the offer sent
							on {{offer.offer_sent_date | date: 'short'}} ?</h2>

						<div class="col-md-3">
							<button mat-stroked-button color="accent" class=" text-uppercase" (click)="showConfirm()"
								mat-dialog-close>Yes</button>

						</div>
						<div class="col-md-3">
							<button mat-stroked-button color="primary" class=" text-uppercase" (click)="closeDialog()"
								mat-dialog-close>No</button>
						</div>
						<div *ngIf="showConfirmContent">
							<form [formGroup]="hiringform" (ngSubmit)="updateHired(offerId, hiringform.value)">
							<div class="col-md-6 mt-3">
								<label class="d-block mb-0 font-condensed">Accepted Date</label>
								<mat-form-field class="w-100" appearance="outline">
									<input matInput formControlName="accepted_date" [max]="today" placeholder="mm/dd/yyyy" [matDatepicker]="picker1" />
									<mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
									<mat-datepicker #picker1></mat-datepicker>
								</mat-form-field>
							</div>

							<div class="col-md-3">
								<button mat-stroked-button color="accent" class=" text-uppercase"
									[disabled]="hiringform.invalid" type="submit" mat-dialog-close>Confirm</button>

							</div>
							</form>
						</div>


					</div>
					
				</mat-dialog-content>

			</ng-template>

			<ng-template #viewOffer>
				<button class="close" mat-button (click)="closeDialog()">X</button>

				<mat-dialog-content>

					<div class="row d-flex align-items-center">

						<h2 class="mat-typography" mat-dialog-title>Offer </h2>
						<div class="col-md-6">
							<p class="mat-typography">Offer Sent On:

								<b>{{ offer.offer_sent_date | date: 'short'}}</b>
							</p>
							<p class="mat-typography">Offer Sent By:

								<b>Joel </b>
							</p>
							<p class="mat-typography">
								Offer Status: <b>{{ offer.offer_accepted ? 'Accepted':'Not Accepted'}}</b>
							</p>
						</div>
						<div class="col-md-12">
							<p>Offer Content </p>
							<div class="card">
								<div class="card-body">
									{{offer.email_content}}
								</div>

							</div>
						</div>
					</div>
				</mat-dialog-content>

			</ng-template>

		</div>
		<div class="row d-flex align-items-center">
			<h2 mat-dialog-title>{{ offered ? 'Make Another Offer' : 'Make an Offer to Candidate'}}</h2>
			<p class="mat-typography mb-0">Select Email Template</p>
			<div mat-dialog-content class="col-md-4 mt-0">
				<mat-form-field style="margin-bottom: -1.25em" appearance="outline" ngDefaultControl>
					<mat-select [disabled]="disableOffer"  (selectionChange)="loadJobTemplate($event.value)">
						<mat-option value=''></mat-option>
						<mat-option value="DEFAULT_OFFER">Default Job Offer Letter</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-4">
				<mat-checkbox color="primary" (change)="showEmail($event)" class="ms-2 mat-typography">
					Send
					a Copy to Myself</mat-checkbox>
			</div>
		</div>
		<div class="mt-3">
			<form [formGroup]="formdata">
				<div class="col-md-12 col-lg-12 col-xl-12">
			
					<mat-form-field appearance="outline" class="messageinput w-100">
						<textarea [disabled]="disableOffer" matInput cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" #mname
							formControlName="offerText" [(ngModel)]="offerText"></textarea>
			
					</mat-form-field>
			
				</div>
				<div class="d-flex ">
					<div class="col-md-5 col-lg-4 col-xl-2 me-4">
						<button mat-raised-button class="post-btn text-uppercase mt-2 " type="submit" [disabled]="formdata.invalid || disableOffer"
					  	 (click)="sendMail(offerText)" color="primary">Send Offer
							
						</button>
					</div>
					<div class="file-upload mt-1 ms-2">
						<input type="file" multiple class="file-input d-none" (change)="onFileSelected($event)" #fileUpload>
						<div class="file-upload">
							<button [disabled]="formdata.invalid || disableOffer" mat-mini-fab color="primary" class="upload-btn" (click)="fileUpload.click()">
								<mat-icon>attach_file</mat-icon>
							</button>
						</div>
			
					</div>
					<div class="mt-3 ms-2" *ngFor="let file of fileName">{{file || ""}}</div>
				</div>
			</form>
		</div>
	</div>
</div>