<div class=" h-100">
	<div class="col-md-12  candidate-header">
		<div class="heading ">
			<h2 class="mat-h1 mb-0 bg-white text-uppercase">Applications By Job</h2>
		</div>
	</div>
	<div *ngIf="!showSpinner" class="mb-2 d-flex justify-content-end">
		<button (click)="resetGrid()" color="accent" mat-stroked-button>Reset All Filters
			<mat-icon class="mb-2">filter_alt_off</mat-icon>
		</button>
	</div>
	<div class="col-md-12 ">
		<div *ngIf="showSpinner" class="d-flex m-3 justify-content-center">
			<div class="spinner-border" role="status">
				<span class="visually-hidden">Loading...</span>
			</div>
		</div>

		<ag-grid-angular *ngIf="!showSpinner" style="height: 1000px;" class="ag-theme-alpine" [rowData]="rowData"
			[suppressMenuHide]="true" [columnDefs]="columnDefs" [getRowStyle]="getRowStyle"
			[defaultColDef]="defaultColDef" [rowHeight]="rowHeight" (gridReady)="onGridReady($event)"
			(firstDataRendered)="onFirstDataRendered($event)" (filterChanged)="onFilterChanged($event)" [pagination]="true">
		</ag-grid-angular>
	</div>
</div>