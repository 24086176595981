import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-step-one',
  templateUrl: './step-one.component.html',
  styleUrls: ['./step-one.component.scss']
})
export class StepOneComponent implements OnInit {

  @Input() jobCompany: any;
  @Input() jobData: any;
  @Input() companies: any = [];
  stepOneForm: FormGroup;
  jobId: string;
  logoURL: any;



  constructor(
    private _cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private router: ActivatedRoute
  ) {
    this.stepOneForm = this.formBuilder.group({
      altName: this.formBuilder.group({
        value: [],
        visible: ['']
      }),
      altAddress1: this.formBuilder.group({
        value: [],
        visible: ['']
      }),
      altCity: this.formBuilder.group({
        value: [],
        visible: ['']
      }),
      altState: this.formBuilder.group({
        value: [],
        visible: ['']
      }),
     
      altEmail: this.formBuilder.group({
        value: [],
        visible: ['']
      }),
      altPhone: this.formBuilder.group({
        value: [],
        visible: ['']
      }),
      altZip: this.formBuilder.group({
        value: [],
        visible: ['']
      }),
      altCompany: this.formBuilder.group({
        value: [],
        visible: [false]
      }),

    });
  }
  
  updateCompany(event) {

    this.jobCompany = this.companies.find((x) => x.id == event.value);
    this.updateDetaultDetails();
    this._cdr.detectChanges();

  }

  updateAltDetails() {
    this.stepOneForm.get('altName').patchValue({
      value: this.jobData.altName?.value ? this.jobData.altName.value : '',
      visible: this.jobData.altName?.visible
    })
    this.stepOneForm.get('altAddress1').patchValue({
      value: this.jobData.altAddress1?.value ? this.jobData.altAddress1.value : '',
      visible: this.jobData.altAddress1?.visible
    })
    this.stepOneForm.get('altCity').patchValue({
      value: this.jobData.altCity?.value ? this.jobData.altCity.value : '',
      visible: this.jobData.altCity?.visible
    })
    this.stepOneForm.get('altState').patchValue({
      value: this.jobData.altState?.value ? this.jobData.altState.value : '',
      visible: this.jobData.altState?.visible
    })
    this.stepOneForm.get('altEmail').patchValue({
      value: this.jobData.altEmail?.value ? this.jobData.altEmail.value : '',
      visible: this.jobData.altEmail?.visible
    })
    this.stepOneForm.get('altPhone').patchValue({
      value: this.jobData.altPhone?.value ? this.jobData.altPhone.value : '',
      visible: this.jobData.altPhone?.visible
    })
    this.stepOneForm.get('altZip').patchValue({
      value: this.jobData.altZip?.value ? this.jobData.altZip.value : '',
      visible: this.jobData.altZip?.visible
    })
    this.stepOneForm.get('altCompany').patchValue({
      value: this.jobCompany.name ? this.jobCompany.name : '',
      visible: this.jobData.altCompany?.visible
    })
  }

  updateDetaultDetails() {
    this.stepOneForm.get('altName').patchValue({
      value: this.jobCompany.name ? this.jobCompany.name : '',
      visible: true
    })
    this.stepOneForm.get('altAddress1').patchValue({
      value: this.jobCompany.address1 ? this.jobCompany.address1 : '',
      visible: true
    })
    this.stepOneForm.get('altCity').patchValue({
      value: this.jobCompany?.city ? this.jobCompany?.city : '',
      visible: true
    })
    this.stepOneForm.get('altState').patchValue({
      value: this.jobCompany?.state ? this.jobCompany?.state : '',
      visible: true
    })
    this.stepOneForm.get('altEmail').patchValue({
      value: this.jobCompany?.email ? this.jobCompany?.email : '',
      visible: true
    })
    this.stepOneForm.get('altPhone').patchValue({
      value: this.jobCompany?.phone ? this.jobCompany?.phone : '',
      visible: true
    })
    this.stepOneForm.get('altZip').patchValue({
      value: this.jobCompany?.zip ? this.jobCompany?.zip :'',
      visible: true
    })
    this.stepOneForm.get('altCompany').patchValue({
      value: this.jobCompany?.name ? this.jobCompany?.name : '',
      visible: true
    })
  }
  ngOnInit(): void {

    this.logoURL = environment.FILE_URL + this.jobCompany.logoFileId;
   
    this.router.paramMap.subscribe(params => {
      this.jobId = params.get('id');
    })
    if (this.jobId) {
      this.updateAltDetails();
    }
    else {
      
      this.updateDetaultDetails();
    }


  }

}
