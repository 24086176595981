import { Component, OnInit } from '@angular/core';
import { MenuItems } from 'src/app/model/menu';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  constructor(public sharedService: SharedService) { }

  navItems: MenuItems[];


  getMenu() {
    this.sharedService.getMenuLinks().subscribe(
      (response: any) => {
        this.navItems = response
      }
    );
  }

  ngOnInit(): void {
    this.getMenu();
  }

}
