export class ViewApplicant {


    public static getValue(key, dataSource) {
        if (key) {
            return (dataSource.find((x) => x.key === key).value);
        }

    }

 public static DISPLAY_STAGE ={
    New : 'New',
    Review : 'Review',
    Interview : 'Interview',
    Offer: 'Offer',
    Hired : 'Hired'

  }

    public static DISPLAY_VALUE =  {

        New: '5',
        Review: '25',
        Interview: '50',
        Offer: '70',
        Hired: '100'

    }

    public static PAY_TYPE = {
        salary: 'Salary',
        hourly: 'Hourly',
        commission: 'Commission',
        volunteer: 'Volunteer',
        salary_plus_commission: 'Salary + Commission'
    }

    public static EDUCATION = {
        high_school: 'High School or Equivalent',
        some_college: 'Some College Completed',
        associate: 'Associate Degree',
        bachelors: 'Bachelor\'s Degree',
        masters: 'Master\'s Degree',
        mba: 'MBA',
        phd: 'Ph.D.',
        other_professional: 'Other Professional Degree'
    }

    public static JOB_TYPE = {
        regular: 'Regular',
        internship: 'Internship',
        seasonal: 'Seasonal',
        temporary: 'Temporary',
        temp_to_hire: 'Temp To Hire',
        volunteer: 'Volunteer',
        domestic: 'Domestic',
        medical_trials: 'Medical Trials',
        school_year: 'School Year'
    }

    public static CAREER_LEVEL = {
        student: 'Student',
        entry: 'Entry Level (<2 years experience)',
        mid: 'Mid Level (2-10 years experience)',
        senior: 'Senior Level (10+ years experience)',
        director: 'Manager/Director',
        executive: 'Executive'
    }

    public static REJECTED = {
        offer_withdrawn: 'Unresponsive',
        not_hireable: 'Did not meet qualifications',
        not_good_fit: 'Did not meet qualifications',
        pass_for_future: 'More qualified candidate selected',
        rejected: 'More qualified candidate selected'
    }
    public static STAGES = {
        '': 'New',
        Read: 'Review',
        reviewed: 'Review',
        contacted: 'Review',
        screened_in_person: 'Review',
        background_check: 'Review',
        screened_phone: 'Review',
        interviewed: 'Interview',
        offer_sent: 'Offer',
        offer_accepted: 'Offer',
        Unread: 'New',
        pass_for_future: 'Rejected',
        candidate_withdrew: 'Withdrawn',
        offer_withdrawn: 'Rejected',
        not_hireable: 'Rejected',
        not_good_fit: 'Rejected',
        rejected: 'Rejected',
        hired: 'Hired',
        lead: 'lead',
        'Candidate Withdrew': 'Withdrawn',
        'rejected-more-qualified-selected': <any>"Rejected",
        'rejected-no-show-for-interview': <any>"Rejected",
        'rejected-unresponsive': <any>"Rejected",
        'rejected-did-not-meet-qualifications': <any>"Rejected",
        rejected_more_qualified_selected: "Rejected",
        rejected_no_show_for_interview: "Rejected",
        rejected_unresponsive: "Rejected",
        rejected_did_not_meet_qualifications: "Rejected"

    }
    public static TRAVEL = {
        '0pct': 'No Traveling',
        '25pct': 'Up to 25%',
        '50pct': 'Up to 50%',
        '75pct': 'Up to 75%',
        '100pct': '100%'

    }

    // To-Do : Raghu - Convert to objects like above
    public static REMOTE = [
        {
            key: 'anywhere',
            value: 'Remote nationwide'
        },
        {
            key: 'limited',
            value: 'Remote statewide'
        },
        {
            key: 'optional',
            value: 'Remote optional'
        }
    ];

}
