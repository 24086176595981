export class Jobs {
    job: Job
    enhancements: string[]
}

export class Job {
    id: ''
    title: string
    isActive: boolean
    isDeleted: boolean
    isQuickPost: boolean
    code: ''
    startsAt: any;
    endsAt: any;
    sortsAt: any;
    createdAt: any
    updatedAt: ''
    companyId: ''
    posterId: ''
    altCompany: AltCompany
    applicationSettings: ApplicationSettings
    isArchived: boolean
    byline: ''
    description: ''
    benefits: ''
    requirements: ''
    category1Id: ''
    category2Id: ''
    category3Id: ''
    locationId: ''
    locationRemote: ''
    locationCityDisplay: ''
    type: ''
    status: ''
    careerLevel: ''
    education: ''
    travel: ''
    payType: ''
    payRangeLow: ''
    payRangeHigh: ''
    botId: ''
    altAddress1: AltAddress1
    altCity: AltCity
    altState: AltState
    altZip: AltZip
    openings: ''
    altName: AltName
    altEmail: AltEmail
    altPhone: AltPhone
    botRunId: ''
    screeningQuestions: any
    tenantId: any
}



export class AltCompany {
    value: string
    visible: boolean
}

export class ApplicationSettings {
    email: Email
    hasResume: boolean
}

export class Email {
    notify: string
    address: string
    subject: string
}

export class AltAddress1 {
    value: string
    visible: boolean
}

export class AltCity {
    value: string
    visible: boolean
}

export class AltState {
    value: string
    visible: boolean
}

export class AltZip {
    value: string
    visible: boolean
}

export class AltName {
    value: string
    visible: boolean
}

export class AltEmail {
    value: string
    visible: boolean
}

export class AltPhone {
    value: string
    visible: boolean
}
