import { Component, OnInit, ViewChild } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { Router } from '@angular/router';
import { AtsService } from 'src/app/services/ats.service';
import { SharedService } from 'src/app/services/shared.service';
import { environment } from 'src/environments/environment';


export interface Section {
  count: number;
  name: string;
}

export interface News {
  count: number;
  name: string;
}

export interface Distrbs {
  icon: string;
  job: string;
  views: number;
  application: number
}

export interface currentJob {
  count: number;
  name: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {

  applicationsToReview = [];
  companyId: any;
  statistics: any;
  topBarData: any;
  unreadMessagesCount = [];
  jobSlots: any;
  packages = [];
  unlimitedJobSlots: any;
  packageType: any;
  showActive = false;
  checkedAllJobs = false;
  unreadOriginal = [];
  distributions = [];

  constructor(private router: Router, private sharedService: SharedService, private atsService: AtsService) {

  }
  goToMarketplace(id){

    this.atsService.goToMarketplace(true);
    this.router.navigate(['job/edit/', id])
    
  }

  changeActiveOrInactive($event: MatRadioChange) {

    if ($event.value === 'active') {
      this.getReviewApplications(true);
      this.showActive = true;
      this.updateUnreadCount(true);
    }
    else {
      this.getReviewApplications(false);
      this.showActive = false;
      this.updateUnreadCount(false);
    }
  }
  updateUnreadCount(value) {

    this.unreadOriginal = this.unreadMessagesCount;
    if (value === true) {

      this.unreadMessagesCount.forEach((message) => {
        if (message.active === true) {
          this.unreadMessagesCount.length = 0;
          this.unreadMessagesCount.push(message);
        }
      })
    }
    else {
      this.getUnreadMessages();
    }

  }

  getReviewApplications(status) {
    this.sharedService.getAllJobs().subscribe(
      (response: any) => {
        if (status === true) {
          this.applicationsToReview.length = 0;

          response.data.forEach((apps) => {
            if (apps.status === true && apps.newApplicants > 0) {
              this.applicationsToReview.push(apps);
            }
          })
        }
        else {

          this.applicationsToReview.length = 0;
          const applications = response.data;
          this.applicationsToReview = applications.sort((a, b) => a.title.localeCompare(b.title));
        }

        this.companyId = response.data[0].companyId;
        this.getStatisticsData(this.companyId);
        this.getTopBarData(this.companyId);
      }
    )
  }
  getTopBarData(companyId) {
    this.sharedService.getDashboardTopBar(companyId).subscribe(
      (response: any) => {
        this.topBarData = response.data;
      }
    )
  }
  getStatisticsData(companyId) {
    this.sharedService.getStatisticsData(companyId).subscribe(
      (response: any) => {

        this.statistics = response.data;
      }
    )
  }

  getPackageDetails() {
    this.sharedService.getPackageDetails().subscribe(
      (response: any) => {
        //  WIP - Raghu - Fix to show based on endsAt
        this.jobSlots = (response.data.jobSlots - response.data.jobSlotsInUse);
        this.packages = response.data.dashboardPackageDetails[0].endsAt;
        this.packageType = PACKAGE_TYPES[response.data.dashboardPackageDetails[0].type];
        this.unlimitedJobSlots = response.data.unlimitedJobSlots;
      }
    )
  }

  getUnreadMessages() {
    this.sharedService.getUnreadMessages().subscribe(
      (response: any) => {

        this.unreadMessagesCount = response;
      }
    )
  }

  getRecommendations(){
    this.sharedService.getRecommendations().subscribe(
      (response: any) => {

        this.distributions = response.data;
      }
    )
  }

  async getUserDetails() {
    this.sharedService.loginAts().subscribe((res) => {

      if (res.data.response !== "LOGIN_VALID") {
        localStorage.removeItem('token');
        window.location.href = environment.ATS_ENV;
      }
    },(error) =>{
      localStorage.removeItem('token');
      window.location.href = environment.ATS_ENV;
    })
  }

  ngOnInit(): void {
    this.getUserDetails();
    this.getReviewApplications(false);
    this.getUnreadMessages();
    this.getPackageDetails();
    this.getRecommendations();
    this.checkedAllJobs = true;
  }

  selectHtml() {

  }

}
export enum PACKAGE_TYPES {
  ats_one_year = <any>'ATS',
  ats_one_year_plus = <any>'ATS+'
}