import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  logoUrl = "../../assets/images/Logo-US.svg";
  userData: any;

  constructor(private sharedService: SharedService) { }


  getUserDetails() {
    this.sharedService.loginAts().subscribe((res) => {

      if (res.data.response == "LOGIN_VALID") {
        this.userData = res.data.userDetails;
      } else {
        localStorage.removeItem('token');
        window.location.href = environment.ATS_ENV;
      }
    }, (error) => {

      localStorage.removeItem('token');
      window.location.href = environment.ATS_ENV;
    })
  }

  logout(){
    this.sharedService.logout().subscribe((res:any)=>{
      if (res.data == "LOGOUT_SUCCESSFUL") {
        window.location.href = environment.ATS_ENV;
        localStorage.removeItem('token');
      }
    })
  }

  ngOnInit(): void {
    this.getUserDetails();
  }

}
