export class GlobalConstants {
    // TO-DO: Remove unused

    public static categories = [
        {
            title: "Accounting"
        },
        {
            title: "Finance"
        },
        {
            title: "Automotive"
        }

    ]
    public static JIUS_OLD = "http://localhost:3000/jobs";

    public static TravelRequirement =
        {
            'zeropct': "No Travelling",


            'twentyfivepct': "Up To 25%",

            'fiftypct': "Up To 50%",

            'seventyfivepct': "Up To 75%",

            'hundredpct': "100%"
        }

    public static JOB_TYPE = {
        regular: 'Regular',
        internship: 'Internship',
        seasonal: 'Seasonal',
        temporary: 'Temporary',
        temptohire: 'Temp To Hire',
        volunteer: 'Volunteer',
        domestic: 'Domestic',
        medicaltrials: 'Medical Trials',
        schoolyear: 'School Year'
    }

    public static CAREER_LEVEL = {
        student: 'Student',
        entry: 'Entry Level (<2 years experience)',
        mid: 'Mid Level (2-10 years experience)',
        senior: 'Senior Level (10+ years experience)',
        director: 'Manager/Director',
        executive: 'Executive'
    }

    public static terms = {

        fulltime: "Full-Time",
        parttime: "Part-Time",
        contract: "Contract",
        perdiem: "Per-Diem"

    }



    public static PAY_TYPE = {
        salary: 'Salary',
        hourly: 'Hourly',
        commission: 'Commission',
        volunteer: 'Volunteer',
        salarypluscommission: 'Salary + Commission'
    }

    public static EDUCATION = {
        highschool: 'High School or Equivalent',
        somecollege: "Some College Completed",
        associate: "Associate Degree",
        bachelors: "Backelor's Degree",
        masters: "Master's Degree",
        mba: "MBA",
        phd: "Ph.D.",
        otherprofessional: "Other Professional Degree"
    }

    public static payRange = [
        25000, 30000, 35000, 40000, 45000, 50000, 55000, 60000, 65000, 70000, 75000, 80000, 85000, 90000, 95000,
        100000, 125000, 150000, 175000, 200000
    ]


}