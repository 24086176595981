import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-applications-by-job',
  templateUrl: './applications-by-job.component.html',
  styleUrls: ['./applications-by-job.component.scss']
})
export class ApplicationsByJobComponent implements OnInit {

  jobData: any = [];
  dataSource: any;
  showSpinner: boolean;
  message: string;
  defaultColDef;
  columnDefs: any;
  rowData: any;
  rowStyle: { background: string; };
  rowHeight: number;
  getRowStyle: (params: any) => { background: string; 'padding-bottom': string; };
  gridApi: any;
  gridColumnApi: any;
  innerWidth: number;
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.innerWidth = window.innerWidth;
  }
  constructor(private sharedService: SharedService, private router: Router) {
    this.onResize();
    this.columnDefs = [
      {
        field: 'title',
        width: 300,
        filter: 'agMultiColumnFilter',

        cellStyle: { fontSize: '1.375rem', fontFamily: 'Myriad Pro' },
        cellRenderer: function (params) {
          let createdDate = new Date(params.data.createdAt).toLocaleDateString();
          let city = params.data.city ? params.data.city + ', ' : ''
          let state = params.data.state ? params.data.state : '';
          if (params.data)
            return '<a style="padding-left:12px;" href="/singlejob/' + params.data.id + '" >' + params.data.title + '<p class="mat-typography mt-2" style="padding-left:12px;">' + city + state + ' | ' + createdDate + ' | ' + '</p></a>';
        },
        valueGetter: function (params) {
          let city = params.data.city ? params.data.city + ', ' : ''
          let state = params.data.state ? params.data.state : '';
          return params.data.title + city + state;
        },
        suppressSizeToFit: true

      },
      {
        field: 'jobStatus',
        valueGetter: function (params) {
          return params.data.status ? 'Active' : 'Inactive';

        },
        filter: 'agSetColumnFilter',
        floatingFilter: false,
        cellStyle: {
          textAlign: 'center',
        }

      },
      {
        field: 'views',
        filter: 'agSetColumnFilter',
        floatingFilter: false,
        cellStyle: {
          textAlign: 'center',
        }

      },
      {
        field: 'newApplicants',
        filter: 'agSetColumnFilter',
        floatingFilter: false,
        cellStyle: {
          textAlign: 'center',
        }

      },
      {
        field: 'inReview',
        filter: 'agSetColumnFilter',
        floatingFilter: false,
        cellStyle: {
          textAlign: 'center',
        }
      },
      {
        field: 'inInterview',
        filter: 'agSetColumnFilter',
        floatingFilter: false,
        cellStyle: {
          textAlign: 'center',
        }
      },
      {
        field: 'offered',
        filter: 'agSetColumnFilter',
        floatingFilter: false,
        cellStyle: {
          textAlign: 'center',
        }
      },
      {
        field: 'hired',
        filter: 'agSetColumnFilter',
        floatingFilter: false,
        cellStyle: {
          textAlign: 'center',
        }
      },
      {
        field: 'leads',
        filter: 'agSetColumnFilter',
        floatingFilter: false,
        cellStyle: {
          textAlign: 'center',
        }
      }
    ];

    this.defaultColDef = {
     
      sortable: true,
      filter: true,
      floatingFilter: true,
      suppressMenuHide: true
    };
    this.rowStyle = { background: 'black' };
    this.rowHeight = 85;
    this.getRowStyle = params => {

   

      let style = {
        background: '#faf9f6',
        'padding-bottom': '0.5rem'
      };

      (params.node.rowIndex % 2 === 0) ? style = { background: '#faf9f6', 'padding-bottom': '0.5rem' }
        : style = { background: '#f2f2f2', 'padding-bottom': '0.5rem' };

      return style;

    };

  }

  resetGrid() {

    //clear filters
    this.gridApi.setFilterModel(null);
    //notify grid to implement the changes
    this.gridApi.onFilterChanged();

  }

  onGridReady(params) {

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.innerWidth <= 768 ? this.gridColumnApi.autoSizeAllColumns() : this.gridApi.sizeColumnsToFit();

    Array.from(document.getElementsByClassName('ag-text-field-input')).forEach((obj) => {
      if (obj.attributes['disabled']) { // skip columns with disabled filter
        return;
      }
      obj.setAttribute('placeholder', 'Search by Title/Status/Location...');
    });
  }

  onFilterChanged(params) {
    sessionStorage.setItem('ag-grid-applications-filter', JSON.stringify(params.api.getFilterModel()));
  }

  onFirstDataRendered(params): void {
    
    const filterModel = sessionStorage.getItem('ag-grid-applications-filter');
    params.api.setFilterModel(JSON.parse(filterModel));
  }

  getJobs() {
    this.showSpinner = true;
    this.sharedService.getAllJobs().subscribe(
      (response: any) => {

        this.jobData = response.data;
        this.rowData = response.data;
        this.showSpinner = false;

      },
      (err: HttpErrorResponse) => {
        this.showSpinner = false;
        window.location.href = environment.ATS_ENV;
      }
    )
  }


  ngOnInit(): void {
    this.getJobs();
  }

}
