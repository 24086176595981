<app-header></app-header>
  <mat-sidenav-container fullscreen class=" sidenav-container " [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches"
      fixedTopGap="56" [opened]="screenWidth > 700" [mode]="(screenWidth > 700) ? 'side' : 'over'">
      <div class="d-flex justify-content-end">
        <button mat-icon-button (click)="snav.toggle()">
          <span class="material-icons">
            keyboard_arrow_left
          </span>
        </button>
      </div>
      <mat-divider></mat-divider>
      <app-sidebar></app-sidebar>
    </mat-sidenav>

    <mat-sidenav-content>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 ps-md-4 pe-md-4">
            <button class="ps-2 mat-toggle-right " mat-icon-button (click)="snav.toggle()">
              <span class="material-icons bg-hamburger">
                menu
              </span>
            </button>
            <div>
                <router-outlet></router-outlet>
            </div>
            
          </div>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
