<div class="d-flex justify-content-between">
    <ng-template #disabledLink>
        <a *ngIf="!params.data.jobId;" style="padding-left:12px;">
            {{params.data.firstName ? params.data.firstName : ''}}
            {{params.data.lastName ? params.data.lastName : ''}}
        
            <p class="mat-typography "> {{location}} </p>
        </a>
    </ng-template>
    <a style="padding-left:12px;" *ngIf="params.data.jobId; else disabledLink" routerLink="/viewapplicant/{{params.data.jobApplicantId}}">
        {{params.data.firstName ? params.data.firstName : ''}}
        {{params.data.lastName ? params.data.lastName : ''}}
        
        <p class="mat-typography "> {{location}} </p>
    </a>
    <button (click)="params.context.openInviteJobDialog(params.data)" class="bg-transparent border-0"><span class="ms-3 material-icons">library_add</span></button>
</div>
