import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ats-preview',
  templateUrl: './ats-preview.component.html',
  styleUrls: ['./ats-preview.component.scss']
})
export class AtsPreviewComponent implements OnInit {
  @Input() company: any;
  @Input() jobData : any;
  logo: any;

  constructor() { }

  ngOnInit(): void {
    
    this.logo = environment.FILE_URL + this.company.logoFileId;
  }

}
