<div class="chat-content w-100 review-content">
  <div class="container">
    <h2 class="mb-0 mt-2">Schedule an Interview</h2>
    <form [formGroup]="formdata" #formDirective="ngForm" (ngSubmit)="scheduleInterview(formdata.value, formDirective)">
      <div class="row">
        <div class="col-md-3">
          <label class="d-block mb-0">Date</label>
          <mat-form-field class="w-100" appearance="outline">
            <input [disabled]="disableForm" matInput placeholder="mm/dd/yyyy" formControlName="date" [matDatepicker]="picker">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-3 ">
          <label>Time</label>
          <mat-form-field class="w-100" floatLabel="always" appearance="outline">
            <input [disabled]="disableForm" matInput type="time" formControlName="time" placeholder="12: 00 AM" value="12: 00 AM">
          </mat-form-field>
        </div>
        <div class="col-md-3 ">
          <label>Duration</label>
          <mat-form-field class="w-100" appearance="outline">
            <mat-select formControlName="duration" value="60 Minutes">
              <mat-option value="30">
                30 Minutes
              </mat-option>
              <mat-option value="60">
                60 Minutes
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <label>Location</label>
          <mat-form-field class="w-100" floatLabel="always" appearance="outline">
            <input [disabled]="disableForm" matInput formControlName="location" placeholder="Location" value="">
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <label>Interviewer</label>
          <mat-form-field class="w-100" floatLabel="always" appearance="outline">
            <input [disabled]="disableForm" matInput formControlName="interviewer" placeholder="Joe Recruiter">
          </mat-form-field>
        </div>
        <div class="col-md-3 schedule-button">
         
          <button [disabled]="!formdata.valid " mat-raised-button color="primary" type="submit"
            class=" text-uppercase">Schedule</button>
        </div>
      </div>
    </form>
    <hr>
    <div class="row">
      <h2 class="mb-0 mt-2">Scheduled Interviews</h2>
      <div *ngFor="let data of interviewData.slice().reverse();">
        <div class="col d-flex align-items-center">
          <mat-icon class="me-1 material-icons">calendar_today</mat-icon>
          <p class="mb-0 me-2">{{data.interview_date_time | date:'short'}}</p>
          <mat-icon class="m-1  material-icons"> location_on</mat-icon>
          <p class="mb-0">{{data.interview_location}}</p>
        </div>

        <p>Interview with: {{ data.interviewer}} | Scheduled By: {{user.firstName}} {{user.lastName}}</p>
        <hr>
      </div>
    </div>
  </div>
</div>