<div class="container">
	<div class="row">
		<div class="col-md-12  candidate-header">
			<div class="heading ">
				<h2 class="mat-h1 mb-0 bg-white text-uppercase">{{isUpdateJob? 'Edit Job': 'Add Job'}}</h2>

			</div>
		</div>
		<mat-horizontal-stepper labelPosition="bottom" #stepper>

			<ng-template matStepperIcon="edit">
				<mat-icon>done</mat-icon>
			</ng-template>
			<mat-step [stepControl]="step1" errorMessage="Name is required.">
				<ng-template matStepLabel>
					<h2 class="step-header font-condensed">Company Profile</h2>
				</ng-template>
				<app-step-one [jobData]="jobData" [jobCompany]="jobCompany" *ngIf="jobCompany" [companies]="companies"></app-step-one>
				<div class="my-2">
					<button class="justify-content-center  text-uppercase" matStepperNext mat-raised-button
						color="primary">
						<span>Next</span>
						<small class="material-icons small-icons me-1">
							keyboard_arrow_right
						</small>
					</button>
				</div>
			</mat-step>

			<mat-step [completed]="fromDashboard ? true : step2?.valid" errorMessage="Name is required.">
				<ng-template matStepLabel>
					<h2 class="step-header font-condensed">Job Description</h2>
				</ng-template>
				<p class="d-none">{{jobData.title}}</p>
				<app-step-two [jobData]="jobData"></app-step-two>
				<div class="my-2">
					<button class="text-uppercase" matStepperPrevious mat-stroked-button color="accent">
						<small class="material-icons small-icons me-1">
							keyboard_arrow_left
						</small>
						<span>Back</span>
					</button>

					<button [disabled]="step2?.controls['title'].hasError('required')" class=" ms-1 text-uppercase"
						matStepperNext mat-raised-button color="primary">
						<span>Next</span>
						<small class="material-icons small-icons me-1">
							keyboard_arrow_right
						</small>
					</button>
				</div>
			</mat-step>

			<mat-step [completed]="fromDashboard ? true : step3?.valid" errorMessage="Name is required.">
				<ng-template matStepLabel>
					<h2 class="step-header font-condensed">Job Details</h2>
				</ng-template>

				<h1 class="jd-add mat-typography">Job Details</h1>
				<p class="mat-typography text-muted">Add specifics about this job.
				</p>
				<app-step-three  (selectedCategory)="updateCategory($event)" [jobData]="jobData"></app-step-three>

				<div class="my-2 ">
					<button class="text-uppercase" matStepperPrevious mat-stroked-button color="accent">
						<small class="material-icons small-icons me-1">
							keyboard_arrow_left
						</small>
						<span>Back</span>
					</button>
					<button class=" ms-1 text-uppercase" [disabled]="step3?.controls['type'].hasError('required') && step3?.controls['category'].hasError('required')"
						matStepperNext mat-raised-button color="primary">
						<span>Next</span>
						<small class="material-icons small-icons me-1">
							keyboard_arrow_right
						</small>
					</button>

				</div>
			</mat-step>

			<mat-step [completed]="fromDashboard ? true : step4?.valid" errorMessage="Name is required.">
				<ng-template matStepLabel>
					<h2 class="step-header font-condensed">Application Options</h2>
				</ng-template>
				<h1 class="jd-add font-condensed">Application Options</h1>
				<p class="mat-typography">Select how you would like to receive applications for this job.
				</p>
				<app-step-four [jobData]="jobData"></app-step-four>
				<div class="my-2">
					<button class="text-uppercase" matStepperPrevious mat-stroked-button color="accent">
						<small class="material-icons small-icons me-1">
							keyboard_arrow_left
						</small>
						<span>Back</span>
					</button>
					<button class=" ms-1 text-uppercase" [disabled]="" matStepperNext mat-raised-button color="primary">
						<span>Next</span>
						<small class="material-icons small-icons me-1">
							keyboard_arrow_right
						</small>
					</button>

				</div>
			</mat-step>

			<mat-step [completed]="fromDashboard ? true : screeningForm" errorMessage="Name is required.">
				<ng-template matStepLabel>
					<h2 class="step-header font-condensed">Screening Questions</h2>
				</ng-template>
				<div class="mb-3">
					<h3 class="mat-typography">
						Pre-Screening Questions
					</h3>
					<p class="mat-typography">
						Pre-Screening questions should be used to determine whether the applicant has the minimum
						qualifications
						required for the open position.
					</p>
					<p class="mat-typography">
						We recommend asking no more than 5 yes/no questions
					</p>
					<p class="mat-typography">For example does your position require</p>
					<li class="mat-typography">
						A certain level of education
					</li>
					<li class="mat-typography">
						A particular License
					</li>
					<li class="mat-typography">Authorization to work in a particular location</li>
				</div>
				<form [formGroup]="screeningForm">
					<div>
						<div *ngFor="let questions of questions.controls; let i=index">
							<div class="row " [formGroup]="questions">
								<div class="col-md-9">

									<mat-form-field class="w-100" appearance="outline">
										<mat-label>Question</mat-label>
										<input class="w-100" formControlName="question" name="{{question}}" matInput
											placeholder="Add question">
									</mat-form-field>
								</div>

								<div class="col-md-2">
									<mat-form-field appearance="outline">
										<mat-label>Type</mat-label>
										<mat-select formControlName="type">
											<mat-option></mat-option>
											<mat-option value="yes/no">Yes/No</mat-option>
										</mat-select>
									</mat-form-field>

								</div>
								<div class="col-md-1 " *ngIf="questions.removable!='' && i>0">
									<span role="button" (click)="removeQuestion(i)"
										class=" text-danger mt-3 material-icons">
										cancel
									</span>
								</div>
							</div>

						</div>


					</div>
				</form>
				<div class="col-md-4 mb-2">
					<button class="text-uppercase" (click)="onChangeScreening()" mat-stroked-button color="accent">
						<small class="material-icons small-icons me-1">
							add_circle
						</small>
						<span>Add Another Question</span>
					</button>
				</div>
				<div class="mb-2 mt-3">
					<button class="me-1 text-uppercase" matStepperPrevious mat-stroked-button color="accent">
						<small class="material-icons small-icons me-1">
							keyboard_arrow_left
						</small>
						<span>Back</span>
					</button>
					<button class=" ms-1 text-uppercase" (click)="addToJobData()" matStepperNext mat-raised-button
						color="primary">
						<span>Next</span>
						<small class="material-icons small-icons me-1">
							keyboard_arrow_right
						</small>
					</button>

				</div>
			</mat-step>


			<mat-step [completed]="fromDashboard ? true : step4?.valid">
				<ng-template matStepLabel>
					<h2 class="step-header font-condensed">Preview</h2>
				</ng-template>
				<h1 class="jd-add">Preview</h1>
				<app-ats-preview [jobData]="jobData" [company]="jobCompany" *ngIf="jobCompany && jobData"> </app-ats-preview>

				<div class="my-2">
					<button class="text-uppercase" matStepperPrevious mat-stroked-button color="accent">
						<small class="material-icons small-icons me-1">
							keyboard_arrow_left
						</small>
						<span>Back</span>
					</button>
					<button (click)="onSubmit(true)" class=" ms-1 text-uppercase" mat-raised-button color="primary">
						<span>{{jobId ? 'Update Job and Save for Later' : 'Create Job and Save for Later'}}</span>
						<small class="material-icons small-icons me-1">
							keyboard_arrow_right
						</small>
					</button>
					<button (click)="onSubmit(false)" class=" ms-1 text-uppercase" matStepperNext mat-raised-button color="primary">
						<span>{{jobId ? 'Update Job' : 'Create Job'}}</span>
						<small class="material-icons small-icons me-1">
							keyboard_arrow_right
						</small>
					</button>
				</div>
			</mat-step>

			<mat-step>
				<ng-template matStepLabel>
					<h2 class="step-header font-condensed">Marketplace</h2>
				</ng-template>
				<h1 class="jd-add">Marketplace Job Options</h1>
				<app-job-marketplace  [jobData]="jobData" (submitEvent)="onSubmit()"></app-job-marketplace>
			</mat-step>

		</mat-horizontal-stepper>
	</div>
</div>