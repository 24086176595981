<!-- <ul class="breadcrumb p-0 m-0 bg-transparent">
  <li *ngFor="let breadcrumb of breadcrumbs" class="breadcrumb-item">
    <a routerLink="{{breadcrumb.url}}">
      {{ breadcrumb.label }}
    </a>
  </li>
</ul> -->

<ul>
  <li *ngFor="let breadcrumb of (breadcrumbs$ | async)">   
    <a class="text-capitalize" routerLink="{{breadcrumb.label}}">
      {{ breadcrumb.label }}
    </a>
  </li>
</ul>