<div class="row ps-md-2">
	<div class="col-md-12">
		<div class="heading">
			<h2 class="mat-h1 bg-white text-uppercase ms-5">Dashboard</h2>
		</div>
		<div class="d-flex">
			<mat-radio-group (change)="changeActiveOrInactive($event)" aria-label="Select an option">
				<mat-radio-button [checked]="checkedAllJobs" value="inactive" class="m-2">Include All Jobs</mat-radio-button>
				<mat-radio-button value="active" class="m-2">Active Jobs Only</mat-radio-button>
			</mat-radio-group>
		</div>

		<ul class="steps steps-5 mb-4">
			<li>
				<a routerLink="/candidates/dashboard-leads/" title="" class="text-center">
					<span class="steps-title">{{topBarData?.newLeads}}</span>
					<span>New
						<span class="d-block">
							Leads
						</span>
					</span>
				</a>
			</li>
			<li>
				<a routerLink="/candidates/dashboard-new/{{showActive?'active':''}}" title="" class="text-center">
					<span *ngIf="showActive" class="steps-title">{{topBarData?.newApplicants}}</span>
					<span *ngIf="!showActive" class="steps-title">{{topBarData?.newApplicantsAll}}</span>

					<span>New
						<span class="d-block">
							Applicants
						</span>
					</span>
				</a>
			</li>
			<li class="current">
				<a routerLink="/candidates/dashboard-review/{{showActive?'active':''}}" title="" class="text-center text-white">
					<span *ngIf="showActive" class="steps-title">{{topBarData?.applicantInReview}}</span>
					<span *ngIf="!showActive" class="steps-title">{{topBarData?.applicantInReviewAll}}</span>
					<span>Applicant
						<span class="d-block">
							in Review
						</span>
					</span>
				</a>
			</li>
			<li>
				<a routerLink="/candidates/dashboard-interview/{{showActive?'active':''}}" title="" class="text-center text-white">
					<span *ngIf="showActive" class="steps-title">{{topBarData?.interviewsScheduled}}</span>
					<span *ngIf="!showActive" class="steps-title">{{topBarData?.interviewsScheduledAll}}</span>
					<span>Interviews
						<span class="d-block">Scheduled</span>
					</span>
				</a>
			</li>
			<li>
				<a routerLink="/candidates/dashboard-offer/{{showActive?'active':''}}" title="" class="text-center text-white">
					<span  *ngIf="showActive" class="steps-title">{{topBarData?.offersMade}}</span>
					<span *ngIf="!showActive" class="steps-title">{{topBarData?.offersMadeAll}}</span>
					<span>Offers
						<span class="d-block">
							Made
						</span>
					</span>
				</a>
			</li>
		</ul>
	</div>

	<div class="col-md-4 mb-4">
		<mat-card class="p-0 dashboard-card shadow-none h-100">
			<mat-card-header class="p-1 bg-dashboard-table">
				<mat-card-title class="text-white fw-light pt-2">To Do</mat-card-title>

			</mat-card-header>
			<mat-card-content class="dashboard-card-content p-3">
				<h2 class="mb-0">Review Applications</h2>
				<ul class="list-group list-group-flush apps-lists">
					<div class="review-apps">
						<div *ngFor="let apps of applicationsToReview">

							<li class="list-group-item border-0 px-0" *ngIf="apps.newApplicants>0 ">
								<div class="d-flex">
									<small class="me-2 bg-primary rounded-circle text-white text-center">
										{{apps.newApplicants}}
									</small>
									<a class="dashboard-link" routerLink="/singlejob/{{apps.id}}/">
										{{apps.title}}
									</a>

									<mat-icon color="primary">
										arrow_right
									</mat-icon>
								</div>
							</li>				
						</div>
					</div>
					<hr />
				</ul>
				<h2 class="mb-0">Read New Messages</h2>
				<ul class="list-group list-group-flush apps-lists">
					<div class="review-apps">
						<div *ngFor="let new of unreadMessagesCount">
							<li class="list-group-item border-0 px-0" *ngIf="new?.counter>0">
								<div class="d-flex">
									<small class="me-2 bg-primary rounded-circle text-white text-center">
										
										{{new?.counter}}
									</small>
									<a class="dashboard-link" routerLink="/viewapplicant/{{new.jobApplicationId}}/">
										{{new?.candidateName}}
									</a>
									<mat-icon color="primary">
										arrow_right
									</mat-icon>
								</div>
							</li>
						</div>
					</div>
				</ul>
			</mat-card-content>
		</mat-card>
	</div>
	<div class="col-md-4 mb-4">
		<mat-card class="p-0 dashboard-card shadow-none h-100">
			<mat-card-header class="p-1 bg-dashboard-table">
				<mat-card-title class="text-white pt-2">Recommendations</mat-card-title>

			</mat-card-header>
			<mat-card-content class="dashboard-card-content p-3">
				 <ul class="list-group list-group-flush apps-lists">
					<div class="review-apps">
						<li class="list-group-item border-0 px-0" *ngFor="let jobs of distributions">
							<div class="d-flex align-items-start">
								<small class="me-2 bg-primary rounded-circle text-white text-center">
									<span class="material-icons">
										share
									</span>
								</small>
								<span>
									<p class="dashboard-link">
										<a (click)="goToMarketplace(jobs.jobId)">
											{{jobs.jobTitle}}
										</a>
									</p>
									<small class="d-block">
										{{jobs?.jobViewsCount}} Views | {{jobs?.jobApplicationCount}} Applications

									</small>

								</span>
								<mat-icon color="primary">
									arrow_right
								</mat-icon>
							</div>
						</li>
					</div>
				</ul>
				<hr />
				<h2 class="mb-0">Enhance Jobs</h2>
				<ul class="list-group list-group-flush apps-lists">
					<div class="review-apps">
						<li class="list-group-item border-0 px-0" *ngFor="let jobs of distributions">
							<div class="d-flex align-items-start">
								<small class="me-2 bg-primary rounded-circle text-white text-center">
									<span class="material-icons">
										share
									</span>
								</small>
								<span>
									<p class="dashboard-link">
										<a (click)="goToMarketplace(jobs.jobId)">									
											{{jobs.jobTitle}}
										</a>
									</p>
									<small class="d-block">
										{{jobs.jobViewsCount}} Views | {{jobs.jobApplicationCount}} Applications
						
									</small>
						
								</span>
								<mat-icon color="primary">
									arrow_right
								</mat-icon>
							</div>
						</li>
					</div>
				</ul> 
			</mat-card-content>
		</mat-card>
	</div>
	<div class="col-md-4 mb-4">
		<mat-card class="p-0 dashboard-card shadow-none h-100">
			<mat-card-header class="p-1 bg-dashboard-table">
				<mat-card-title class="text-white pt-2">Job Statistics</mat-card-title>

			</mat-card-header>
			<mat-card-content class="dashboard-card-content p-3">


				<div class="row ">
					<div class="col-lg-12 ">
						<h2 class="font-condensed">Month To Date</h2>
						<div class="d-flex mb-2">
							<p class="mb-0 mat-typography">Job Views</p>
							<span
								class="mat-typography float-end ms-auto badge rounded-pill bg-secondary">{{statistics?.viewsMTD}}</span>
						</div>

						<div class="d-flex mb-2">
							<p class=" mat-typography">Job Applications</p>
							<span
								class="mat-typography float-end ms-auto badge rounded-pill bg-secondary">{{statistics?.applicationsMTD}}</span>
						</div>

						<div class="d-flex ">
							<p class=" mat-typography">Total Jobs</p>
							<span
								class="mat-typography float-end ms-auto badge rounded-pill bg-secondary">{{statistics?.totalJobsMTD}}</span>
						</div>
						<!-- <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart"
							[plotOptions]="chartOptions.plotOptions" [labels]="chartOptions.labels"
							[legend]="chartOptions.legend" [colors]="chartOptions.colors"
							[responsive]="chartOptions.responsive" [tooltip]="chartOptions.tooltip">
						</apx-chart> -->
						<hr>
					</div>

					<div class=" col-lg-12 ">
						<h2 class=" font-condensed">Year To Date</h2>

						<div class="d-flex mb-2">
							<p class=" mat-typography">Job Views</p>
							<span
								class="mat-typography float-end ms-auto badge rounded-pill bg-secondary">{{statistics?.viewsYTD}}</span>

						</div>

						<div class="d-flex mb-2">
							<p class=" mat-typography">Job Applications</p>
							<span
								class="mat-typography float-end ms-auto badge rounded-pill bg-secondary">{{statistics?.applicationsYTD}}</span>
						</div>

						<div class="d-flex">
							<p class="mb-0 mat-typography">Total Jobs</p>
							<span
								class="mat-typography float-end ms-auto badge rounded-pill bg-secondary">{{statistics?.totalJobsYTD}}</span>
						</div>
						<!-- <apx-chart [series]="ytdOptions.series" [chart]="ytdOptions.chart"
							[plotOptions]="ytdOptions.plotOptions" [labels]="ytdOptions.labels"
							[legend]="ytdOptions.legend" [colors]="ytdOptions.colors"
							[responsive]="ytdOptions.responsive" [tooltip]="chartOptions.tooltip">
						</apx-chart> -->

						<hr>
					</div>

					<div class="  col-lg-12 ">
						<div class="d-flex ">
							<p class="mb-0 mat-typography">Currently Active Jobs</p>
							<span
								class="mat-typography float-end ms-auto badge rounded-pill bg-secondary">{{statistics?.activeJobs}}</span>
						</div>

					</div>
					<p class="mb-0">Available Job Slots: {{unlimitedJobSlots ? 'Unlimited': jobSlots}}</p>
					<p class="mb-0">Package Expires: {{packages?packages:'' | date: 'short'}}</p>
					<p class="mb-0">Package Type: {{packageType}}</p>
				</div>
			</mat-card-content>
		</mat-card>
	</div>


</div>