import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApplicantConversation } from '../model/conversations';
import { Job } from '../model/jobs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(private http: HttpClient) { }

  loginAts(): Observable<any> {

    return this.http.get('/api/ats/login');
  }


  getAuthDetails(token) {

    localStorage.setItem('token', token);
    return this.http.get('/api/ats/login');
  }


  getJobs(): Observable<any> {
    return this.http.get('assets/json/jobs.json');
  }

  getReviews(jobApplicationId): Observable<any> {

    return this.http.get('/api/ats/getReviews', {
      params: {
        jobApplicationId: jobApplicationId,
      }
    });
  }

  getNotes(jobApplicationId): Observable<any> {

    return this.http.get('/api/ats/getNotes', {
      params: {
        jobApplicationId: jobApplicationId,
      }
    });
  }

  postReviews(data): Observable<Object> {

    const body = data;
    return this.http.post<Object>('/api/ats/addReviews', body,);
  }

  addNotes(data): Observable<any> {

    const body = data;
    return this.http.post<Object>('/api/ats/addNotes', body,);
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

  getAllJobs(): Observable<any> {

    return this.http.get('/api/ats/getAllJobs');
  }

  getJobDetails(): Observable<any> {
    return this.http.get('assets/json/job-applications.json');
  }

  getSingleJobApplicants(jobId): Observable<any> {
    return this.http.get('/api/ats/getApplicantsByJob', {
      params: {
        jobId: jobId,
      }
    });

  }

  getSingleJob(jobId): Observable<any> {

    return this.http.get('/api/ats/getSingleJobById', {

      params: {
        jobId: jobId,
      }
    });
  }

  getMenuLinks(): Observable<any> {
    return this.http.get('assets/json/menu.json');
  }

  getAppsOverview(): Observable<any> {
    return this.http.get('assets/json/applicant-overview.json');

  }
  getApplicantDetailsData(): Observable<any> {
    return this.http.get('assets/json/applicant-view.json');
  }

  getAppsJobtype(): Observable<any> {
    return this.http.get('assets/json/job-types.json');
  }

  getApplicantResume(resumeId): Observable<any> {

    return this.http.get('/api/ats/getApplicantResume', {

      params: {
        resumeId: resumeId,
      }
    });
  }

  getOtherFiles(resumeId): Observable<any> {

    return this.http.get('/api/ats/getOtherFiles', {

      params: {
        resumeId: resumeId,
      }
    });
  }

  getCoverLetter(applicationId) {

    return this.http.get('/api/ats/getApplicantCoverLetter', {

      params: {
        applicationId: applicationId,
      }
    });
  }

  getApplicantOtherJobs(userId, jobId) {

    return this.http.get('/api/ats/getApplicantOtherJobs', {

      params: {
        candidateId: userId,
        jobId: jobId
      }
    });
  }

  getApplicant(id): Observable<any> {

    return this.http.get('/api/ats/getApplicant', {

      params: {
        id: id,
      }
    });
  }
  getApplicantListForJob(id): Observable<any> {

    return this.http.get('/api/ats/getApplicantListForJob', {

      params: {
        id: id,
      }
    });
  }

  getAppliedJob(id): Observable<any> {

    return this.http.get('/api/ats/getAppliedJob', {
      params: { id: id }
    });
  }

  updateStage(data) {

    const body = data;
    return this.http.put<Object>('/api/ats/updateJobAppStatus', body);
  }

  sendEmailToApplicant(data) {

    const body = data;
    return this.http.post<Object>('/api/email/send/twilio/v2', body);
  }

  getConversationForApplicantWithJobAppId(jobApplicationId: string | number): Observable<ApplicantConversation> {

    return this.http.get<ApplicantConversation>(`/api/jobapplication/conversation?jobAppId=${jobApplicationId}`);
  }

  getCandidatePipelineData() {

    return this.http.get('/api/ats/getCandididatePipelineData');
  }

  getATSPackages() {

    return this.http.get('/api/ats/getATSPackages');
  }


  getCandidateTags(candidateId, orgId) {


    return this.http.get('/api/ats/getTags', {
      params: {
        candidateId: candidateId,
        orgId: orgId
      }
    });
  }

  updateTags(tags, orgId, candidateId) {

    const body = {
      candidateId: candidateId,
      organizationId: orgId,
      tags: tags,
      tenantId: orgId
    };
    return this.http.put<Object>('/api/ats/updateTags', body);
  }

  scheduleInterview(data) {

    const body = data;
    return this.http.post('/api/email/send/twilio/invitation', body);
  }


  addInterview(data) {

    const body = data;
    return this.http.post('/api/ats/postInterviews', body);
  }

  getInterviews(jobApplicationId) {

    return this.http.get('/api/ats/getInterviews', {
      params: {
        jobApplicationId: jobApplicationId
      }
    });
  }


  getOffers(jobApplicationId) {

    return this.http.get('/api/ats/getOffers', {
      params: {
        jobApplicationId: jobApplicationId
      }
    });
  }

  addOffer(data) {
    const body = data;
    return this.http.post('/api/ats/postOffers', body);
  }

  updateOfferAccepted(data) {

    const body = data;
    return this.http.put('/api/ats/updateOfferAccepted', body);
  }

  createHired(data) {

    const body = data;
    return this.http.post('/api/ats/createHiring', body);
  }

  updateHiring(data) {

    const body = data;
    return this.http.put('/api/ats/updateHiring', body);
  }

  getHiring(jobApplicationId) {

    return this.http.get('/api/ats/getHiringDates', {

      params: {
        jobApplicationId: jobApplicationId
      }
    });
  }

  //Dashboard
  getStatisticsData(companyId) {

    return this.http.get('/api/ats/getJobStatistics', {
      params: {
        companyId: companyId
      }
    });
  }

  getDashboardTopBar(companyId) {

    return this.http.get('/api/ats/getApplicantsStatus', {
      params: {
        companyId: companyId
      }
    });
  }

  getPackageDetails() {

    return this.http.get('/api/ats/getPackageDetails');
  }

  getUnreadMessages() {

    return this.http.get('/api/jobapplication/conversation/unread/status');
  }

  getRecommendations() {
    return this.http.get('/api/ats/getDistributionDetails');
  }

  //Job
  loadJobTemplate(val, requestData) {

    return this.http.get('/api/ats/getTemplate', {

      params: {
        title: val,
        details: encodeURIComponent(JSON.stringify(requestData))
      }
    });
  }

  findJob(jobId) {

    return this.http.get('/api/ats/findJob', {
      params: { jobId: jobId }
    });
  }

  updateJob(jobData: Job) {
    const body = {
      job: jobData,
      enhancements: [],
    }
    return this.http.post('/api/ats/updateJob', body);
  }

  createJob(jobData?: Job) {
    const body = {
      job: jobData,
      enhancements: []
    };
    return this.http.post('/api/ats/createJob', body);
  }

  getCompanies(): Observable<any> {

    return this.http.get('/api/ats/getOrgCompanies');
  }

  getLocations(params) {

    return this.http.get('/api/ats/location/autocomplete', {
      params: { request: params }
    });
  }

  getLocationDataById(locationId) {

    return this.http.get('/api/ats/location/getLocationDataById', {
      params: { locationId: locationId }
    });
  }

  updateJobActions(jobId, action) {

    return this.http.post('/api/ats/updateJobActions', '', { params: { jobId: jobId, action: action } });
  }

  inviteToJob(data) {

    const body = data;
    return this.http.post('/api/ats/inviteToJob', body);
  }


  getAllPackages() {
    return this.http.get('/api/ats/getAllPackages');
  }

  submitPayment(data) {
    const body = data;
    return this.http.post('/api/ats/submitPayment', body);
  }

  logout() {
    const headers = { 'token': localStorage.getItem('token') };
    return this.http.post('/api/ats/logout','',{ headers });
  }

}
