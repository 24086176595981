<form [formGroup]="stepThreeForm">
    <div class="row">

        <div class="col-md-12">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Categories</mat-label>
                <mat-select formControlName="category" (selectionChange)="updateCategory($event)" name="category" multiple required>
                    <mat-select-trigger>
                        <mat-chip-list>
                            <mat-chip [selected]="value" *ngFor="let value of stepThreeForm.get('category').value"
                                [removable]="true" (removed)="onCategoryRemoved(value)">
                                {{ value }}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                        </mat-chip-list>
                    </mat-select-trigger>
                    <mat-option *ngFor="let categories of categories | keyvalue: originalOrder"
                        [value]="categories.key">
                        {{categories.value}}</mat-option>
                </mat-select>
                <mat-error *ngIf="stepThreeForm.get('category').hasError('required')">
                    Please enter atleast One Category
                </mat-error>
            </mat-form-field>
        </div>

        <div class="col-md-6">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Location</mat-label>
                <input type="text" placeholder="Pick one" aria-label="Number" [value]="locationField?locationField:''"
                    matInput (keyup)="getLocations($event)" [matAutocomplete]="auto" name="option" required>
                <mat-autocomplete (optionSelected)='onLocationSelect($event)' [displayWith]="getOptionText"
                    #auto="matAutocomplete">
                    <mat-option *ngFor="let option of locationData" [value]="option">
                        {{option.text}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Job Code</mat-label>
                <input matInput placeholder="Job Code" name="code" formControlName="code">
            </mat-form-field>

            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Type</mat-label>
                <mat-select name="type" formControlName="type" required>
                    <mat-option>None</mat-option>
                    <mat-option *ngFor="let type of types | keyvalue:originalOrder" [value]="type.key">
                        {{type.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Career Level</mat-label>
                <mat-select name="careerLevel" formControlName="careerLevel">
                    <mat-option>None</mat-option>
                    <mat-option *ngFor="let careerLevel of careerLevel  | keyvalue:originalOrder"
                        [value]="careerLevel.key">
                        {{careerLevel.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Pay Type</mat-label>
                <mat-select name="payType" formControlName="payType">
                    <mat-option [value]="payType.key" *ngFor="let payType of payType | keyvalue: originalOrder">
                        {{payType.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </div>

        <div class="col-md-6">
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Remote Job Details</mat-label>
                        <mat-select name="remote" formControlName="locationRemote">
                            <mat-option>None</mat-option>
                            <mat-option value="anywhere">
                                Remote nationwide
                            </mat-option>
                            <mat-option value="limited">
                                Remote statewide
                            </mat-option>
                            <mat-option value="optional">
                                Remote optional
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Travel Requirements</mat-label>
                        <mat-select name="travel" formControlName="travel">
                            <mat-option>None</mat-option>
                            <mat-option *ngFor="let Travel of travel | keyvalue: originalOrder" [value]="Travel.key">
                                {{Travel.value}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field class="w-100" floatLabel="always" appearance="outline">
                        <mat-label>Start Date</mat-label>
                        <input matInput placeholder="mm/dd/yyyy" name="startsAt" formControlName="startsAt"
                            [matDatepicker]="startsAt">
                        <mat-datepicker-toggle matSuffix [for]="startsAt"></mat-datepicker-toggle>
                        <mat-datepicker #startsAt></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-md-6 ">
                    <mat-form-field class="w-100" floatLabel="always" appearance="outline">
                        <mat-label>End Date</mat-label>
                        <input matInput placeholder="mm/dd/yyyy" name="endsAt" formControlName="endsAt"
                            [matDatepicker]="picker2">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Term</mat-label>
                        <mat-select name="status" formControlName="status">
                            <mat-option>None</mat-option>
                            <mat-option *ngFor="let status of terms | keyvalue: originalOrder" [value]="status.key">
                                {{status.value}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-12">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Minumum Education</mat-label>
                        <mat-select name="education" formControlName="education">
                            <mat-option *ngFor="let education of education | keyvalue: originalOrder"
                                [value]="education.key">
                                {{education.value}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Pay Range</mat-label>
                        <mat-select name="payRangeLow" formControlName="payRangeLow">
                            <mat-option></mat-option>
                            <mat-option *ngFor="let payRangeLow of payRange" [value]="payRangeLow">
                                {{payRangeLow | currency:'USD':'symbol'}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-6 ">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>To</mat-label>
                        <mat-select name="payRangeHigh" formControlName="payRangeHigh">
                            <mat-option>None</mat-option>
                            <mat-option *ngFor="let payRangeHigh of payRange" [value]="payRangeHigh">
                                {{payRangeHigh | currency:'USD':'symbol'}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
</form>