<h1 class="jd-add font-condensed">Company Profile</h1>
<p class="mat-typography text-muted">Enter your Company information as you would like it to be displayed
    on your
    posting.
    Fields may be hidden from the posting, and hiding Company Name will create an anonymous posting.
</p>
<div class="row">
    <div class="col-12" >
        <div class="col-md-4">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Associated Company</mat-label>
                <mat-select [value]="jobCompany?.id" (selectionChange)="updateCompany($event)">
                    <mat-option *ngFor="let company of companies" [value]="company.id">{{company.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <hr>
        <div class="row">
            <div class="col-md-6">
                <form [formGroup]="stepOneForm">               
                    <div class="col-md-9">
                        <div class="row" formGroupName="altCompany">
                            <div class="col-md-9">
                                <mat-form-field appearance="outline">
                                    <mat-label>Company Name</mat-label>
                                    <input class="w-40" formControlName="value" matInput name="companyName"
                                        placeholder="Company Name">
                                </mat-form-field>
                            </div>
                            <div class="col-md-3 mt-3">
                                <mat-checkbox  (change)="stepOneForm.get('altCompany.visible').setValue(!$event.checked)">Hide</mat-checkbox>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-md-9">
                        <div class="row" formGroupName="altAddress1">
                            <div class="col-md-9">
                                <mat-form-field appearance="outline">
                                    <mat-label>Address</mat-label>
                                    <input class="w-40" formControlName="value" matInput
                                        placeholder="Address">
                                </mat-form-field>
                            </div>
                            <div class="col-md-3 mt-3">
                                <mat-checkbox  (change)="stepOneForm.get('altAddress1.visible').setValue(!$event.checked)">Hide</mat-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div class="row" formGroupName="altCity">
                            <div class="col-md-9">
                                <mat-form-field appearance="outline">
                                    <mat-label>City</mat-label>
                                    <input class="w-40" formControlName="value" matInput placeholder="City">
                                </mat-form-field>
                            </div>
                            <div class="col-md-3 mt-3">
                                <mat-checkbox  (change)="stepOneForm.get('altCity.visible').setValue(!$event.checked)">Hide</mat-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div class="row" formGroupName="altState">
                            <div class="col-md-9">
                                <mat-form-field appearance="outline">
                                    <mat-label>State</mat-label>
                                    <input class="w-40" formControlName="value" matInput placeholder="State">
                                </mat-form-field>
                            </div>
                            <div class="col-md-3 mt-3">
                                <mat-checkbox  (change)="stepOneForm.get('altState.visible').setValue(!$event.checked)">Hide</mat-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div class="row" formGroupName="altZip">
                            <div class="col-md-9">
                                <mat-form-field appearance="outline">
                                    <mat-label>Zip Code</mat-label>
                                    <input class="w-40" formControlName="value" matInput placeholder="Zip Code">
                                </mat-form-field>
                            </div>
                            <div class="col-md-3 mt-3">
                                <mat-checkbox  (change)="stepOneForm.get('altZip.visible').setValue(!$event.checked)">Hide</mat-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div class="row" formGroupName="altName">
                            <div class="col-md-9">
                                <mat-form-field appearance="outline">
                                    <mat-label>Contact Name</mat-label>
                                    <input class="w-40" formControlName="value" matInput
                                        placeholder="Contact Name">
                                </mat-form-field>
                            </div>
                            <div class="col-md-3 mt-3">
                                <mat-checkbox  (change)="stepOneForm.get('altName.visible').setValue(!$event.checked)">Hide</mat-checkbox>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-9">
                        <div class="row" formGroupName="altEmail">
                            <div class="col-md-9">
                                <mat-form-field appearance="outline">
                                    <mat-label>Email</mat-label>
                                    <input class="w-40" formControlName="value" matInput placeholder="Phone">
                                </mat-form-field>
                            </div>
                            <div class="col-md-3 mt-3">
                                <mat-checkbox  (change)="stepOneForm.get('altEmail.visible').setValue(!$event.checked)">Hide</mat-checkbox>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-9">
                        <div class="row" formGroupName="altPhone">
                            <div class="col-md-9">
                                <mat-form-field appearance="outline">
                                    <mat-label>Phone</mat-label>
                                    <input class="w-40" formControlName="value" matInput placeholder="Phone">
                                </mat-form-field>
                            </div>
                            <div class="col-md-3 mt-3">
                                <mat-checkbox  (change)="stepOneForm.get('altPhone.visible').setValue(!$event.checked)">Hide</mat-checkbox>
                            </div>
                        </div>
                    </div>
                </form>
            </div>


            <div class="col-md-4">
                <img [src]="logoURL">

            </div>
        </div>
    </div>

</div>