import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Applicant } from 'src/app/model/Applicant';
import { EmployerUser } from 'src/app/model/EmployerUser';
import { Interview } from 'src/app/model/Interview';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-applicant-interview',
  templateUrl: './applicant-interview.component.html',
  styleUrls: ['./applicant-interview.component.scss']
})
export class ApplicantInterviewComponent implements OnInit, OnChanges {
  messages: any;
  @Output() interviewScheduled = new EventEmitter<string>();
  @Input() user: any;
  @Input() job: any;
  @Input() seekerData: Applicant;
  @Input() jobApplicationId;
  @Input() appStage: any;
  interviewData = [];
  @Output() interviewMailSent = new EventEmitter<string>();

  formdata: FormGroup;
  disableForm = false;

  constructor(public sharedService: SharedService, private formBuilder: FormBuilder) { }

  scheduleInterview(text, formDirective: FormGroupDirective) {

    formDirective.resetForm();

    this.formdata.reset();


    text.date.setHours(text.time.split(':')[0]);
    text.date.setMinutes(text.time.split(':')[1])
    const fromTime = text.date.toISOString();
    const toTime = new Date(text.date.getTime() + text.duration*60000).toISOString();

    const schedule: Interview = {
      details: {
        title: "Interview for" + " " + this.job.title,
        description: "An interview has been scheduled for the job you have applied to."
      },
      organizer: {
        name: this.user.firstName + " " + this.user.lastName,
        organizationId: this.user.organizationId,
        jobApplicationId: this.jobApplicationId
      },
      attender: {
        name: this.seekerData.firstName,
        email: this.seekerData.email
      },
      schedule: {
        from: fromTime,
        to: toTime
      },
      token: localStorage.getItem("token")
    }
    this.interviewScheduled.emit('interviewed');

    this.sharedService.scheduleInterview(schedule).subscribe(
      (response: any) => {
        //need to handle response - raghu
        console.log(response);
      }
    );


    const data = {
      jobApplicationId: this.jobApplicationId,
      scheduled_by: this.user.userId,
      interview_date_time: fromTime,
      interview_duration: text.duration,
      interview_location: text.location,
      interview_type: "Phone",
      interviewer: text.interviewer,
      scheduled_on: new Date().toISOString()
    };

    this.sharedService.addInterview(data).subscribe(
      (response: any) => {

        this.interviewData.push(response.data);
      }
    );


  }

  getInterviews() {
    this.sharedService.getInterviews(this.jobApplicationId).subscribe(
      (response: any) => {

        this.interviewData = response.data;
      }
    );
  }

  ngOnInit(): void {
    this.formdata = this.formBuilder.group({
      date: new FormControl('', Validators.required),
      time: new FormControl('', Validators.required),
      duration: new FormControl('', Validators.required),
      location: new FormControl('', Validators.required),
      interviewer: new FormControl('', Validators.required)
    });
    if (this.appStage === 'Hired' || this.appStage==='lead') {
      this.disableForm = true;
      this.formdata.disable();
    }

  }

  loadInterviews() {
    this.getInterviews();
  }

  ngOnChanges() {
  }

}
