import { Directive } from '@angular/core';

@Directive({
  selector: '[appOnCreate]'
})
export class OnCreateDirective {

  constructor() { }

}
