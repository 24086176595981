import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewApplicant } from 'src/app/model/ViewApplicant';
import { environment } from 'src/environments/environment';
import { KeyValue, Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { EmailRequest } from 'src/app/model/email-request';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ViewApplicantConstants } from 'src/app/components/common/constants/ViewApplicantConstants';
import { EmailTemplateRequest } from 'src/app/model/EmailTemplateRequest';
import { InviteJobDialogComponent } from 'src/app/components/common/invite-job-dialog/invite-job-dialog.component';

@Component({
  selector: 'app-applicant-view',
  templateUrl: './applicant-view.component.html',
  styleUrls: ['./applicant-view.component.scss']
})

export class ApplicantViewComponent implements OnInit, OnDestroy {
  applicantsoverview: any;
  jobtype: any;
  apps: any;
  applicationdetails: any;
  username: any;
  selected: any = 1;
  posts = [];
  radioSelected: string;
  rbs: any;
  selectedRadioValue: string;
  ratings = [];
  todayDate: Date = new Date();
  applicationId: any;
  applicant: any;
  status: ViewApplicant;
  category: ViewApplicant;
  jobType: string;
  education: any;


  @ViewChild('email') email;


  careerLevel: string;
  applicantList: [];
  applicantListIds = [];
  job: any;
  resumeUrl: any;
  applicationStage: any;
  candidateWithdrew: boolean;
  rejected = false;
  counter = 0;
  disableButton: boolean = false;
  disablePrevButton = false;
  disableNextButton = false;
  applicantStage: any;
  public DISPLAY_STAGE = ViewApplicant.DISPLAY_STAGE;
  public DISPLAY_VALUE = ViewApplicant.DISPLAY_VALUE;
  public STAGES = STAGES;
  payType: any;
  travel: { key: string; value: string; };
  coverLetter: any;
  otherJobs: any;
  disableTabs = false;
  finalStatus: string;
  hired: boolean;
  reviews: any;
  jobApplicationId: any;
  userData: any;
  other_file: string;
  isWithdrawn: boolean;
  jobLocation: any;
  disableOtherFilesTab: boolean;
  template: any;
  showEmailTemplate = false;
  jobList: any;
  makeOffer = false;
  showTags = false;
  rating = 3.5;
  advance = 'Advance';
  notificationEmail: string;
  @ViewChild('makeOfferDialog') rejectTemplate: TemplateRef<any>;
  @ViewChild('hiredDialog') hiredTemplate: TemplateRef<any>;
  private unsubscribe = new Subject();
  selectedIndex: number;
  rejectEmail: any;
  fileType: string;
  updateTabIndex: string | number;
  acceptedDate: any;
  tags = [];
  resumeId: any;
  noResume = false;
  tenantId: any;
  formdata: FormGroup;
  @ViewChild('applicantOffersComponent') applicantOffersComponent;
  @ViewChild('reviewsComponent') reviewsComponent;
  @ViewChild('interviewsComponent') interviewsComponent;
  @ViewChild('notesComponent') notesComponent;
  @ViewChild('hiringComponent') hiringComponent;
  invitationSent = false;

  constructor(private location: Location, public sharedService: SharedService,
    private route: Router,
    private router: ActivatedRoute,
    public dialog: MatDialog,
    private infoSnackBar: MatSnackBar,
    private formBuilder: FormBuilder
    ) {

  }


  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [

        'subscript',
        'superscript',
        'justifyFull',
        'indent',
        'outdent',
        'heading',
      ],
      [

        'backgroundColor',
        'customClasses',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',

        'toggleEditorMode']
    ]
  };

  toggleTagsEditMode() {
    this.showTags = !this.showTags;
    this.removable = !this.removable;
  }

  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }

  async changeApplicant(val) {
    
    await this.getApplicant(val);
    this.selectedIndex = 0;
    if (val) {
      this.route.navigate(['viewapplicant', val]);
    }

    this.isFirstOrLast(val);
  }

  isFirstOrLast(val) {
    const indexOfVal = this.applicantListIds.indexOf(parseInt(val));

    if (indexOfVal == 0) {
      this.disablePrevButton = true;
      this.disableNextButton = false;
    }
    else if (indexOfVal == this.applicantListIds.length - 1) {
      this.disablePrevButton = false;
      this.disableNextButton = true;
    }
    else {
      this.disablePrevButton = false;
      this.disableNextButton = false;
    }
  }

  changeApplisPrev(value) {

    this.changeApplicant(this.applicantListIds[this.applicantListIds.indexOf(value) - 1]);

  }
  changeApplistNext(value) {
    this.changeApplicant(this.applicantListIds[this.applicantListIds.indexOf(value) + 1]);
  }

  getApplicantsOverview() {

    this.sharedService.getAppsOverview().subscribe(
      (response: any) => {
        this.applicantsoverview = response;
      }
    );
  }
  getApplicantDetail() {
    this.sharedService.getApplicantDetailsData().subscribe(
      (response: any) => {
        this.applicationdetails = response.results;
      }
    );
  }

  getApplicantsJobtype() {
    this.sharedService.getAppsJobtype().subscribe(
      (response: any) => {
        this.jobtype = response;
      }
    );
  }

  getUserDetails() {
    this.sharedService.loginAts().subscribe((res) => {

      if (res.data.response == "LOGIN_VALID") {
        this.userData = res.data.userDetails;
      } else {
        localStorage.removeItem('token');
        window.location.href = environment.ATS_ENV;
      }
    })
  }
  getAcceptedDate(ev){
    this.acceptedDate = ev;
  }

  showSnackBarWithText(text: string): void {
    this.infoSnackBar.open(text, undefined, {
      duration: 3000
    });
  }

  updateStage(ev, event, reject, sendEmail?:boolean) {

    if (ev === 'New' || ev === 'Review' || ev === 'reactivate' || reject == true) {

      if (sendEmail) {

        const formData = new FormData();
        formData.append('sender', this.tenantId);
        formData.append('receiver', this.applicant.email);
        formData.append('senderEmployerId', this.userData?.userId);
        formData.append('jobApplicationId', this.jobApplicationId);
        formData.append('messageText', this.rejectEmail)
        formData.append('subject', "Regarding Your Application for " + this.job.title);
        formData.append('carbonCopy', this.userData?.email);
      
        this.sharedService.sendEmailToApplicant(formData).subscribe(
          {
            next: (response) => {
              this.showSnackBarWithText('Email Sent Successfully!');
             
              setTimeout(() => {
                this.email.getConversations();
              }, 2000);

              this.rejectEmail = '';
            },
            error: (error) => console.log(error)
          });

    
      }


      ev === 'New' ? ev = 'reactivate' : ev = STAGES[ev];
      const data = {
        jobApplicationId: this.jobApplicationId,
        employerStage: ev.replaceAll('_', '-')
      }
      this.sharedService.updateStage(data).pipe(takeUntil(this.unsubscribe)).subscribe(
        (response: any) => {

          this.getAppStatus(response.data.employerStage, response.data.isRead);
          if (!response.data.isWithdrawn) {
            this.isWithdrawn = false;
            this.closeDialog();
          }

        }
      )
    }
    else if (ev == 'offeraccepted') {
      this.selectedIndex = 7;
      const data = {
        jobApplicationId: this.jobApplicationId,
        employerStage: 'hired'
      }
      this.sharedService.updateStage(data).pipe(takeUntil(this.unsubscribe)).subscribe(
        (response: any) => {

          this.getAppStatus(response.data.employerStage, response.data.isRead);
          if (!response.data.isWithdrawn) {
            this.isWithdrawn = false;
          }
          if (response.message === 'success') {
           
            this.sendEmailAsConversation(this.notificationEmail);
          }

        }
      )
    }

    else {
      event.source.value = this.applicantStage;
      this.selectedIndex = (
        ev === 'Offer' ? 6 :
          ev === 'Hired' ? 7 :
            ev === 'Interview' ? 3 :
              0
      );
    }

  }

  loadEmailTemplate(ev) {
    if (ev === STAGES.rejected_more_qualified_selected || ev === STAGES.rejected_did_not_meet_qualifications) {
      
      let reqData : EmailTemplateRequest = {
        companyId : this.job.companyId,
        jobTitle : this.job.title,
        applicantName : (this.applicant.firstName + ' ' + this.applicant.lastName),
        email : this.userData.email,
        phoneNumber :  this.userData.phone,
        employerName : this.userData.firstName + ' ' + this.userData.lastName,
        linkToJob : ''
      };

      this.sharedService.loadJobTemplate(ViewApplicantConstants.EMAIL_TEMPLATES.REJECT, reqData).subscribe((response: any) => {

        this.rejectEmail = response.data?.template;
      });
    }
    else {
      this.rejectEmail = '';
    }
  }
  
  getEmails(event){

    if(event.index == ViewApplicantConstants.TAB_INDEX.EMAIL){
      this.email.getConversations();
    }
    else if (event.index === ViewApplicantConstants.TAB_INDEX.REVIEWS) {
      this.reviewsComponent.loadReviews();
    }
    else if (event.index === ViewApplicantConstants.TAB_INDEX.INTERVIEWS) {
      this.interviewsComponent.loadInterviews();
    }
    else if (event.index === ViewApplicantConstants.TAB_INDEX.NOTES) {
      this.notesComponent.loadNotes();
    }
    else if (event.index === ViewApplicantConstants.TAB_INDEX.OFFERS ) {
      this.applicantOffersComponent.loadOffersTab();
    }
    else if (event.index === ViewApplicantConstants.TAB_INDEX.HIRING ) {
      this.hiringComponent.loadHiringTab();
    }
    this.updateTabIndex = (
      event.index === 2 ? 'reviews' :
        event.index === 4 ? 'notes' :
          event.index === 3 ? 'interview' :
            0
    );
    
  }

  fetchAverage(){
    this.sharedService.getApplicant(this.applicationId).pipe(takeUntil(this.unsubscribe)).subscribe(

      (response: any) => {
        this.rating = response.data[1];
      }
    )
  }

  updateOfferStatus(ev) {
    if(ev==='hired'){
      this.selectedIndex = 7;
    }
    const data = {
      jobApplicationId: this.jobApplicationId,
      employerStage: STAGES[ev]
    }
    this.sharedService.updateStage(data).pipe(takeUntil(this.unsubscribe)).subscribe(
      (response: any) => {

        this.getAppStatus(response.data.employerStage, response.data.isRead);
        if (!response.data.isWithdrawn) {
          this.isWithdrawn = false;
        }

      }
    )
  }

  private sendEmailAsConversation(message: string) {
     const data: EmailRequest = {
      sender: this.tenantId,
      receiver: this.applicant.email,
      senderEmployerId: this.userData.userId,
      jobApplicationId: this.jobApplicationId,
      messageText: message,
       subject: "Regarding Your Application for " + this.job?.title
    };
  }

  openMakeOffer(ev) {
    this.openDialog(this.rejectTemplate, 600, ev);
  }

  openAddJobDialog(templateRef, width) {
    let dialogRef = this.dialog.open(InviteJobDialogComponent, {
      width: width + 'px',
      disableClose: true,
      data: {
        jobData: {
          candidateId: this.applicant.userId,
          tenantId: this.applicant.tenantId,
          receiverEmail: this.applicant.email
        },
        userId: this.userData.userId
      }
    });
  }
  openDialog(templateRef, width, ev) {

   
    let dialogRef = this.dialog.open(ev==='add-job' ? InviteJobDialogComponent : templateRef, {
      width: width + 'px',
      disableClose: true,
      data: {
        job: this.job
      }
    });

  }

  closeDialog() {
    let dialogRef = this.dialog.closeAll();
    this.showEmailTemplate = false;

  }

  showEmail(value) {
    this.showEmailTemplate = !this.showEmailTemplate;
  }

  getAppStatus(stage, isRead) {

    stage = stage ? stage.replaceAll('-', '_') : '';

    //  TO-DO: raghu - optimize below conditions

    // stage.replace('_', '').includes('rejected') ? this.rejected = true : this.rejected = false;
    // stage.replace('_','') == 'hired' ? this.hired = true : this.hired = false;

    if (stage == '' && isRead == true) {
      this.applicantStage = ViewApplicant.STAGES['reviewed'];
      this.rejected = false;
      this.hired = false;
      this.invitationSent = false;
    }

    else if (stage.includes('rejected') && !this.isWithdrawn) {
      this.rejected = true;
      this.hired = false;
      this.finalStatus = STAGES[stage];
      this.invitationSent = false;
    }
   

    else if(stage=='lead'){
      this.invitationSent = true;
      this.applicantStage = ViewApplicant.STAGES[stage];
    }
    else if (stage == 'hired' && !this.isWithdrawn) {
      this.rejected = false;
      this.hired = true;
      this.applicantStage = ViewApplicant.STAGES[stage];
      this.invitationSent = false;
      this.finalStatus = ViewApplicant.STAGES['hired'];
    }

    else {
      this.applicantStage = ViewApplicant.STAGES[stage];
      this.rejected = false;
      this.hired = false;
      this.invitationSent = false;
    }

  }

  getApplicant(id) {
    this.sharedService.getApplicant(id).pipe(takeUntil(this.unsubscribe)).subscribe(

      (response: any) => {
        // this.starsComponent.initialStars = response.data[1];
        
        this.rating = response.data[1];
        const responseData = response.data[0];
        responseData.isWithdrawn ? this.isWithdrawn = true : this.isWithdrawn = false;

        this.jobType = ViewApplicant.JOB_TYPE[responseData.jobType ? responseData.jobType.replace('-', '_') : ''];
        this.category = ViewApplicantConstants.JOB_CATEGORIES[responseData.category1Id];
        this.education = ViewApplicant.EDUCATION[responseData.education ? responseData.education.replace('-', '_') : ''];
        this.careerLevel = ViewApplicant.CAREER_LEVEL[responseData.careerLevel];
        this.payType = ViewApplicant.PAY_TYPE[responseData.payType ? responseData.payType.replace('-', '_') : ''];
        this.travel = ViewApplicant.TRAVEL[responseData.travel ? responseData.travel : ''];
        this.resumeId = responseData.resumeId;
        this.jobApplicationId = responseData.id;
        this.applicant = responseData;
        this.tenantId = responseData?.tenantId;
        this.selected = responseData.id;
        this.fetchTags(responseData.userId);
        this.getAppStatus(responseData.employerStage, responseData.isRead);
       
        if(responseData.resumeId === null){
          this.disableOtherFilesTab = true;
          this.resumeUrl = '';
          this.noResume = true;
      
        }
        else {
          this.getApplicantResume(responseData.resumeId);
          this.getOtherFiles(responseData.resumeId);
        }
        this.getAppliedJob(this.applicationId, responseData.userId);
        this.getApplicantCoverLetter();

      }
    );
  }

  getOtherFiles(resumeId) {
    this.sharedService.getOtherFiles(resumeId).pipe(takeUntil(this.unsubscribe)).subscribe(
      (response: any) => {
        if (response.data) {
          this.other_file = response.data;
          if (this.other_file.length === 0) {
            this.disableOtherFilesTab = true;
          }
          else {
            this.disableOtherFilesTab = false;
          }
        }
        else {
          this.other_file = '';
        }
      }
    );
  }
  getApplicantResume(resumeId) {

    this.sharedService.getApplicantResume(resumeId).pipe(takeUntil(this.unsubscribe)).subscribe(
      (response: any) => {
        if (response.data.length>0) {
          this.fileType = response.data[1].extension==='pdf'?'file':'attachment';
          this.resumeUrl = environment.FILE_URL + response.data[0].fileId;
        }
        else {
          this.noResume = true;
          this.resumeUrl = '';
        }
      }
    );
  }

  getApplicantCoverLetter() {
    this.sharedService.getCoverLetter(this.applicationId).pipe(takeUntil(this.unsubscribe)).subscribe(
      (response: any) => {
        this.coverLetter = response.data.coverLetter;
        if (this.coverLetter == null) {
          this.disableTabs = true;
        }
        else {
          this.disableTabs = false;
        }

      }
    )
  }

  getApplicantListForJob(id) {
    this.sharedService.getApplicantListForJob(id).pipe(takeUntil(this.unsubscribe)).subscribe(
      (response: any) => {

        this.applicantList = response.data;
        this.extractApplicantIds(this.applicantList);

      }
    );
  }
  getApplicantOtherJobs(userId, jobId) {
    this.sharedService.getApplicantOtherJobs(userId, jobId).pipe(takeUntil(this.unsubscribe)).subscribe(
      (response: any) => {

        this.otherJobs = response.data;
      }
    );
  }
  extractApplicantIds(applicantList) {
    applicantList.forEach(applicant => {
      this.applicantListIds.push(applicant.id);
    });
    this.isFirstOrLast(this.applicationId);
  }

  getAppliedJob(id, userId) {
    this.sharedService.getAppliedJob(id).pipe(takeUntil(this.unsubscribe)).subscribe(
      (response: any) => {

        this.job = response.data[0];
        this.jobLocation = response.data[1];
        this.getApplicantOtherJobs(userId, this.job.id);


      },
      (err) => {
        console.log(err);
      }
    );
  }
  openOtherFile(fileId) {
    if (fileId) {
      window.location.href = environment.FILE_URL + fileId;

    }
  }

  loadApplicantDetails() {

    this.getApplicant(this.applicationId);
    this.getApplicantListForJob(this.applicationId);
  }

  ngOnInit(): void {
    this.userData = this.getUserDetails();
    this.router.paramMap.subscribe(params => {
      this.applicationId = params.get('id');
    });

    this.loadApplicantDetails();
    this.formdata = this.formBuilder.group({
      rejectEmail: new FormControl('', Validators.required),

    });

  }
  ngOnDestroy() {

    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  selectable = true;
  removable = false;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  add(event: MatChipInputEvent): void {

    const value = (event.value || '').trim();
    const input = event.input;
   
    this.tags = this.tags===null ? [] : this.tags;

    if (value && !this.tags?.includes(value) && this.tags?.length < 8) {
      this.tags.push(value);
      
    }
    // Clear the input value
    if (input) {
      input.value = '';
    }
  }
  saveTags(orgId, userId){
    this.showTags = !this.showTags;
    this.removable = !this.removable;
    this.sharedService.updateTags(this.tags, this.tenantId, userId).subscribe((res) => {
      (response: any) => {
        this.tags.push(response.data.tags);
        
      }
    })

  }

  remove(tags): void {
    const index = this.tags.indexOf(tags);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  fetchTags(candidateId) {

    if (!this.userData) {
      this.sharedService.loginAts().subscribe((res) => {

        if (res.data.response == "LOGIN_VALID") {
          this.userData = res.data.userDetails;
          this.getTags(candidateId, this.tenantId);
        } 
  
      })
    } else {

      this.getTags(candidateId, this.tenantId);
    }
    

  }
  getTags(candidateId, orgId){
    this.sharedService.getCandidateTags(candidateId, orgId).subscribe(

      (response: any) => {
        this.tags = response.data.tags;
        
      }
    )
  }
}

export enum STAGES {
  new = <any>"New",
  reviewed = <any>"Review",
  hired = <any>"Hired",
  interviewed = <any>"Interview",
  'offer-sent' = <any>"Offer",
  rejected = <any>"rejected",
  reactivate = <any>"reactivate",
  'lead' = <any>"New",
  'rejected_more_qualified_selected' = <any>"More Qualified Candidate Selected",
  'rejected_no_show_for_interview' = <any>"No Show for Interview",
  'rejected_unresponsive' = <any>"Unresponsive",
  'rejected_did_not_meet_qualifications' = <any>"Did not meet qualifications",

}
