import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';
import { AtsService } from 'src/app/services/ats.service';
import { ViewApplicantConstants } from '../../constants/ViewApplicantConstants';

@Component({
  selector: 'app-jobsrouter',
  templateUrl: './jobsrouter.component.html',
  styleUrls: ['./jobsrouter.component.scss']
})
export class JobsrouterComponent implements OnInit {

  createdDate: any;
  city: string;
  state: any;
  categories = [];

  constructor(private router: Router, private atsService: AtsService) { }

  refresh(params: ICellRendererParams): boolean {
    throw new Error('Method not implemented.');
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    throw new Error('Method not implemented.');
  }

  params: any;

  agInit(params: any): void {

    this.params = params;
    this.createdDate = new Date(params.data.createdAt).toLocaleDateString();
    this.city = params.data.city ? params.data.city + ', ' : ''
    this.state = params.data.state ? params.data.state : '';
    this.categories = params.data.categories.split(',').map((val) => {
      return ViewApplicantConstants.JOB_CATEGORIES[val ? val : ''];
    });
  }

  editJob(id){

    this.atsService.isUpdateJob(true);
    this.router.navigate(['/job/edit/', id]);
    
  }
  
  ngOnInit(): void {
  }

}
