import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Packages } from '../model/packages.model';

@Injectable({
  providedIn: 'root'
})
export class AtsService {
 
  private isATSPlus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public atsPlusCustomer: Observable<boolean> = this.isATSPlus.asObservable();

  private checkout: BehaviorSubject<Packages> = new BehaviorSubject<Packages>(null);
  public checkoutSubject: Observable<Packages> = this.checkout.asObservable();
  
  private jobData = new BehaviorSubject('default message');
  currentMessage = this.jobData.asObservable();


  private offerData = new BehaviorSubject('1');
  offerId = this.offerData.asObservable();

  private marketplace = new BehaviorSubject(false);
  isEnhancement = this.marketplace.asObservable();
  

  private jobUpdate = new BehaviorSubject(false);
  isJobUpdate = this.jobUpdate.asObservable();

  constructor() { }

  updateJobData(data){
    this.jobData.next(data);
  }

  updateOfferId(id){
    this.offerData.next(id);
  }

  updateCheckout(checkoutSubject){
    this.checkout.next(checkoutSubject);
  }

  goToMarketplace(data){
    this.marketplace.next(data);
  }

  isUpdateJob(isUpdate){
    this.jobUpdate.next(isUpdate);
  }

  checkATSPlus(atsPlusCustomer) {
    this.isATSPlus.next(atsPlusCustomer);
  }

}
