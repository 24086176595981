<div class="row">
    <div class="col-12">
        <p class="mat-typography">Please review your Job Post details before continuing.
        </p>
    </div>
</div>

<div class="border">
    <div class="row  m-4">
        <div class="col-md-3">
            <img [src]="logo">
        </div>
    </div>


    <div class="row ms-4">
        <div class="col-md-8">
            <h1 class=" mat-typography text-primary">
                {{jobData.title}}
            </h1>
            <p class="text-uppercase ">
                <b>
                    {{jobData.city}} {{jobData.state}} {{jobData.status}} {{jobData.createdAt | date: 'short'}}
                </b>
            </p>
        </div>
        <div class="col-md-3 text-end">
            <button mat-raised-button color="primary" class="text-uppercase">Apply Now</button>
        </div>

    </div>
    <div class="row ms-4">
        <div class="col-md-6">
            <p class="mat-typography">
                {{jobData.description}}
            </p>
            <h1 class="mat-typography text-primary" *ngIf="jobData.requirements">
                Requirements
            </h1>
            <p class="mat-typography">
                {{jobData.requirements}}
            </p>
            <h1 class="mat-typography text-primary" *ngIf="jobData.benefits">
                Benefits
            </h1>
            <p class="mat-typography">
                {{jobData.benefits}}
            </p>
        </div>
    </div>

    <div class="d-flex justify-content-end">
        <div class="col-md-3 float-end m-3">
            <p class="mat-typography text-center">-Powered By-</p>
            <img src="../../../../../../assets/images/Logo-US.svg" />
        </div>
    </div>

</div>