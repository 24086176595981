import { KeyValue } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, NgForm, FormArray } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GlobalConstants } from 'src/app/components/common/constants';
import { ViewApplicantConstants } from 'src/app/components/common/constants/ViewApplicantConstants';
import { AltCompany, Job, Jobs } from 'src/app/model/jobs';
import { AtsService } from 'src/app/services/ats.service';
import { SharedService } from 'src/app/services/shared.service';
import { environment } from 'src/environments/environment';
import { StepFourComponent } from '../steps/step-four/step-four.component';
import { StepOneComponent } from '../steps/step-one/step-one.component';
import { StepThreeComponent } from '../steps/step-three/step-three.component';
import { StepTwoComponent } from '../steps/step-two/step-two.component';

@Component({
    selector: 'app-add-job',
    templateUrl: './add-job.component.html',
    styleUrls: ['./add-job.component.scss']
})
export class AddJobComponent implements OnInit, AfterViewInit, AfterViewChecked {


    jobId: any;
    jobDetailsData: Job;
    enhancementsData: any;
    altCompany = new AltCompany();

    notify: any;
    isJius: boolean;
    isExternal: boolean;
    showEmail: boolean;
    companies: any = [];
    startsAt: FormControl;
    endsAt: FormControl;
    category = [];
    applicationSettings: any;
    id: any;
    jobData = new Job;
    options: FormGroup;
    jobCompany: any;
    locationData: Object;
    @ViewChild(StepOneComponent) public stepOneForm: StepOneComponent;
    @ViewChild(StepTwoComponent) public stepTwoForm: StepTwoComponent;
    @ViewChild(StepThreeComponent) public stepThreeForm: StepThreeComponent;
    @ViewChild(StepFourComponent) public stepFourForm: StepFourComponent;
    number: number;
    user: any;
    private unsubscribe = new Subject();
    private isFromMarketplaceUnsubscribe: Subscription;
    tenantId: any;
    fromDashboard = false;
    @ViewChild('stepper') stepper: MatStepper;
    isFromMarketplace: any;
    isUpdateJob: any;
    isUpdateJobUnsubscribe: Subscription;

    constructor(private _formBuilder: FormBuilder,
        private sharedService: SharedService,
        private router: ActivatedRoute,
        private infoSnackBar: MatSnackBar,
        private cdr: ChangeDetectorRef,
        private route: Router,
        private atsService: AtsService
    ) {
        this.options = _formBuilder.group({
            floatLabel: this.floatLabelControl,
        });

        this.router.paramMap.subscribe(params => {
            this.jobId = params.get('id');
        })
    }
    screeningForm: FormGroup;
    selectedCompany: any;
    screeningQuestions: any = [];
    // Constants
    locationName: any;
    selectedCategory = [];
    categories = ViewApplicantConstants.JOB_CATEGORIES;
    types = GlobalConstants.JOB_TYPE;
    terms = GlobalConstants.terms;
    travel = GlobalConstants.TravelRequirement;
    careerLevel = GlobalConstants.CAREER_LEVEL;
    payType = GlobalConstants.PAY_TYPE;
    payRange = GlobalConstants.payRange;
    education = GlobalConstants.EDUCATION;


    jius: string = "jius";
    external: string = "external";
    checked = true;
    floatLabelControl = new FormControl('auto');

    originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
        return 0;
    }

    updateCategory(event) {
        this.jobData.category1Id = event.value[0] ? event.value[0] : '';
        this.jobData.category2Id = event.value[1] ? event.value[1] : '';
        this.jobData.category3Id = event.value[2] ? event.value[2] : '';
    }

    getCompanies() {

        this.sharedService.getCompanies().subscribe(
            {
                next: (response) => {
                    this.companies = response.data;
                    this.companies.map((comp) => {
                        if (comp.isSelected === true) {
                            this.jobCompany = comp;
                        }
                    });

                },
                error: (error) => console.log(error)
            });

    }

    goForward(stepper: MatStepper) {
        stepper.next();
    }

    onSubmit(isLater?) {

        this.addToJobData();
        if (this.jobId) {
            this.jobData.id = this.jobId;
        } else {
            if (isLater) 
                this.jobData.isActive = false;
            else
                this.jobData.isActive = true;
        }

        this.jobData.createdAt = new Date().toISOString();
        this.jobData.screeningQuestions = this.screeningForm.value.screeningquestions;
        this.jobData.companyId = this.jobCompany.id;
        this.jobData.posterId = this.user.userId;
        this.jobData.tenantId = this.tenantId;

        if (this.jobId) {
            this.updateJob();
        }
        else {
            this.createJob()
        }
        if(isLater){
            this.route.navigate(['my-jobs']);
        } else {
            this.goForward(this.stepper);
        }
    }

    showSnackBarWithText(text: string): void {
        this.infoSnackBar.open(text, undefined, {
            duration: 3000
        });
    }

    updateJob() {
        this.sharedService.updateJob(this.jobData).subscribe(
            (data: any) => {
                if (data.message === 'success') {
                    this.showSnackBarWithText('Job Updated Successfully');
                }
            })
    }

    createJob() {
        this.sharedService.createJob(this.jobData).subscribe((data: any) => {
            if (data.message === 'success') {
                this.showSnackBarWithText('Job Created Successfully');
            }
        })
    }

    getJobById(jobId: any) {

        this.sharedService.findJob(jobId).subscribe(
            (response: any) => {

                if (response.data) {

                    this.jobData = response.data;
                    this.tenantId = response.data.tenantId;

                    this.companies.map((comp) => {
                        if (comp.id == this.jobData.companyId && this.jobData.companyId != null) {
                            this.jobCompany = comp;

                        }
                        else if (comp.isSelected) {
                            this.jobCompany = comp;

                        }
                    });

                    if (this.jobData.screeningQuestions?.length > 0) {
                        
                        this.screeningQuestions = this.jobData.screeningQuestions;
                        this.screeningQuestions.forEach(screening => {
                            this.questions.push(this._formBuilder.group({
                                question: [screening.question],
                                type: [screening.type]
                            }));
                        });
                    }
                    else {
                        this.screeningQuestions = {
                            question: '',
                            type: ''
                        }
                    }
                }

                this.enhancementsData = response.data.enhancements;

            },
            (err: HttpErrorResponse) => {

                // window.location.href = environment.ATS_ENV;
            }
        )
    }

    get step1() {
        return this.stepOneForm ? this.stepOneForm.stepOneForm : null;
    }
    get step2() {
        return this.stepTwoForm ? this.stepTwoForm.stepTwoForm : null;
    }
    get step3() {
        return this.stepThreeForm ? this.stepThreeForm.stepThreeForm : null;
    }
    get step4() {
        return this.stepFourForm ? this.stepFourForm.stepFourForm : null;
    }

    addToJobData() {

        this.jobData = { ...this.jobData, ...this.step1.value, ...this.step2.value, ...this.step3.value, ...this.step4.value };
    }
    getUserDetails() {

        this.sharedService.loginAts().subscribe((res) => {

            if (res.data.response == "LOGIN_VALID") {
                this.user = res.data.userDetails;
            }
        })
    }


    ngAfterViewChecked(): void {

        this.cdr.detectChanges();
    }
    ngAfterViewInit(): void {
      
        if (this.isFromMarketplace) {
            this.stepper.selectedIndex = 6;
        }
        setTimeout(() => {
            this.stepper.linear = true;
        });
        
        
    }

    get screening() { return this.screeningForm.controls; }
    get questions() { return this.screening.screeningquestions as FormArray; }

    onChangeScreening() {

        this.questions.push(this._formBuilder.group({
            question: [''],
            type: ['']
        }));

    }

    ngOnInit() {

        this.screeningForm = this._formBuilder.group({
            screeningquestions: new FormArray([]),
        })
        this.checkATSPackage();
        this.getCompanies();

        this.isFromMarketplaceUnsubscribe = this.atsService.isEnhancement.subscribe(data => {
            this.isFromMarketplace = data;
        });
        this.isUpdateJobUnsubscribe = this.atsService.isJobUpdate.subscribe(data => 
        { 
            this.isUpdateJob = data;
        });
        if (this.jobId) {
            this.getJobById(this.jobId);
            this.atsService.isUpdateJob(true);
        }
        else{
            this.onChangeScreening();
        }

        this.getUserDetails();
     
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.atsService.goToMarketplace(false);
        this.isFromMarketplaceUnsubscribe.unsubscribe();
        this.atsService.isUpdateJob(false);
        this.isUpdateJobUnsubscribe.unsubscribe();
    }

    checkATSPackage() {
        this.sharedService.getATSPackages().subscribe((response: any) => {
            if (response.data){
                const res = response.data;
                const isPlus = res.find(a => a === 'ats_one_year_plus');
                this.atsService.checkATSPlus(isPlus);
            }
        });
    }

}
