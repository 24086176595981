<div class="chat-panel">
    <div class="chat-content w-100 p-2">
        <h2 class="ps-3 font-condensed" >  
            Your Conversation
        </h2>
        <ul class="list-group list-group-flush message-content_list" *ngIf="conversation">
            <li *ngFor="let userchatmessage of conversation.conversation.slice().reverse();"
                [ngClass]="userchatmessage?.senderId.length === 0 ? 'justify-content-start align-items-end' : 'flex-row-reverse justify-content-start align-items-end'"
                class="list-group-item border-0 d-flex">
                <div class="chat-user mb-4"
                    [ngClass]="userchatmessage.senderId === '' ? 'me-2' : 'chat-user-content_bg ms-2'">
                    <p class="mb-0 font-condensed" [ngClass]="userchatmessage.senderId === '' ? '' : 'chat-user-content_bg'">
                        {{userchatmessage.senderId ? employerInitials: seekerInitials }}
                    </p>
                </div>
                <div class="chat-user-content">
                    <p class="mb-0 email-padding font-condensed multi_lines_text"
                        [ngClass]="userchatmessage.senderId === '' ? 'chat-user_bg mx-width-email' : 'chat-user-content_bg '" [innerHTML]="userchatmessage.conversationText">
                       
                    </p>
                    <div class="d-flex" [ngClass]="userchatmessage.senderId ==='' ? '' : 'justify-content-end' ">
                    <small class="date-time"> {{userchatmessage?.timestamp | date:'short' }}</small>

                    </div>
                </div>
            </li>
        </ul>
    </div>
    <div class="row d-flex align-items-center">
        <p class="mat-typography mb-0">Select Email Template</p>
        <div mat-dialog-content class="col-md-4 mt-0">
            <mat-form-field style="margin-bottom: -1.25em" appearance="outline" ngDefaultControl>
                <mat-select (selectionChange)="loadEmailTemplate($event.value)">
                    <mat-option value=''></mat-option>
                    <mat-option value="THANK_YOU">Thank you template</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="chat-footer container">
        <div class="row py-3 justify-content-center align-items-center">
            <form [formGroup]="formdata" >
                <div class="col-md-12 col-lg-12 col-xl-12">

                    <mat-form-field appearance="outline" class="messageinput w-100">
                        <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" #mname
                          formControlName="text" [(ngModel)]="text" ></textarea>

                    </mat-form-field>

                </div>
                <div class="d-flex ">
                    <div class="col-md-5 col-lg-4 col-xl-2 me-4">
                        <button mat-raised-button class="post-btn text-uppercase mt-2 " type="submit"
                          [disabled]="formdata.invalid "  (click)="sendMail(text)" color="primary">Send Via Email</button>
                    </div>
                    <div class="file-upload mt-1 ms-2">
                        <input type="file" multiple class="file-input d-none" (change)="onFileSelected($event)"
                             #fileUpload>
                        <div class="file-upload">
                            <button [disabled]="formdata.invalid" mat-mini-fab color="primary" class="upload-btn" (click)="fileUpload.click()">
                                <mat-icon>attach_file</mat-icon>
                            </button>
                        </div>

                    </div>
                    <div class="mt-3 ms-2" *ngFor="let file of fileName">{{file || ""}}</div>
                </div>
            </form>
        </div>
    </div>
</div>