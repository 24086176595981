import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-applicant-post',
  templateUrl: './applicant-post.component.html',
  styleUrls: ['./applicant-post.component.scss']
})
export class ApplicantPostComponent implements OnInit, OnChanges {
  @Input() posts = [];
  @Input() user: any;
  @Input() jobApplicationId: any;
  notes = [];

  constructor(public sharedService: SharedService) { }

  // TO-DO : Raghu - WORK IN PROGRESS 
  ngOnInit(): void {

  }

  loadNotes() {
    this.getNotes(this.jobApplicationId);
  }
  ngOnChanges() {
   
  }
  getNotes(jobApplicationId) {
    this.sharedService.getNotes(jobApplicationId).subscribe(
      (response: any) => {
        this.notes = response.data;
      }
    )
  }

  sendPost(input, post) {
    const data = {
      "jobApplicationId": this.jobApplicationId,
      "message": post,
      "postedBy": this.user?.userId,
      "posterName": this.user?.firstName + " " + this.user?.lastName
    }

    if (post !== null) {
      input.value = '';
    }
    this.sharedService.addNotes(data).subscribe(
      (response: any) => {
        this.notes.push(response.data);
      },
      (err) => {
        console.log(err);
      }
    )



  }
}
