export class JobApplication{
    id: number
    employerStage: string
    createdAt: ''
    jobId: number
    candidateId: number
    isDeleted = false
    isArchived = false
    isDeletedForEmployer = false
    isWithdrawn = false
    isInternal = false
    isRead = false
}