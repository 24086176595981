import { SharedService } from 'src/app/services/shared.service';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AtsService } from 'src/app/services/ats.service';
import { ViewApplicantConstants } from 'src/app/components/common/constants/ViewApplicantConstants';
import { EmailTemplateRequest } from 'src/app/model/EmailTemplateRequest';

@Component({
  selector: 'app-applicant-offers',
  templateUrl: './applicant-offers.component.html',
  styleUrls: ['./applicant-offers.component.scss']
})
export class ApplicantOffersComponent implements OnInit {

  htmlContent = '';
  showEmailTemplate = false;
  formdata: FormGroup;
  hiringform: FormGroup;
  @Input() userTypeMessages = [];
  @Input() applicationdetails;
  @Input() applicantEmailId;
  @Input() applicantLastName;
  @Input() applicantFirstName;
  @Input() user: any;
  @Input() jobApplicationId = '';
  @Input() tenantId: any;
  @Input() job: any;
  @Output() offerMailSent = new EventEmitter<string>();
  @Output() hiredDate = new EventEmitter<string>();
  offerData = [];
  offerText: any;
  @Input() offerSent;
  offered = false;
  @Output() offerAccepted = new EventEmitter<string>();
  showConfirmContent = false;

  today = new Date();
  disableAccept = false;
  carbonCopy = false;
  offerId: any;
  @ViewChild('fileUpload') fileUpload: ElementRef;

  fileName = [];
  file: File[] = [];
  checked = false;
  disableOffer = false;

  constructor(
    public dialog: MatDialog,
    private sharedService: SharedService,
    private formBuilder: FormBuilder,
    private infoSnackBar: MatSnackBar,
    private atsService: AtsService
  ) { }


  openDialog(templateRef, width, id) {
    let dialogRef = this.dialog.open(templateRef, {
      width: width + 'px',
      disableClose: true
    });
    this.offerId = id;
  }

  loadOffersTab() {

    this.getOffers();
  }


  closeDialog() {
    let dialogRef = this.dialog.closeAll();
    this.checked = false;

  }
  showConfirm() {
    this.showConfirmContent = true;
  }
  showEmail(value) {
    this.carbonCopy = !this.carbonCopy;
  }

  loadJobTemplate(val) {

    if (val) {

      let reqData : EmailTemplateRequest = {
        companyId : this.job.companyId,
        jobTitle : this.job.title,
        applicantName : (this.applicantFirstName + ' ' + this.applicantLastName),
        email : this.user.email,
        phoneNumber :  this.user.phone,
        employerName : this.user.firstName + ' ' + this.user.lastName,
        linkToJob : ''
      };

      this.sharedService.loadJobTemplate(ViewApplicantConstants.EMAIL_TEMPLATES[val], reqData).subscribe((response: any) => {

        this.offerText = response.data?.template;
      });
    } 
    else {
      this.offerText = '';
    }

  }

  updateHired(offerId, formValues) {

    this.sharedService.updateOfferAccepted(offerId).subscribe(
      (response: any) => {
        this.atsService.updateOfferId(offerId);
      }
    )

    const hiring_data = {

      jobApplicationId: this.jobApplicationId,
      sentBy: this.user.userId,
      offerId: offerId,
      acceptance_date: formValues.accepted_date

    }

    this.sharedService.createHired(hiring_data).subscribe(
      (response: any) => {
      }
    )


    this.offerAccepted.emit('hired');
    this.hiredDate.emit(hiring_data.acceptance_date);
    this.disableAccept = true;
    this.closeDialog();
  }

  onFileSelected(event) {

    let fileList = event.target.files as FileList

    for (let i = 0; i < fileList.length; i++) {
      this.file.push(fileList.item(i));
      this.fileName.push(fileList.item(i).name);
    }
  }

  sendMail(text) {

    const formData = new FormData();
    formData.append('sender', this.tenantId);
    formData.append('receiver', this.applicantEmailId);
    formData.append('senderEmployerId', this.user.userId);
    formData.append('jobApplicationId', this.jobApplicationId);
    formData.append('messageText', this.offerText)
    formData.append('subject', "Regarding Your Application for " + this.job.title);
    if (this.carbonCopy) {
      formData.append('carbonCopy', this.user.email);
    }
    if (this.file.length > 0) {
      for (let files in this.file) {

        formData.append('attachments', this.file[files]);
      }
    }
    else {
      formData.append('attachments', '');
    }

    this.sharedService.sendEmailToApplicant(formData).subscribe(
      {
        next: (response) => {
          this.offerSent = 'Offer';
          this.offered = true;
          this.showSnackBarWithText('Email Sent Successfully!');
          this.fileUpload.nativeElement.value = '';
          this.fileName = [];
          this.file = [];

          this.offerText = '';
        },
        error: (error) => console.log(error)
      }
    )


    const data = {
      jobApplicationId: this.jobApplicationId,
      sentBy: this.user.userId,
      offer_sent_date: new Date().toISOString(),
      offer_accepted: false,
      email_content: this.offerText,
      attachments: 123
    }

    this.sharedService.addOffer(data).subscribe(
      (response: any) => {
        this.offerData.push(response.data);
        this.offerMailSent.emit('Offer');
      }
    )
  }


  showSnackBarWithText(text: string): void {
    this.infoSnackBar.open(text, undefined, {
      duration: 3000
    });
  }

  getOffers() {
    this.sharedService.getOffers(this.jobApplicationId).subscribe(
      (response: any) => {
        response.data?.length > 0 ? this.offered = true : this.offered = false;
        this.offerData = response.data;
        this.offerData.map((offer) => {
          if (offer.offer_accepted === true) {
            this.disableAccept = true;
          }

        })
      }
    );
  }


  ngOnInit(): void {

    if(this.offerSent==='lead' || this.offerSent==='hired'){
      this.disableOffer = true;
    }
    this.offerSent === 'Offer' ? this.offered = true : this.offered = false;

    this.formdata = this.formBuilder.group({
      offerText: new FormControl('', Validators.required)

    });
    this.hiringform = this.formBuilder.group({
      accepted_date: new FormControl('', Validators.required)

    });

  }

  ngOnChanges() {

    
  }

}
