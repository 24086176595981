<div class="chat-content w-100 review-content" *ngIf="reviews">
    <div class="chat-footer container py-3">
        <h2 class="mb-0">Add a Review</h2>
        <div class="row py-3 justify-content-center align-items-start">
            <div class="col-md-7 col-lg-7 col-xl-8">
                <mat-form-field appearance="outline" class="postinput w-100">
                    <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1.3" cdkAutosizeMaxRows="5"
                        #rname></textarea>
                </mat-form-field>
            </div>
            <div class="col-md-5 col-lg-5 col-xl-4 pe-md-1">
                <form class="rating rating-default mb-3">
                    <label>
                        <input type="radio" [(ngModel)]="radioSelected" name="stars" value="1" />
                        <span class="icon">★</span>
                    </label>
                    <label>
                        <input type="radio" [(ngModel)]="radioSelected" name="stars" value="2" />
                        <span class="icon">★</span>
                        <span class="icon">★</span>
                    </label>
                    <label>
                        <input type="radio" [(ngModel)]="radioSelected" name="stars" value="3" />
                        <span class="icon">★</span>
                        <span class="icon">★</span>
                        <span class="icon">★</span>
                    </label>
                    <label>
                        <input type="radio" [(ngModel)]="radioSelected" name="stars" value="4" />
                        <span class="icon">★</span>
                        <span class="icon">★</span>
                        <span class="icon">★</span>
                        <span class="icon">★</span>
                    </label>
                    <label>
                        <input type="radio" [(ngModel)]="radioSelected" name="stars" value="5" />
                        <span class="icon">★</span>
                        <span class="icon">★</span>
                        <span class="icon">★</span>
                        <span class="icon">★</span>
                        <span class="icon">★</span>
                    </label>
                </form>
                <button (click)="getRating(rname, rname.value)" mat-raised-button class="post-btn text-uppercase mb-3"
                    color="primary">
                    Post Review</button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <hr class="dropdown-divider my-0">
                <figure class="py-4 mb-0" *ngFor="let rating of reviews.slice().reverse();">
                    <figcaption class="d-flex align-items-center mb-2">
                        <span class="material-icons" *ngIf="rating.rating == '1'">
                            star
                        </span>
                        <span *ngIf="rating.rating == '2'">
                            <span class="material-icons text-primary">
                                star
                            </span>
                            <span class="material-icons text-primary">
                                star
                            </span>
                        </span>
                        <span *ngIf="rating.rating == '3'">
                            <span class="material-icons text-primary">
                                star
                            </span>
                            <span class="material-icons text-primary">
                                star
                            </span>
                            <span class="material-icons text-primary">
                                star
                            </span>
                        </span>
                        <span *ngIf="rating.rating == '4'">
                            <span class="material-icons text-primary">
                                star
                            </span>
                            <span class="material-icons text-primary">
                                star
                            </span>
                            <span class="material-icons text-primary">
                                star
                            </span>
                            <span class="material-icons text-primary">
                                star
                            </span>
                        </span>
                        <span *ngIf="rating.rating == '5'">
                            <span class="material-icons text-primary">
                                star
                            </span>
                            <span class="material-icons text-primary">
                                star
                            </span>
                            <span class="material-icons text-primary">
                                star
                            </span>
                            <span class="material-icons text-primary">
                                star
                            </span>
                            <span class="material-icons text-primary">
                                star
                            </span>
                        </span>
                        <span class="ms-2">
                            {{rating.postedOn + 'Z' | date: 'longDate'}} {{rating.postedOn + 'Z'| date: 'shortTime' }} By {{user?.firstName}} {{user?.lastName}}
                        </span>
                    </figcaption>
                    <blockquote class="blockquote mb-0">
                        <p class="mb-0 fst-italic">" {{rating.message}} "</p>
                    </blockquote>
                </figure>
            </div>
        </div>
    </div>
</div>