<form [formGroup]="stepFourForm">
    <mat-radio-group aria-label="Select an option">

        <div class="row" formGroupName="applicationSettings">
            <div class="col-md-3">
                <mat-checkbox formControlName="hasResume" (change)="stepFourForm.get('applicationSettings.hasResume').setValue(!$event.checked)" class="mat-typography">Quick Apply : Applicants
                    apply without resumes
                </mat-checkbox>
            </div>

            <h1 class="jd-add font-condensed pt-3">Notification Frequency</h1>
            <div formGroupName="email" >
                <mat-radio-group formControlName="notify" aria-label="Select an option" name="notify">
                    <mat-radio-button (click)="hideField()" class=" my-2 mat-typography"
                        value="never">
                        Never:
                        View
                        applicants by signing into your account<br>
                    </mat-radio-button> <br>
                    <mat-radio-button (click)="showEmailField()" class="my-2 mat-typography"
                        value="per-day">
                        Daily
                        Digest: Sent once per day at
                        approximately 4:30 PM EST.
                    </mat-radio-button><br>
                    <mat-radio-button (click)="showEmailField()" class="my-2 mat-typography"
                        value="per-app">
                        Per
                        Application: Sent every time
                        someone
                        applies to a Job

                    </mat-radio-button><br>
                    <br>
                    <mat-error *ngIf="stepFourForm.get('applicationSettings.email.notify').hasError('required')">
                        Please select atleast one option
                    </mat-error>
                </mat-radio-group>
            </div>
            <div *ngIf="showEmail">
                <div formGroupName="email"> 
                    <h1 class="font-condensed">Email Settings</h1>
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Email Address</mat-label>
                        <input class="w-100" formControlName="address" matInput placeholder="Email" name="address">
                    </mat-form-field>
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Email Subject</mat-label>
                        <input class="w-100" formControlName="subject" matInput placeholder="Email" name="subject">
                    </mat-form-field>
                </div>
            </div>
        </div>
        <br>

  
    </mat-radio-group>
</form>