<mat-nav-list>
    <div *ngFor="let item of navItems" class="h-100">
        <a mat-list-item [routerLink]="item.route">
            {{item.displayName}}
        </a>
        <div *ngFor="let subitem of item.children">
            <a class="sub-menu-link" mat-list-item  *ngIf="item.children && item.children.length"
                [routerLink]="subitem.route">
                {{subitem.displayName}}
            </a>
        </div>
    </div>
</mat-nav-list>