import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AtsService } from 'src/app/services/ats.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-applicant-hiring',
  templateUrl: './applicant-hiring.component.html',
  styleUrls: ['./applicant-hiring.component.scss']
})
export class ApplicantHiringComponent implements OnInit {
  showEmailTemplate = false;
  offerDate;
  @Input() hiredStatus: any;
  @Input() acceptedDate: any;
  showHiredContent = false;
  hiringform: FormGroup;
  offerId: string;
  @Input() jobApplicationId;
  disabled = false;
  hiringData: any;
  actual: string;
  expected: string;

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private atsService: AtsService,
    private sharedService: SharedService
  ) { }

  openDialog(templateRef, width) {

    let dialogRef = this.dialog.open(templateRef, {
      width: width + 'px'
    });
  }

  closeDialog() {
    let dialogRef = this.dialog.closeAll();
  }

  showEmail(value) {
    this.showEmailTemplate = !this.showEmailTemplate;
  }

  updateHiring(formValues) {

    this.atsService.offerId.subscribe(id => this.offerId = id);
    if (formValues) {
      const data = {
        "offerId": this.offerId,
        "expected_start_date": formValues.expected_start_date,
        "actual_start_date": formValues.actual_start_date,
        "jobApplicationId": this.jobApplicationId
      }


      this.sharedService.updateHiring(data).subscribe(
        (response: any) => {
          this.disabled=true;
          this.actual = new Date(response.data.actual_start_date).toLocaleDateString();
          this.expected = new Date(response.data.expected_start_date).toLocaleDateString();
          this.offerDate = response.data.acceptance_date;
        }
      )


    }

  }

  getHiring() {
    this.sharedService.getHiring(this.jobApplicationId).subscribe(
      (response: any) => {
        if (response.data) {
          this.actual = new Date(response.data.actual_start_date).toLocaleDateString();
          this.expected = new Date(response.data.expected_start_date).toLocaleDateString();
          this.offerDate = response.data.acceptance_date;
        }
        response.data?.actual_start_date != null ? this.disabled = true : this.disabled = false;

      }
    )
  }

  ngOnInit() {

  }

  loadHiringTab() {
    this.hiredStatus === 'Hired' ? this.showHiredContent = true : this.showHiredContent = false;
    this.hiringform = this.formBuilder.group({
      expected_start_date: new FormControl('', Validators.required),
      actual_start_date: new FormControl('', Validators.required)
    });
  }

  ngOnChanges() {

  }

}
