import { SelectionModel } from '@angular/cdk/collections';
import { KeyValue } from '@angular/common';
import { createUrlResolverWithoutPackagePrefix } from '@angular/compiler';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CartObject } from 'src/app/model/CartObject';
import { CartPackage } from 'src/app/model/CartPackage';
import { PackageDetails, Packages, PackageTypes } from 'src/app/model/packages.model';
import { AtsService } from 'src/app/services/ats.service';
import { SharedService } from 'src/app/services/shared.service';
@Component({
  selector: 'app-job-marketplace',
  templateUrl: './job-marketplace.component.html',
  styleUrls: ['./job-marketplace.component.scss']
})
export class JobMarketplaceComponent implements OnInit {

  @ViewChild(MatAccordion) accordion: MatAccordion;
  prices = PackageDetails.SLOT_PRICES;
  slotObjectConstant = PackageDetails.SLOT_OBJECTS;
  selection = new SelectionModel<any>(false, []);
  cartTotal: any;
  @Output() submitEvent = new EventEmitter();
  cartCount: any;
  packageTypes = PackageDetails.PACKAGE_NAMES;
  packagePrices = PackageDetails.PACKAGE_PRICES;
  packageInfo = PackageDetails.PACKAGE_DESCRIPTION;
  mycart = [];
  packages: any;
  packageRequest = new Packages();
  jobId: string;
  isATSPlusCustomer: boolean;
  subscription: Subscription;
  existingPackages: any;
  cartObject: CartObject[] = [];
  slotObjectNames = PackageDetails.SLOT_OBJECTS_NAMES;
  disableEnhancements = false;
  showSlotPurchasePanel = false;
  hideSlots = false;

  constructor(private sharedService: SharedService, public route: ActivatedRoute, private router: Router, private atsService: AtsService) { }

  priceList: {};
  count: any;
  months: any;
  price: any;
  currentlyChecked: any;
  @Input() jobData: any;
  slotDescription: boolean[] = [];

  joboptions = [
    {
      id: 1,
      header: 'JobsInTheUS Job Slots',
    },
    {
      id: 2,
      header: 'JobsInTheUS Enhancements',
    },
    {
      id: 3,
      header: 'JobsInTheUS Distribution',
    },
    {
      id: 4,
      header: 'Accounting Job Boards',
    },
    {
      id: 5,
      header: 'Logistics Job Boards',
    },
    {
      id: 6,
      header: 'Manufacturing Job Boards',
    }
  ];

  getCartTotal() {
    let total = 0;
    this.cartObject.forEach(cartItem => {
      if(!cartItem.isCredit) {
        total += cartItem.price;
      }
    });
    return total;
  }

  proceedToPayment() {
    this.submit();
    // this.router.navigate(['checkout'], { relativeTo: this.route });
  }

  isChecked(id){
    let ischecked = false;
    if(this.cartObject){
      this.cartObject.forEach(element => {
        if (element.selectedId === id) {
          ischecked = true;
        }
      });
    }
    return ischecked;
  }

  addPackages(event, count, months, price, type, product, id) {

    let packname = product.replaceAll('_', '-');
    let cartPackage: CartPackage = {
      type : packname,
      count: count,
      term: months === null ? null : months == 1 ? 'one-month' : months == 3 ? 'three-months' : 'one-year',
      isCredit: false
    };
    //  Selected event
    if (event.target.checked) {
      if (type === 'slots') {
        if (this.disableEnhancements)
          this.disableEnhancements = !this.disableEnhancements;
        this.updateCart(cartPackage, price, id, months);
        this.packageRequest.packages.push(cartPackage);
      } else {
        if (this.packages[packname].available > 0) {
          this.packages[packname].available -= 1;
          cartPackage.isCredit = true;
          // update Cart
          this.updateCart(cartPackage, price, id, months);
          // update packages
          this.packageRequest.packages.push(cartPackage);
        } else {
          this.updateCart(cartPackage, price, id, months);
          this.packageRequest.packages.push(cartPackage);
        }
      }
    } else {
      if (type === 'slots'){
        if(!this.hideSlots)  
          this.disableEnhancements = true;      
        const index = this.cartObject.findIndex(cart => cart.selectedId == id);
        this.cartObject.splice(index,1);
        this.deleteAndUpdatePackages(packname);
      }
    }

  }

  updateCart (pkg, price, id, months) {
    const cartObj: CartObject = {
      packName: pkg.type,
      displayName: this.packageTypes[pkg.type.replaceAll('-', '_')],
      price: price,
      isCredit: pkg.isCredit,
      selectedId: id
    }
    if (pkg.type === 'job-slots-resume-views') {
      const dispName= this.getJobDisplayName(pkg.count,months);
      cartObj.displayName = dispName;
    }
    this.cartObject.push(cartObj);
  }


  getJobDisplayName(count, months){
    const slotObject = count.toString() + '.' + months.toString();
    return this.slotObjectNames[slotObject];
  }

  deleteCartItem(index, pkgType, selectedId) {
    const ele = document.getElementById(this.cartObject[index].selectedId) as HTMLInputElement;
    ele.checked = false;
    this.cartObject.splice(index, 1);
    this.deleteAndUpdatePackages(pkgType);
  }

  deleteAndUpdatePackages(pkgType) {
    const idx = this.packageRequest.packages.findIndex(pkg => pkg.type ==pkgType.replaceAll('_', '-'));
    this.packageRequest.packages.splice(idx);
    this.updatePackages(pkgType);
  }

  updatePackages(pkgType) {
    if (this.existingPackages[pkgType] && this.existingPackages[pkgType].available !== 0) {
      this.packages[pkgType].available += 1;
    }
    
  }


  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }

  submit() {

    this.packageRequest.coupon = "",
    this.packageRequest.grandTotalCheck = this.getCartTotal(),
    this.atsService.updateCheckout(this.packageRequest);
    this.router.navigate(['checkout'], { relativeTo: this.route });
  }

  getKeys(obj: any): Array<string> {
    return Object.keys(obj);
  }

  skipMarketplace(){
    this.router.navigate(['my-jobs']);
  }

  showSlots(id){
    if(id===1 && this.hideSlots){
      return true;
    }
  }

  showOrHideSlots(event){
    if(this.disableEnhancements)
      this.disableEnhancements = !this.disableEnhancements;
    if(event.checked)
      this.hideSlots = true;      
    else{
      this.hideSlots = false;
      this.disableEnhancements = true
    }

  }

  getAllPackages() {
    this.sharedService.getAllPackages().subscribe(
      (response: any) => {
        this.packages = response.data;
        this.existingPackages = JSON.parse(JSON.stringify(response.data));

        // disable enhancements if not ATS+ and have no slots available
        if (!this.isATSPlusCustomer) {
          this.disableEnhancements = true;          
        }

        // show existing slot information if slots are available
        if(!this.isATSPlusCustomer && this.existingPackages.slots.available > 0 ){
          this.showSlotPurchasePanel = true;
        }
        
      }
    )
  }

  getAvailableCredits(types) {

    let packname = types.replaceAll('_', '-');
    if (this.packages.hasOwnProperty(packname))
      return this.packages[packname].available;
    else
      return 0;
  }

  ngOnInit(): void {
    this.cartCount = 0;
    this.cartTotal = 0;
    this.packageRequest.packages = [];
    this.route.paramMap.subscribe(params => {
      this.jobId = params.get('id');
    });
    this.isATSPlusCustomer;
    this.subscription = this.atsService.atsPlusCustomer.subscribe((result: boolean) => {
      this.isATSPlusCustomer = result;
    });
    this.packageRequest.jobId = parseInt(this.jobId);
    this.getAllPackages();
  }

  ngOnDestroy() {
    this.atsService.goToMarketplace(false);
    this.subscription.unsubscribe();
  }

  showPanel(id) {
    if (id == 1 && this.isATSPlusCustomer) {
      return false;
    } else {
      return true;
    }
  }

  expandColumn(id) {
    if((id == 1 && !this.isATSPlusCustomer) || (id == 2 && this.isATSPlusCustomer))  {
      return true;
    } else {
      return false;
    }
  }

  getCount(key) {
    const slotDet = key.split('.');
    return parseInt(slotDet[0]);
  }
  getMonths(key) {
    const slotDet = key.split('.');
    return parseInt(slotDet[1]);
  }

  showSlotDescription(index) {
    this.slotDescription[index] = true;
  }
  hideSlotDescription(index) {
    this.slotDescription[index] = false;
  }
}
