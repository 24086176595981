import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GlobalConstants } from 'src/app/components/common/constants';
import { ViewApplicantConstants } from 'src/app/components/common/constants/ViewApplicantConstants';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-step-three',
  templateUrl: './step-three.component.html',
  styleUrls: ['./step-three.component.scss']
})
export class StepThreeComponent implements OnInit, OnChanges {

  @Input() jobData: any;
  @Output() selectedCategory = new EventEmitter<any>();
  stepThreeForm: FormGroup;
  types = GlobalConstants.JOB_TYPE;
  categories = ViewApplicantConstants.JOB_CATEGORIES;
  terms = GlobalConstants.terms;
  travel = GlobalConstants.TravelRequirement;
  careerLevel = GlobalConstants.CAREER_LEVEL;
  payType = GlobalConstants.PAY_TYPE;
  payRange = GlobalConstants.payRange;
  education = GlobalConstants.EDUCATION;
  category = [];
  locationData: Object;
  locationField: any;
  jobId: string;


  constructor(private formBuilder: FormBuilder, private sharedService: SharedService, private router: ActivatedRoute) {
    this.stepThreeForm = this.formBuilder.group({
      type: ['', Validators.required],
      careerLevel: [''],
      payRangeLow: [''],
      payRangeHigh: [''],
      education: [''],
      status: [''],
      travel: [''],
      locationRemote: [''],
      payType: [''],
      code: [''],
      startsAt: [''],
      endsAt: [''],
      locationId: [''],
      category: ['']
    });
    this.router.paramMap.subscribe(params => {
      this.jobId = params.get('id');
    })
  }
  updateCategory(event){
    this.selectedCategory.emit(event);
  }

  getOptionText(option) {

    return option?.text;
  }

  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }
  getLocations(event) {

    this.sharedService.getLocations(event.target.value).subscribe(
      (response: any) => {
        this.locationData = JSON.parse(response.data).results;
        
      })
  }
  onLocationSelect(event) {
    this.jobData.locationId = event.option.value.id;
    this.stepThreeForm.controls.locationId.setValue(this.jobData.locationId);
  }

  getLocationData(locationId){
    this.sharedService.getLocationDataById(locationId).subscribe(
      (response: any) => {
       if(response.message === 'success')
        this.locationField = response.data.city + ", " + response.data.state;
      }
    )
  }

  onCategoryRemoved(category){
    const categories = this.stepThreeForm.get('category').value as string[];
    this.removeFirst(categories, category);
    this.stepThreeForm.get('category').setValue(categories);
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  ngOnInit(): void {


  }

  ngOnChanges(): void{

  //Todo - Raghu. Wait for locationId to be updated 
    if (this.jobData.locationId) {
      this.getLocationData(this.jobData.locationId);

    }
    this.stepThreeForm.patchValue({

      type: this.jobData.type,
      careerLevel: this.jobData.careerLevel,
      payRangeLow: this.jobData.payRangeLow,
      payRangeHigh: this.jobData.payRangeHigh,
      education: this.jobData.education,
      status: this.jobData.status,
      travel: this.jobData.travel,
      locationRemote: this.jobData.locationRemote,
      payType: this.jobData.payType,
      code: this.jobData.code,
      startsAt: this.jobData.startsAt,
      endsAt: this.jobData.endsAt,
      locationId: this.jobData.locationId
    })

    this.category = [this.jobData.category1Id, this.jobData.category2Id, this.jobData.category3Id];
    if(this.category){
      this.stepThreeForm.patchValue({
        category: this.category

      })
    }
    else{
      this.stepThreeForm.patchValue({
        category: []
      })
    }
  }
}
