<div class="container h-100">
  <div class="row h-100 justify-content-center align-items-center">
    <div class="col-md-4">
      <form class="w-100">
        <div class="mb-5">
          <h1 class="font-weight-bold mb-0">Sign in</h1>
          <p class="mb-0">Welcome back! Please sign into your account.</p>
        </div>
        <div>
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>User name</mat-label>
            <input matInput placeholder="Placeholder">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Password</mat-label>
            <input type="password" matInput placeholder="Placeholder">
          </mat-form-field>
        </div>
        <div class="d-flex justify-content-center">
        <a routerLink="/dashboard">
          <button mat-stroked-button color="primary" >SIGN IN</button>
        </a>
      </div>
      </form>
    </div>
  </div>
</div>