import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-applicant-reviews',
  templateUrl: './applicant-reviews.component.html',
  styleUrls: ['./applicant-reviews.component.scss']
})
export class ApplicantReviewsComponent implements OnInit {
  @Input() posts = [];
  @Input() ratings = [];
  @Input() selectedRadioValue: string;
  @Input() rbs: any;
  @Input() radioSelected: string;
  @Input() jobApplicationId: any;
  reviews = [];
  private ratingArr = [];
  @Input() user: any;
  @Output() fetchAverage = new EventEmitter<boolean>();

  constructor(public sharedService: SharedService) { }

  ngOnInit(): void {

  }

  loadReviews(){
    this.getReviews(this.jobApplicationId);
    for (let index = 0; index < 5; index++) {
      this.ratingArr.push(index);
    }
  }

  getReviews(jobApplicationId) {
    this.sharedService.getReviews(jobApplicationId).subscribe(
      (response: any) => {
        this.reviews = response.data;
      }
    )
  }

  getRating(input, rating) {

    const get_date = new Date().toISOString();
    const data = {
      "rating": this.radioSelected,
      "message": rating,
      "jobApplicationId": this.jobApplicationId,
      "postedBy": this.user?.userId
    }
    this.sharedService.postReviews(data).subscribe(
      (response: any) => {
        this.reviews.push(response.data);
        this.fetchAverage.emit(true);
        this.radioSelected = null;
      },
      (err) => {
        console.log(err.error);
      }
    )



    this.rbs = document.querySelectorAll('input[name="stars"]');
    if (rating !== null) {
      this.ratings.push(rating);
      input.value = '';
    }

    for (let rb of this.rbs) {
      if (rb.checked) {
        this.selectedRadioValue = rb.value;
        this.rbs.checked = !this.rbs.checked;
        break;
      }
    }

    this.rbs.checked = '';
    return this.selectedRadioValue;

  }

}
