<div class="p-4">
    <button class="close" mat-button (click)="closeDialog()">X</button>
    <h2 mat-dialog-title> Invite Candidate to Apply to Another Job</h2>
    <div class="col-md-8">
        <mat-form-field style="margin-bottom: -1.25em" appearance="outline" ngDefaultControl>
            <mat-label>List of All Open Positions</mat-label>
            <mat-select [value]="'select'" (selectionChange)="showEmailField($event)">
                <div *ngFor="let jobs of jobList">

                    <mat-option *ngIf="jobs.status" [value]='jobs.id' [id]="jobs.title">{{jobs.title}}</mat-option>
                </div>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="showEmail" class="mt-3 col-md-12">
        <form>
            <div class="col-md-12 col-lg-12 col-xl-12">
                <mat-label class="mat-typography">Invitation Email</mat-label>
                <mat-form-field appearance="outline" class="messageinput w-100">
                    <textarea class="mat-typography" ng-bind-html matInput cdkTextareaAutosize cdkAutosizeMinRows="10" cdkAutosizeMaxRows="15"
                        [innerHTML]="inviteEmailTemplate"></textarea>
                </mat-form-field>
            </div>
        </form>
    </div>
    <div mat-dialog-actions class="mt-4 d-flex justify-content-between" align="end">
        <mat-checkbox *ngIf="showEmail" class="mat-typography">Send Myself a Copy of Email Invitation</mat-checkbox>
        <div>
            <button mat-stroked-button color="accent" class=" text-uppercase" (click)="closeDialog()"
                mat-dialog-close>Cancel</button>

            <button mat-raised-button color="primary" class="ms-4 text-uppercase" [mat-dialog-close]="true" (click)="sendInvite()"
                mat-dialog-close>Add to Job
            </button>
        </div>
    </div>
</div>