<div class="row h-100" *ngIf="applicant">
	<div class="col-md-12">
		<div class="heading ms-2">
			<h2 class="mat-h1 bg-white text-uppercase">View applicant</h2>
		</div>
	</div>
	<div class="col-xl-8 col-lg-7 col-md-12 col-sm-12 pt-3">
		<mat-card class="dashboard-card shadow-none mb-6">
			<mat-card-content class="dashboard-card-content p-2">
				<div class="d-flex justify-content-between">
					<div class="me-auto">
						<div class="d-flex align-items-center">
							<p class="mat-title mb-0">{{applicant.firstName}} {{applicant.lastName}} </p>

							<bar-rating class="ms-2" [(rate)]="rating" [readOnly]="true" [max]="5"></bar-rating>

						</div>
						<p class="mb-0">{{applicant?.city}}{{applicant.city ? ',':''}} {{applicant.state}}
							{{applicant.state ? '|' : ''}}
							{{applicant.phone}} {{applicant.phone?'|':''}} {{applicant.email}}

						</p>
					</div>

					<div>
						<button *ngIf="!showTags"
							id="addTags"
							class="float-right d-flex justify-content-center align-items-center text-uppercase tag-btn"
							(click)="toggleTagsEditMode()" mat-raised-button color="primary">
							<small class="material-icons small-icons me-1">
								local_offer
							</small>
							<span>{{tags?.length>1? 'Edit Tags' : 'Add Tags'}}</span>
						</button>
					</div>

				</div>
				<mat-chip-list *ngIf="!showTags" #chipList>
					<mat-chip *ngFor="let tag of tags" [selectable]="selectable" [removable]="removable"
						(removed)="remove(tag)">
						{{tag}}
						<mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
					</mat-chip>


				</mat-chip-list>
				<div class="d-flex align-items-center">
					<mat-form-field appearance="outline" *ngIf="showTags" class="mt-2">
						<mat-label>Tags</mat-label>
						<mat-chip-list #chipList>
							<mat-chip *ngFor="let tag of tags" [selectable]="selectable" [removable]="removable"
								(removed)="remove(tag)">
								{{tag}}
								<mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
							</mat-chip>

							<input id="tagInput" style="height: 45px;" [matChipInputFor]="chipList"
								[matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
								(matChipInputTokenEnd)="add($event)">
						</mat-chip-list>
					</mat-form-field>
					<div *ngIf="showTags" class="ms-2">
						<button style="font-size: 0.8rem;" (click)="saveTags(userData?.userId, applicant.userId)"
							class=" float-right d-flex justify-content-center align-items-center text-uppercase tag-btn"
							mat-raised-button color="primary">
							<small class="material-icons small-icons me-1" style="font-size: 0.9rem !important;">
								local_offer
							</small>
							<span>Save Tags</span>
						</button>
						<button (click)="toggleTagsEditMode()" style="font-size: 0.8rem; line-height: 25px;"
							mat-stroked-button color="accent" class="mt-2 text-uppercase w-100">
							Cancel
						</button>
					</div>
				</div>
			</mat-card-content>
		</mat-card>

		<mat-card class="dashboard-card shadow-none p-0 mb-6">
			<mat-card-content>
				<mat-tab-group [(selectedIndex)]="selectedIndex" (selectedTabChange)="getEmails($event)"
					mat-align-tabs="start">
					<mat-tab label="Email">
						<app-applicant-message #email *ngIf="userData" [companyId] ="job?.companyId" [updateTabIndex]="updateTabIndex"
							[tenantId]="tenantId" [user]="userData" [jobTitle]="job?.title" [jobApplicationId]="jobApplicationId"
							[applicantDetails]="applicant">
						</app-applicant-message>
					</mat-tab>
					<mat-tab label="Text *">
						<div class="chat-content review-content">
							<div class="chat-footer container py-3">
								<h2 class="mb-0">Coming Soon!</h2>
							</div>
						</div>

					</mat-tab>
					<mat-tab label="Reviews">
						<app-applicant-reviews #reviewsComponent (fetchAverage)="fetchAverage()" [user]="userData"
							[jobApplicationId]="jobApplicationId" [updateTabIndex]="updateTabIndex"
							[radioSelected]="radioSelected" [ratings]="ratings" [rbs]="rbs"
							[selectedRadioValue]="selectedRadioValue">
						</app-applicant-reviews>

					</mat-tab>
					<mat-tab label="Interviews">
						<app-applicant-interview [disabled]="invitationSent" #interviewsComponent (interviewScheduled)="updateOfferStatus('Interview')" [user]="userData"
							[job]="job" [appStage]="applicantStage" [seekerData]="applicant"
							[jobApplicationId]="jobApplicationId" [radioSelected]="radioSelected"
							[selectedRadioValue]="selectedRadioValue">
						</app-applicant-interview>
					</mat-tab>
					<mat-tab label="Notes">
						<app-applicant-post #notesComponent [user]="userData" [user]="userData" [jobApplicationId]="jobApplicationId">
						</app-applicant-post>
					</mat-tab>
					<mat-tab label="Activity *">
						<div class="chat-content review-content">
							<div class="chat-footer container py-3">
								<h2 class="mb-0">Coming Soon!</h2>
							</div>
						</div>
					</mat-tab>
					<mat-tab id="offers-tab" [disabled]="invitationSent" label="Offers">
						<app-applicant-offers #applicantOffersComponent [tenantId]="tenantId" [offerSent]="applicantStage" (hiredDate)="getAcceptedDate($event)"
							[job]="job" (offerAccepted)="updateOfferStatus('hired')" (offerMailSent)="updateOfferStatus($event)"
							[applicantEmailId]="applicant.email" [user]="userData" [jobApplicationId]="jobApplicationId"
							[applicationdetails]="applicant" [applicantFirstName] = "applicant.firstName" [applicantLastName] = "applicant.lastName">
						</app-applicant-offers>
					</mat-tab>
					<!-- <mat-tab label="Screening">
						<div class="chat-content review-content">
							<div class="chat-footer container py-3">
								<h2 class="mb-0">Screening</h2>
							</div>
						</div>
					</mat-tab> -->
					<mat-tab label="Hiring">
						<app-applicant-hiring #hiringComponent [acceptedDate]="acceptedDate" [jobApplicationId]="jobApplicationId"
							[hiredStatus]="applicantStage">
						</app-applicant-hiring>
					</mat-tab>
				</mat-tab-group>
			</mat-card-content>
		</mat-card>

		<mat-card class="dashboard-card shadow-none p-0 mb-6">
			<mat-card-content>
				<mat-tab-group mat-align-tabs="start">
					<mat-tab label="Resume">
						<div class="chat-panel p-4">
							<div class="chat-content resume-content text-center w-100">
								<div class="resume-content-inner" *ngIf="fileType==='file'">
									<embed [src]="resumeUrl | safeUrl" type="application/pdf" width="100%"
										height="750px" />
								</div>
								<div *ngIf="fileType==='attachment' && !noResume">
									<a [href]="resumeUrl" download>
										<button mat-stroked-button color="accent" class=" text-uppercase"
											mat-dialog-close>Download Resume</button>
									</a>

								</div>
								<h2 class="mat-typography" *ngIf="noResume && resumeUrl=='' ">Resume Not Found!</h2>
							</div>
						</div> 
					</mat-tab>
					<mat-tab label="Cover Letter" [disabled]="disableTabs">
						<div class="chat-panel p-4">
							<div class="chat-content resume-content  w-100">
								<div class="resume-content-inner" style="white-space: pre-line;">
									<p class="cover-letter-inner w-80">{{coverLetter}}</p>
								</div>
							</div>
						</div>
					</mat-tab>
					<mat-tab label="Other files" [disabled]="disableOtherFilesTab">
						<div class="chat-panel p-4">
							<div class="chat-content resume-content text-center w-100">
								<div class="resume-content-inner">
									<div *ngFor="let files of other_file">
										<div *ngIf="files" class="col-md-5 d-flex align-items-center">
											<mat-icon>description</mat-icon>
											<a (click)="openOtherFile(files.fileId)">
												{{files.name}} &#40; {{files.extension}} &#41;
											</a>
										</div>

									</div>
								</div>
							</div>
						</div>
					</mat-tab>
				</mat-tab-group>
			</mat-card-content>
		</mat-card>
	</div>
	<div class="col-xl-4 col-lg-5 col-md-12 pt-3">
		<mat-card class="border-0 shadow-none p-0 mb-6">
			<div class="applicant-list bg-light">
				<div class="d-flex">
					<button [ngClass]="disablePrevButton ? 'disable-icon' : ''"
						(click)="changeApplisPrev(appname.selected.value)"
						class="border-0 bg-light px-2 py-1 d-flex align-items-center"> <span
							class="material-icons">keyboard_arrow_left</span></button>
					<div class="bg-light name-block px-2 py-1">
						<mat-form-field [(ngModel)]="myAttribute" ngDefaultControl class="border-0 applicant-select">
							<mat-select #appname (selectionChange)="changeApplicant($event.value)" [(value)]="selected">

								<mat-option *ngFor="let applist of applicantList" [value]="applist.id">
									{{applist.user.firstName}} {{applist.user.lastName}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<button [ngClass]="disableNextButton ? 'disable-icon' : ''"
						(click)="changeApplistNext(appname.selected.value)"
						class="border-0 bg-light px-2 py-1 d-flex align-items-center"> <span
							class="material-icons">keyboard_arrow_right</span></button>
				</div>
			</div>
		</mat-card>

		<mat-card class="dashboard-card shadow-none mb-6">
			<h2 class="text-danger mb-0" *ngIf="invitationSent">Job Invitation Sent</h2>
			<p class="mat-title mb-0">{{job?.title}}</p>
			<div *ngIf="!job?.isActive">
				<ng-template class=" fs-5 mb-0 font-weight-bold text-danger ">Job Status: Inactive</ng-template>
			</div>
				<p class="mb-0">Location: <span class="font-condensed">{{jobLocation?.city}},
						{{jobLocation?.state}}</span></p>
			<p class="mb-0">Date Applied: <span class="font-condensed">{{applicant.createdAt | date:'short'}}</span></p>
			<p class="mb-0">Source: <span class="font-condensed">{{applicant.source}}</span></p>
			<h3 class="text-danger mb-0" *ngIf="rejected">Rejected - {{finalStatus}}</h3>
			<h3 class="text-primary mb-0" *ngIf="hired">{{finalStatus}}</h3>
			<h3 class="text-primary mb-0" *ngIf="isWithdrawn ">Candidate Withdrew</h3>
			<div *ngIf="!rejected ">

				<mat-slider class="w-100 p-0" style="pointer-events: none;" [value]="invitationSent? '0' : DISPLAY_VALUE[applicantStage]"
					aria-label="unit(s)"></mat-slider>

				<div class="row mb-2">
					<div class="col" *ngFor="let enum of DISPLAY_STAGE | keyvalue: originalOrder">
						{{enum.value}}
					</div>
				</div>
			</div>



			<div [ngClass]="rejected ? 'disable' : 'justify-content-center'" class="d-flex  align-items-center">

				<div *ngIf="rejected">
					<button (click)="updateStage('reactivate')" mat-stroked-button color="accent"
						class="me-4 text-uppercase">
						Reactivate
					</button>
				</div>
				<div *ngIf="!rejected && !isWithdrawn && !hired && !invitationSent">
					<mat-form-field appearance="outline" class="advance-btn" [ngClass]="rejected ? 'disable' : ''">
						<!-- <mat-label class=" text-white font-weight-500">{{applicantStage}}</mat-label>  -->
						<mat-select [value]="applicantStage" (selectionChange)="updateStage($event.value, $event)">
							<mat-option [value]="enum.key" *ngFor="let enum of DISPLAY_STAGE | keyvalue: originalOrder">
								{{ enum.value}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>


				<div *ngIf="!rejected && !isWithdrawn && !hired && !invitationSent">
					<button (click)="openDialog(rejecttemplate, 700)" mat-stroked-button color="accent"
						class="mx-4 text-uppercase">
						Reject
					</button>
				</div>


				<ng-template #rejecttemplate>
					<button class="close" mat-button (click)="closeDialog()">X</button>

					<mat-dialog-content>
						<div class="row d-flex align-items-center">

							<h2 mat-dialog-title> Reject Applicant for this Job</h2>

							<p class="mat-typography mb-0">Reason for Rejection</p>
							<div mat-dialog-content class="col-md-5 me-2">
								<mat-form-field style="margin-bottom: -1.25em" appearance="outline" ngDefaultControl>

									<mat-select (selectionChange)="loadEmailTemplate($event.value)" #rejected [value]='STAGES.rejected_more_qualified_selected'>
										<mat-option [value]='STAGES.rejected_more_qualified_selected'>
											{{STAGES.rejected_more_qualified_selected}}</mat-option>
										<mat-option [value]='STAGES.rejected_no_show_for_interview'>
											{{STAGES.rejected_no_show_for_interview}}</mat-option>
										<mat-option [value]='STAGES.rejected_unresponsive'>
											{{STAGES.rejected_unresponsive}}</mat-option>
										<mat-option [value]='STAGES.rejected_did_not_meet_qualifications'>
											{{STAGES.rejected_did_not_meet_qualifications}}
										</mat-option>

									</mat-select>
								</mat-form-field>

							</div>
							<div class="col-md-4">
								<mat-checkbox color="primary" (change)="showEmail($event)"
									class="mat-typography">Send
									Notification to this Applicant</mat-checkbox>

							</div>
							<div *ngIf="showEmailTemplate" class="mt-3">
								<form [formGroup]="formdata">
									<div class="col-md-12 col-lg-12 col-xl-12">
							
										<mat-form-field appearance="outline" class="messageinput w-100">
											<textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"
												[(ngModel)]="rejectEmail" formControlName="rejectEmail" ></textarea>
							
										</mat-form-field>
							
									</div>
								</form>
							</div>
						</div>
						
					</mat-dialog-content>
					<div mat-dialog-actions class="mt-2" align="end">
						<button mat-stroked-button color="accent" class=" text-uppercase" (click)="closeDialog()"
							mat-dialog-close>Cancel</button>

						<button mat-raised-button color="primary" class="mx-4 text-uppercase"
							(click)="updateStage(rejected.selected.value, true, showEmailTemplate)" mat-dialog-close>{{
							showEmailTemplate ? 'Reject and Send' : 'Reject'}}</button>
					</div>
				</ng-template>

				<div class="ms-auto">
					<button mat-mini-fab class="shadow-none border-rounded" [matMenuTriggerFor]="menu" color="outline"
						aria-label="Example icon button with a menu icon">
						<mat-icon>more_horiz</mat-icon>
					</button>
					<mat-menu #menu="matMenu">
						
						<button (click)="openAddJobDialog(addJobTemplate, 600)" mat-menu-item>
							<span>Add to another job</span>
						</button>
					</mat-menu>
				</div>
			</div>
		</mat-card>

		<mat-card class="dashboard-card shadow-none mb-6">
			<mat-card-content>
				<p class="mat-title mb-0">Applicant Overview</p>
				<ul class="ps-3 mt-1">
					<li class="mb-1">
						<span>Category:</span> <span class="font-condensed ms-1">{{category}}</span>
					</li>
					<li class="mb-1">
						<span>Willing to Relocate:</span> <span class="font-condensed ms-1">{{applicant.canRelocate ?
							'Yes' : 'No'}}</span>
					</li>
					<li class="mb-1">
						<span>Willing to Telecommute</span> <span class="font-condensed ms-1">{{applicant.canTelecommute
							? 'Yes' : 'No'}}</span>
					</li>
				</ul>
			</mat-card-content>
		</mat-card>

		<div class="row mb-6">
			<div class="row m-0 p-0">
				<div class="col-md-6 col-lg-6 pe-md-1">
					<mat-card class="mt-sm-2 dashboard-card shadow-none p-2 bg-success border-0 p-2">
						<mat-card-content>
							<p class="mat-h2 mb-0">{{jobType ? jobType : 'N/A'}}
								<span
									class="d-block text-uppercase font-regular mat-h4 mb-0 font-weight-600 line-height-1">
									<small>Job Type</small></span>
							</p>
						</mat-card-content>
					</mat-card>
				</div>
				<div class="col-md-6 col-lg-6 ps-md-1">
					<mat-card class="mt-sm-2 h-90 dashboard-card shadow-none p-2 bg-success border-0 p-2">
						<mat-card-content>
							<p class="mat-h2 mb-0">{{status ? status : 'N/A'}}
								<span
									class="d-block  text-uppercase font-regular mat-h4 mb-0 font-weight-600 line-height-1">
									<small>Job Status</small> </span>
							</p>
						</mat-card-content>
					</mat-card>
				</div>
			</div>
			<div class="row m-0 p-0">
				<div class="col-md-6 col-lg-6 pe-md-1">
					<mat-card class="mt-2 h-90 dashboard-card shadow-none p-2 bg-success border-0 p-2">
						<mat-card-content>
							<p class="mat-h2 mb-0">{{education ? education : 'N/A'}}
								<span
									class="d-block  text-uppercase font-regular mat-h4 mb-0 font-weight-600 line-height-1">
									<small>Education</small></span>
							</p>
						</mat-card-content>
					</mat-card>
				</div>
				<div class="col-md-6 col-lg-6 ps-md-1">
					<mat-card class="mt-2 h-90 dashboard-card shadow-none p-2 bg-success border-0 p-2">
						<mat-card-content>
							<p class="mat-h2 mb-0">{{careerLevel ? careerLevel : 'N/A'}}
								<span
									class="d-block  text-uppercase font-regular mat-h4 mb-0 font-weight-600 line-height-1">
									<small>Career Level</small></span>
							</p>
						</mat-card-content>
					</mat-card>
				</div>
			</div>
			<div class="col-md-6 col-lg-6 pe-md-1">
				<mat-card class="mt-2 h-90 dashboard-card shadow-none p-2 bg-success border-0 p-2">
					<mat-card-content>
						<p class="mat-h2 mb-0">{{ payType ? payType : 'N/A'}}
							<span
								class="d-block  text-uppercase font-regular mat-h4 mb-0 font-weight-600 line-height-1">
								<small>Pay Type</small></span>
						</p>
					</mat-card-content>
				</mat-card>
			</div>
			<div class="col-md-6 col-lg-6 ps-md-1">
				<mat-card class="mt-2 h-90 dashboard-card shadow-none p-2 bg-success border-0 p-2">
					<mat-card-content>
						<p class="mat-h2 mb-0">{{ applicant.payRangeLow | currency:'USD':'symbol' }} -
							{{ applicant.payRangeHigh | currency:'USD':'symbol' }}
							<span
								class="d-block  text-uppercase font-regular mat-h4 mb-0 font-weight-600 line-height-1">
								<small>Salary</small></span>
						</p>
					</mat-card-content>
				</mat-card>
			</div>
			<div class="col-md-6 col-lg-6 pe-md-1">
				<mat-card class="mt-2 h-90 dashboard-card shadow-none p-2 bg-success border-0 p-2">
					<mat-card-content>
						<p class="mat-h2 mb-0">{{travel ? travel : 'N/A'}}
							<span
								class="d-block  text-uppercase font-regular mat-h4 mb-0 font-weight-600 line-height-1">
								<small>Travel</small></span>
						</p>
					</mat-card-content>
				</mat-card>
			</div>

		</div>

		<mat-card class="dashboard-card shadow-none mb-6">
			<mat-card-content>
				<p class="mat-title mb-0">Applicant's Other Job Applications</p>
				<ul class="ps-3 mt-1">
					<li *ngFor="let jobs of otherJobs">
						<a routerLink="/singlejob/{{jobs.jobId}}/">
							<span class="font-condensed ms-1">{{jobs.title}}</span>
						</a>
						<span></span>
					</li>

				</ul>
			</mat-card-content>
		</mat-card>
	</div>
</div>
<ng-template #addJobTemplate>
	<button class="close" mat-button (click)="closeDialog()">X</button>

	<mat-dialog-content>
		<div class="row d-flex align-items-center">

			<h2 mat-dialog-title> Add Applicant to Another Job</h2>

			<p class="mat-typography mb-0">Add to Job</p>
			<div mat-dialog-content class="col-md-8">
				<mat-form-field style="margin-bottom: -1.25em" appearance="outline" ngDefaultControl>

					<mat-select [(ngModel)]="job.id">
						<div *ngFor="let jobs of jobList">
							<mat-option *ngIf="jobs.status" [value]='jobs.id'>{{jobs.title}}</mat-option>
						</div>


					</mat-select>
				</mat-form-field>

			</div>

		</div>

	</mat-dialog-content>
	<div mat-dialog-actions class="mt-4" align="end">
		<button mat-stroked-button color="accent" class=" text-uppercase" (click)="closeDialog()"
			mat-dialog-close>Cancel</button>

		<button mat-raised-button color="primary" class="ms-4 text-uppercase" (click)="closeDialog()"
			mat-dialog-close>Add
			to Job</button>
	</div>
</ng-template>