
export class ViewApplicantConstants{

  public static STAGES = {
        '': 'New',
        read: 'Read',
        'Read': 'Review',
        'Candidate Withdrew': 'Candidate Withdrew',
        contacted: 'Lead',
        screened_in_person: 'New',
        interviewed: 'Interview',
        rejected: 'Rejected',
        reviewed: 'Review',
        screened_phone: 'New',
        background_check: 'background-check',
        offer_sent: 'Offer',
        offer_accepted: 'Offer',
        hired: 'Hired',
        offer_withdrawn: 'Rejected',
        not_hireable: 'Rejected',
        not_good_fit: 'Rejected',
        pass_for_future: 'Rejected',

     
    }
  
  public static TAB_INDEX = {

    EMAIL: 0,
    TEXT: 1,
    REVIEWS: 2,
    INTERVIEWS: 3,
    NOTES: 4,
    ACTIVITY: 5,
    OFFERS: 6,
    HIRING: 7

  }

  public static EMAIL_TEMPLATES = {

    THANK_YOU: 'thank_you_template',
    DEFAULT_OFFER: 'default_offer_template',
    REJECT: 'reject_template',
    INVITATION_LEAD: 'invitation_lead_template'

  }
  public static JOB_CATEGORIES = {
    '':'N/A',
    accounting: 'Accounting',
    'admin-clerical': 'Admin - Clerical',
    automotive: 'Automotive',
    banking: 'Banking',
    biotech: 'Biotech',
    'building-inspection-repair': 'Building Inspection - Repair',
    'business-development': 'Business Development',
    'business-opportunity': 'Business Opportunity',
    construction: 'Construction',
    consultant: 'Consultant',
    'customer-service': 'Customer Service',
    'design': 'Design',
    'direct-support-care': 'Direct Support Care',
    'distribution-shipping': 'Distribution - Shipping',
    'education': 'Education',
    'engineering': 'Engineering',
    'facilities': 'Facilities',
    'finance': 'Finance',
    'fitness-recreation-and-sports': 'Fitness Recreation and Sports',
    'franchise': 'Franchise',
    'general-business': 'General Business',
    'general-labor': 'General Labor',
    'government': 'Government',
    'government-federal': 'Government - Federal',
    'grocery': 'Grocery',
    'healthcare': 'Healthcare',
    'home-care': 'Home Care',
    'hospitality-hotels-tourism': 'Hospitality - Hotels - Tourism',
    'human-resources': 'Human Resources',
    'hvac': 'HVAC',
    'information-technology': 'Information Technology',
    'installation-maintenance-repair': 'Installation - Maintenance - Repair',
    'insurance': 'Insurance',
    'internet-ecommerce': 'Internet Ecommerce',
    'inventory': 'Inventory',
    'janitorial-housekeeping': 'Janitorial - Housekeeping',
    'landscaping': 'Landscaping',
    'law-enforcement-corrections': 'Law Enforcement Corrections',
    'legal': 'Legal',
    'legal-admin': 'Legal - Admin',
    'maine-office-tourism': 'Tourism Jobs - Maine Office of Tourism',
    'management': 'Management',
    'manufacturing': 'Manufacturing',
    'marine-industries': 'Marine Industries',
    'marketing': 'Marketing',
    'media-arts-entertainment': 'Media - Arts - Entertainment',
    'mental-health': 'Mental Health',
    'municipal': 'Municipal',
    'non-profit': 'Non-profit',
    'nursing': 'Nursing',
    'other': 'Other',
    'pharmaceutical': 'Pharmaceutical',
    'professional-services': 'Professional Services',
    'purchasing-procurement': 'Purchasing - Procurement',
    'qa-quality-control': 'QA - Quality Control',
    'public-health': 'Public Health',
    'real-estate': 'Real Estate',
    'research': 'Research',
    'restaurant-food-service': 'Restaurant - Food Service',
    'retail': 'Retail',
    'sales': 'Sales',
    'science': 'Science',
    'security': 'Security',
    'skilled-labor-trades': 'Skilled Labor - Trades',
    'social-services': 'Social Services',
    'strategy-planning': 'Strategy - Planning',
    'supply-chain-logistics': 'Supply Chain - Logistics',
    'telecommunications': 'Telecommunications',
    'training': 'Training',
    'transportation': 'Transportation',
    'veterinary-services': 'Veterinary Services',
    'volunteer': 'Volunteer',
    'warehouse': 'Warehouse'

  };

  

}
