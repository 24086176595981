<button class="bg-transparent border-0" [matMenuTriggerFor]="menu">
    <i class="material-icons gear-icon">settings</i>
</button>
<div class="col-md-3">
    <mat-menu #menu="matMenu" xPosition="after" class="settings-menu bg-white">

        <a mat-menu-item href="{{jobViewURL}}/{{params.data.id}}" target="_blank">
            <span>View</span>
        </a>
        <a mat-menu-item routerLink="/job/edit/{{params.data.id}}">
            <span>Edit</span>
        </a>
        <button *ngIf="params.data.status" (click)="refreshJobs()" mat-menu-item>
            <span>Refresh</span>
        </button>
        <button (click)="updateJobAction(params.data.status ? 'deactivate' : 'activate')" mat-menu-item>
            <span>{{params.data.status ? 'Deactivate' : 'Activate' }}</span>
        </button>
        <button (click)="updateJobAction(params.data.isArchived ? 'unarchive' : 'archive')" mat-menu-item>
            <span>{{params.data.isArchived ? 'Unarchive' : 'Archive' }}</span>
        </button>
        <button (click)="updateJobAction('copy')" mat-menu-item>
            <span>Copy</span>
        </button>
        <button *ngIf="!params.data.status" mat-menu-item>
            <span>History</span>
        </button>
        <button (click)="updateJobAction('delete')" mat-menu-item>
            <span>Delete</span>
        </button>
    </mat-menu>
</div>