import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';
import { GlobalConstants } from '../common/constants';
import { environment } from "src/environments/environment";
import { AtsService } from 'src/app/services/ats.service';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {

  token = '';
  constructor(private atsService: AtsService, private sharedService: SharedService, private router: Router, private activatedRoute: ActivatedRoute) {

    this.activatedRoute.queryParams.subscribe(params => {
      this.token = params['token'];
      this.token = this.token ? this.token.replace(/"/g, '') : '';
    });
  }

  ngOnInit() {
    this.loginToATS(this.token);
  }

  loginToATS(token) {

    token = token ? token : localStorage.getItem('token');
    if (token && token != '') {
      this.sharedService.getAuthDetails(token).subscribe((res: any) => {

        if (res.data.response == "LOGIN_VALID") {
          localStorage.setItem('token', token);
          this.router.navigate(['/dashboard']);
          
        } else {

          localStorage.removeItem('token');
          window.location.href = environment.ATS_ENV;

        }
      })
    }
    else {
      window.location.href = environment.ATS_ENV;
    }
  }
}
